@charset "UTF-8";
@font-face {
  font-display: block;
  font-family: "CargoDiatypeVariable";
  src: url("https://static.cargo.site/assets/C3/fonts/diatype-24/CargoDiatypePlusVariable.woff2") format("woff2-variations");
  font-style: normal;
  font-weight: 200 1000;
}
@font-face {
  font-display: block;
  font-family: "CargoDiatypeVariable";
  src: url("https://static.cargo.site/assets/C3/fonts/diatype-24/CargoDiatypeItalicPlusVariable.woff2") format("woff2-variations");
  font-style: italic;
  font-weight: 200 1000;
}
@font-face {
  font-display: block;
  font-family: "CargoMonumentGrot";
  src: url("https://static.cargo.site/assets/C3/fonts/Monument-Grotesk-Mono/CargoMonumentGroteskMono-Regular.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-display: block;
  font-family: "CargoMonumentGrot";
  src: url("https://static.cargo.site/assets/C3/fonts/Monument-Grotesk-Mono/CargoMonumentGroteskMono-RegularItalic.woff2") format("woff2");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "Inter";
  font-weight: normal;
  font-style: normal;
  src: url("https://static.cargo.site/assets/C3/fonts/SF/SFProText-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-weight: normal;
  font-style: italic;
  src: url("https://static.cargo.site/assets/C3/fonts/Inter/Inter-Italic.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-weight: 500;
  font-style: normal;
  src: url("https://static.cargo.site/assets/C3/fonts/Inter/Inter-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-weight: 500;
  font-style: italic;
  src: url("https://static.cargo.site/assets/C3/fonts/Inter/Inter-MediumItalic.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-weight: 700;
  font-style: normal;
  src: url("https://static.cargo.site/assets/C3/fonts/Inter/Inter-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-weight: 700;
  font-style: italic;
  src: url("https://static.cargo.site/assets/C3/fonts/Inter/Inter-BoldItalic.woff2") format("woff2");
}
body,
.light-mode {
  --height-row-numeric: 40;
  --height-row: calc(1px * var(--height-row-numeric));
  --height-row-quarter: calc(var(--height-row) * 0.25);
  --height-row-half: calc(var(--height-row) * 0.5);
  --height-row-2: calc(var(--height-row) * 2);
  --height-row-3: calc(var(--height-row) * 3);
  /* Text Styles */
  --fontFamily-default: -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --fontSize-default: 14.5px;
  --lineHeight-default: 1.65;
  --fontFamily-CargoDiatype: "CargoDiatypeVariable", sans-serif;
  --lineHeight-CargoDiatype: 16px;
  --fontWeight-CargoDiatype: 400;
  --fontFamily-mono: "CargoMonumentGrot", monospace;
  --fontFamily-code: Menlo, Lucida Console, monospace, sans-serif;
  --fontSize-header: 17px;
  --fontSize-secondary: 13.5px;
  --fontSize-small: 12px;
  --fontSize-large: 22px;
  --line-height-vertical-offset: -.2em;
  --row-rule-offset: calc(-1 * var(--spacing-1));
  --leftMenuBar-width: 270px;
  --topAlignment: calc(var(--height-row) + var(--height-row));
  --color-default: rgba(var(--baseColor-default-reverse-rgb), .75);
  --color-default-secondary: rgba(var(--baseColor-default-reverse-rgb), .4);
  --borderRadius-small: 5px;
  --color-interface-element-default: var(--base-color-text-default);
  --color-interface-element-checked: #ffffff;
  --element-gap: 1px;
  --ui-width-uiWindow: 342px;
  --ui-width-colorpicker: 244px;
  --ui-width-uiWidnow-numeric: 342;
  --spacing-1: 15px;
  --spacing-half: calc(var(--spacing-1) * 0.5);
  --spacing-2: calc(var(--spacing-1) * 2);
  --spacing-3: calc(var(--spacing-1) * 3);
  --spacing-4: calc(var(--spacing-1) * 4);
  --spacing-exp-1: 5px;
  --spacing-exp-half: calc(var(--spacing-exp-1) * 0.5);
  --spacing-exp-2: calc(var(--spacing-exp-1) * 2);
  --spacing-exp-3: calc(var(--spacing-exp-1) * 3);
  --spacing-exp-4: calc(var(--spacing-exp-1) * 5);
  --spacing-exp-5: calc(var(--spacing-exp-1) * 8);
  --spacing-exp-6: calc(var(--spacing-exp-1) * 13);
  --spacing-exp-7: calc(var(--spacing-exp-1) * 21);
  --spacing-exp-8: calc(var(--spacing-exp-1) * 34);
  --ui-padding-uiWindow: var(--spacing-1);
  --ui-padding-default-horizontal: calc(var(--spacing-1) * 4 / 5);
  --ui-padding-default-vertical: calc(var(--spacing-1) * 0.5);
  --ui-padding-default: var(--ui-padding-default-vertical) var(--ui-padding-default-horizontal);
  --ui-button-border-radius: 100px;
  --ui-lineHeight-default: var(--lineHeight-default);
  --ui-window-border-background: rgba(var(--baseColor-default-reverse-rgb), .2);
  --ui-details-background: #d1d1d1;
  --ui-maxWidth-mobile: 95vw;
  --outline-uiWindow-default: var(--element-gap) solid var(--baseColor-border);
  --window-boxShadow-default: 2px 2px 19px rgba(0, 0, 0, 0.15);
  --window-boxShadow-heavy: 4px 4px 40px rgba(0, 0, 0, 0.25);
  --opacity-downstate-default: .7;
  --opacity-icon-default: .75;
  --ui-background-default: var(--baseColor-default);
  --ui-background-overlayer: var(--baseColor-overlayer);
  --ui-background-overlayer-active: var(--baseColor-overlayer-active);
  --ui-background-confirm: var(--baseColor-confirm);
  --ui-background-accent: var(--baseColor-accent);
  --ui-background-container: var(--baseColor-container);
  --ui-background-accent-reverse: var(--baseColor-accent-reverse);
  --ui-background-subLayer: var(--baseColor-subLayer);
  /* Interface Colors */
  --ui-color-default: rgba(var(--baseColor-default-reverse-rgb), .85);
  --ui-color-accent: var(--baseColor-accent);
  --ui-color-accent-reverse: var(--baseColor-accent-reverse);
  --ui-color-confirm: var(--baseColor-confirm);
  --ui-color-error: var(--baseColor-alert);
  --ui-hint-background: #B7C9FF;
  --ui-message-background: #FFE600;
  --background-1: #fff;
  --background-2: var(--baseColor-default);
  --background-3: #fcfcfc;
  --background-force-dark: #111111;
  --background-blur-rgba: rgba(252, 252, 252, 0.6);
  --ui-border-bars: rgba(var(--baseColor-default-reverse-rgb), 0.12);
  --ui-border-bars-solid: #e0e0e0;
  --ui-color-flat: rgb(var(--baseColor-default-rgb));
  --ui-color-flat-reverse: rgb(var(--baseColor-default-reverse-rgb));
  --ui-color-soften: rgb(var(--baseColor-default-rgb));
  --color-shadow-rgb: 0, 0, 0;
  --ui-list-block-active: #f9f9f9;
  --ui-checkers-bg: #FFFFFF;
  --ui-arrow-opacity: .4;
  --inputAfter-opacity: .4;
  --ui-noFiles-fill: #CECECE;
  --ui-files-background: #F2F2F2;
  --ui-fileName-background: #F1F1F1;
  --ui-fileType-outline: 1px solid #DDDDDD;
  --ui-svg-shade: #242424;
  --ui-load-spinner-large-speed: 1.2s;
  --ui-load-spinner-large-background-radius: 24px;
  --ui-load-spinner-large-background-color: rgba(120,115,115,.75);
  --ui-hint-background: #B7C9FF;
  --ui-message-background: #FFE600;
  --ui-close-circle-opacity: .25;
  --ui-checkbox-inactive-opacity: 0.12;
  --border-outer: 11px;
  --border-inner: calc( var(--border-outer ) - 1px );
  --layer-0: 10;
  --layer-1: 100;
  --layer-2: 200;
  --layer-3: 300;
  --layer-4: 400;
  --layer-5: 500;
  --layer-6: 600;
  --layer-7: 700;
  --layer-8: 800;
  --layer-9: 900;
  --layer-10: 1000;
  font-family: var(--fontFamily-default);
  font-size: var(--fontSize-default);
  line-height: var(--lineHeight-default);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga" on;
  text-align: left;
  --windowRadiusLarge: 11px;
  --windowRadiusLarge-inner: calc(var(--windowRadiusLarge) - 1px);
  --windowRadiusSmall: 11px;
  --windowRadiusSmall-inner: calc(var(--windowRadiusSmall) - 1px);
  --modalRadiusLarge: 15px;
  --modalRadiusLarge-inner: calc(var(--modalRadiusLarge) - 1px);
  --modalRadiusSmall: 15px;
  --modalRadiusSmall-inner: calc(var(--modalRadiusSmall) - 1px);
}
body.mobile,
.light-mode.mobile {
  --fontSize-default: 16px;
  --lineHeight-default: 1.5;
  --fontSize-header: 18px;
}
body.f-f,
.light-mode.f-f {
  --fontFamily-default: "Inter", sans-serif;
}
body.dark,
.light-mode.dark {
  --ui-window-border-background: rgb(var(--darkBorder-rgb));
  --ui-details-background: #444444;
  --background-1: #141414;
  --background-3: #111111;
  --background-blur-rgba: rgba(3, 3, 3, 0.6);
  --ui-border-bars: var(--baseColor-border);
  --ui-border-bars-solid: var(--baseColor-border);
  --ui-color-flat: rgba(var(--baseColor-default-rgb), .85);
  --ui-color-flat-reverse: rgba(var(--baseColor-default-reverse-rgb), .85);
  --ui-color-soften: rgba(var(--baseColor-default-reverse-rgb), .85);
  --color-shadow-rgb: 11, 10, 12;
  --ui-list-block-active: var(--ui-background-container);
  --ui-arrow-opacity: .47;
  --inputAfter-opacity: .6;
  --ui-noFiles-fill: #676767;
  --ui-files-background: #0A0A0A;
  --ui-fileName-background: #0C0C0C;
  --ui-fileType-outline: 1px solid #4A4A4A;
  --ui-svg-shade: #CCCCCC;
  --ui-hint-background: var(--baseColor-accent);
  --ui-message-background: #00B46E;
  --ui-close-circle-opacity: .615;
  --ui-checkbox-inactive-opacity: 0.14;
}

/* Base Color and Background Mixins */
*,
*:before,
*:after {
  box-sizing: border-box;
}

*.square {
  min-width: var(--height-row) !important;
  min-height: var(--height-row) !important;
  width: var(--height-row) !important;
  height: var(--height-row) !important;
}

*.rectangle {
  min-width: calc(var(--height-row) * 2) !important;
  min-height: var(--height-row) !important;
  width: calc(var(--height-row) * 2) !important;
  height: var(--height-row) !important;
}

.ui-grid-square {
  width: var(--height-row) !important;
  height: var(--height-row) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled,
*[disabled] {
  opacity: 0.6 !important;
  pointer-events: none !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.grid-columns-even {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: var(--element-gap);
  position: relative;
}
.grid-columns-even .range:nth-child(2)::before {
  background: transparent;
}
.grid-columns-even label.checkbox:nth-child(2)::after {
  background: transparent !important;
}

.grid-columns-auto-square {
  display: grid;
  grid-auto-columns: auto var(--height-row);
  grid-auto-flow: column;
  grid-gap: var(--element-gap);
}

.ui-group {
  display: inline-grid;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  width: 100%;
  grid-auto-columns: auto;
  grid-gap: var(--element-gap);
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.ui-group > *:not(details).has-local-value {
  position: relative;
}
.ui-group > *:not(details).has-local-value:after {
  content: "";
  position: absolute;
  top: 50%;
  left: -13px;
  z-index: 1;
  pointer-events: none;
  width: 5px;
  height: 5px;
  background: green;
  transform: rotate(45deg) translate(0px, -100%);
}
.ui-group > details.has-local-value > summary:before,
.ui-group > details .scrubber.has-local-value:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -13px;
  z-index: 1;
  pointer-events: none;
  width: 5px;
  height: 5px;
  background: green;
  transform: rotate(45deg) translate(0px, -100%);
}

.ui-group::-webkit-scrollbar {
  display: none;
}

.ui-group.limited {
  width: auto;
}

.grid-gap-initial {
  grid-gap: initial !important;
}

.grid-columns-auto {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  position: relative;
  grid-gap: var(--element-gap);
}

.grid-columns-template-1-auto {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: 1fr auto;
}

.ui-window-text {
  font-size: var(--fontSize-secondary);
  line-height: 1.3em;
  display: block;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.5);
  margin-top: 10px;
}
.ui-window-text .text-link {
  display: inline-block;
  cursor: pointer;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.5);
  text-decoration: underline;
  text-decoration-color: rgba(var(--baseColor-default-reverse-rgb), 0.35);
}
.ui-window-text .text-link:active {
  opacity: 0.7;
}

.emoji {
  color: #000 !important;
  opacity: 1 !important;
  font-size: 20px;
  line-height: 0;
}

body,
.light-mode {
  --baseColor-accent: #0064E1;
  --baseColor-accent-rgb: 0, 100, 225;
  --baseColor-accent-reverse-rgb: 255, 255, 255;
  --baseColor-accent-reverse: #ffffff;
  --baseColor-accent-mobile: #F68219;
  --baseColor-accent-mobile-rgb: 246, 130, 25;
  --baseColor-accent-alt1: #FFFFC8;
  --baseColor-accent-alt1-rgb: 255, 255, 200;
  --baseColor-container: #eeeeee;
  --baseColor-overlayer: #f7f7f7;
  --baseColor-overlayer-active: #EAEAEA;
  --baseColor-border: #DBDCE0;
  --baseColor-confirm: #0FCE83;
  --baseColor-alert: #FF0000;
  --baseColor-default: #ffffff;
  --baseColor-default-rgb: 255,255,255;
  --baseColor-default-reverse-rgb: 0,0,0;
  --legacyColor-grayscale: #f2f2f2;
  --legacyColor-grayscale-darker: #ccc;
  --baseColor-subLayer: rgba(var(--baseColor-default-reverse-rgb), .05);
}

body.dark,
#editor-overlay.dark {
  --baseColor-accent: #007AFF;
  --baseColor-accent-rgb: 0, 122, 255;
  --baseColor-accent-reverse-rgb: 255, 255, 255;
  --baseColor-accent-reverse: #ffffff;
  --baseColor-default: #202020;
  --baseColor-container: #2d2d2d;
  --baseColor-border: #474747;
  --baseColor-subLayer: #cccccc;
  --baseColor-confirm: #0FCE83;
  --baseColor-default-rgb: 0, 0, 0;
  --baseColor-default-reverse-rgb: 255,255,255;
  --baseColor-default-reverse: rgba(var(--baseColor-default-reverse-rgb), .85);
  --baseColor-default-reverse-soft: rgba(var(--baseColor-default-reverse-rgb), .6);
  --baseColor-default-reverse-softer: rgba(var(--baseColor-default-reverse-rgb), .4);
  --darkBorder-rgb: 80, 80, 80;
  --legacyColor-grayscale: #f2f2f2;
  --legacyColor-grayscale-darker: rgba(var(--baseColor-default-reverse-rgb),.1);
}

#collaborators {
  --ui-collaborator-diameter: 24px;
  background: var(--ui-background-default);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: calc((var(--height-row) - var(--ui-collaborator-diameter)) / 2);
}
#collaborators .collaborator {
  width: var(--ui-collaborator-diameter);
  height: var(--ui-collaborator-diameter);
  border-radius: 100%;
  background: red;
  box-shadow: inset 0px 0px 0px 1px var(--ui-color-accent-reverse);
}
#collaborators .collaborator + .collaborator {
  margin-top: -8px;
}

.uiWindow[window-name=editors],
.uiWindow[window-name=share-folder] {
  border-radius: var(--windowRadiusLarge);
  font-family: var(--fontFamily-default);
  font-size: var(--fontSize-default);
  position: fixed;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.15);
}
.uiWindow[window-name=editors] .uiWindow-content,
.uiWindow[window-name=share-folder] .uiWindow-content {
  border-radius: var(--windowRadiusLarge);
}
.uiWindow[window-name=editors] .uiWindow-content .uiWindow-inner,
.uiWindow[window-name=share-folder] .uiWindow-content .uiWindow-inner {
  border-radius: var(--windowRadiusLarge-inner);
}
.uiWindow[window-name=editors] .processing,
.uiWindow[window-name=share-folder] .processing {
  height: 154.5px;
}
.uiWindow[window-name=editors] form .ui-group,
.uiWindow[window-name=share-folder] form .ui-group {
  max-height: 300px;
  overflow: scroll;
}
.uiWindow[window-name=editors] window-header,
.uiWindow[window-name=share-folder] window-header {
  margin-left: 0;
  margin-right: 0;
}
.uiWindow[window-name=editors] window-header window-label,
.uiWindow[window-name=share-folder] window-header window-label {
  margin-bottom: 25px;
}
.uiWindow[window-name=editors] .user,
.uiWindow[window-name=share-folder] .user {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  max-width: 310px;
}
.uiWindow[window-name=editors] .user.error input,
.uiWindow[window-name=share-folder] .user.error input {
  color: #f00;
}
.uiWindow[window-name=editors] .user:not(.editable),
.uiWindow[window-name=share-folder] .user:not(.editable) {
  grid-template-columns: auto 1fr auto;
  text-align: left;
  line-height: var(--ui-lineHeight-default);
  background-color: var(--ui-background-default);
  height: var(--height-row);
}
.uiWindow[window-name=editors] .user:not(.editable).pending .email-printed,
.uiWindow[window-name=editors] .user:not(.editable).pending .invited,
.uiWindow[window-name=share-folder] .user:not(.editable).pending .email-printed,
.uiWindow[window-name=share-folder] .user:not(.editable).pending .invited {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.5);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.5);
}
.uiWindow[window-name=editors] .user:not(.editable) .email-printed,
.uiWindow[window-name=share-folder] .user:not(.editable) .email-printed {
  color: var(--ui-color-flat-reverse);
  padding: var(--ui-padding-default);
  padding-left: 0;
  padding-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 202px;
}
.uiWindow[window-name=editors] .user:not(.editable) .invited,
.uiWindow[window-name=share-folder] .user:not(.editable) .invited {
  height: var(--height-row);
  padding: var(--ui-padding-default);
  padding-left: 0;
  display: flex;
  align-self: center;
}
.uiWindow[window-name=editors] .user.editable,
.uiWindow[window-name=share-folder] .user.editable {
  grid-template-columns: 1fr auto;
}
.uiWindow[window-name=editors] .user.editable .user-role-button,
.uiWindow[window-name=share-folder] .user.editable .user-role-button {
  width: 64px;
  padding: var(--ui-padding-default);
  padding-left: 6px;
}
.uiWindow[window-name=editors] .user.editable .user-role-button.disabled,
.uiWindow[window-name=share-folder] .user.editable .user-role-button.disabled {
  opacity: 1 !important;
  pointer-events: none;
  text-decoration: none;
}
.uiWindow[window-name=editors] .user.editable .user-role-button.disabled::active,
.uiWindow[window-name=share-folder] .user.editable .user-role-button.disabled::active {
  opacity: 1 !important;
}
.uiWindow[window-name=editors] .user button,
.uiWindow[window-name=share-folder] .user button {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  text-decoration: underline;
}
.uiWindow[window-name=editors] .user button:active,
.uiWindow[window-name=share-folder] .user button:active {
  opacity: 1;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.2);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.2);
}
.uiWindow[window-name=editors] .user .disabled,
.uiWindow[window-name=share-folder] .user .disabled {
  pointer-events: none !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.uiWindow[window-name=editors] button.user-role-button,
.uiWindow[window-name=share-folder] button.user-role-button {
  padding-left: 0;
  text-align: right;
}
.uiWindow[window-name=editors] button.user-role-button.disabled,
.uiWindow[window-name=share-folder] button.user-role-button.disabled {
  opacity: 1 !important;
  text-decoration: none;
  pointer-events: none;
}
.uiWindow[window-name=editors] button.user-role-button.disabled::active,
.uiWindow[window-name=share-folder] button.user-role-button.disabled::active {
  opacity: 1 !important;
}
.uiWindow[window-name=editors] button span svg,
.uiWindow[window-name=share-folder] button span svg {
  margin: 0 auto;
  left: 0;
  width: 26px;
}
.uiWindow[window-name=editors] .more-actions button[type=submit].disabled,
.uiWindow[window-name=share-folder] .more-actions button[type=submit].disabled {
  background: rgba(var(--baseColor-default-reverse-rgb), 0.25);
}
.uiWindow[window-name=editors] button[type=submit],
.uiWindow[window-name=share-folder] button[type=submit] {
  position: relative;
}
.uiWindow[window-name=editors] button[type=submit] .caution-processing,
.uiWindow[window-name=share-folder] button[type=submit] .caution-processing {
  border-radius: var(--ui-button-border-radius);
}
.uiWindow[window-name=editors] .ui-group .text-input.autosize .input-auto-size,
.uiWindow[window-name=share-folder] .ui-group .text-input.autosize .input-auto-size {
  max-width: 237.77px;
}
.uiWindow[window-name=editors] .ui-group .text-input.autosize .input-auto-size::after,
.uiWindow[window-name=editors] .ui-group .text-input.autosize .input-auto-size input,
.uiWindow[window-name=share-folder] .ui-group .text-input.autosize .input-auto-size::after,
.uiWindow[window-name=share-folder] .ui-group .text-input.autosize .input-auto-size input {
  max-width: 225px;
}
.uiWindow[window-name=editors] .ui-group .text-input.autosize.no-label .input-auto-size,
.uiWindow[window-name=share-folder] .ui-group .text-input.autosize.no-label .input-auto-size {
  max-width: 304.77px;
}
.uiWindow[window-name=editors] .ui-group .text-input.autosize.no-label .input-auto-size::after,
.uiWindow[window-name=editors] .ui-group .text-input.autosize.no-label .input-auto-size input,
.uiWindow[window-name=share-folder] .ui-group .text-input.autosize.no-label .input-auto-size::after,
.uiWindow[window-name=share-folder] .ui-group .text-input.autosize.no-label .input-auto-size input {
  max-width: 292px;
}

.mobile .uiWindow[window-name=editors] {
  width: 580px;
}
.mobile .uiWindow[window-name=editors] .user {
  max-width: unset;
}
.mobile .uiWindow[window-name=editors] .user .user:not(.editable) .email-printed {
  min-width: unset;
}
.mobile .uiWindow[window-name=editors] .user .ui-group {
  justify-content: stretch;
}
.mobile .uiWindow[window-name=editors] window-content .user-role-button {
  width: calc(64px + 1ch);
}

.prevent-touch-selection {
  user-select: none !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
}

.context-menu-layer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1100;
  display: flex;
  --fontSize-default: 14.5px;
}
.context-menu-layer .context-menu {
  --scale: 1;
  --ui-background-container: #e4e4e4;
  --sub-menu-text-color: #222222;
  --shortcut: #222222;
  --ui-outline-container: rgba(var(--baseColor-default-reverse-rgb), 0.20);
  --hr-color: rgba(0,0,0,.12);
  --context-menu-shadow: 2px 2px 17px 0px rgba(0, 0, 0, .2);
  --hover-title-background: #cfcdcb;
  --outer-radius: calc(7px * var(--scale));
  --inner-radius: calc(6px * var(--scale));
  --item-radius: calc(4px * var(--scale));
  --item-height: calc(23px * var(--scale));
  --horizontal-margin: calc(5px * var(--scale));
  --vertical-padding: calc(5px * var(--scale));
  --horizontal-padding: calc(10px * var(--scale));
  --svg-height: calc(14px * var(--scale));
  --svg-translateY: calc(2px * var(--scale));
  --line-height: 1.5em;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  position: absolute;
  font-family: var(--fontFamily-default);
  background: transparent;
  border-radius: var(--item-radius);
  overflow: visible;
  width: fit-content;
}
.context-menu-layer .context-menu.block-hover {
  pointer-events: none;
}
.context-menu-layer .context-menu.block-hover .menu-background .menu-ui .sub-menu {
  pointer-events: none;
}
.context-menu-layer .context-menu .menu-background {
  background: var(--ui-outline-container);
  padding: 0;
  box-shadow: var(--context-menu-shadow);
  font-size: calc(var(--fontSize-default) * var(--scale));
  z-index: var(--layer-8);
  border-radius: var(--outer-radius);
  cursor: default !important;
}
.context-menu-layer .context-menu .menu-background.clipped-height {
  padding: 1px;
}
.context-menu-layer .context-menu .menu-background.clipped-height .menu-ui-body {
  max-height: 100%;
  overflow: scroll;
  padding: 0;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body {
  padding: 1px;
  border-radius: var(--outer-radius);
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui {
  background: var(--ui-background-container);
  border-radius: var(--inner-radius);
  padding: var(--vertical-padding) 0;
  display: grid;
  grid-template-columns: auto 1fr auto;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title {
  font-size: calc(var(--fontSize-default) * var(--scale));
  line-height: var(--line-height);
  color: var(--sub-menu-text-color);
  -webkit-display: none;
  height: var(--item-height);
  cursor: default;
  position: relative;
  display: contents;
  white-space: nowrap;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button em,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label em,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title em {
  opacity: 0.4;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button .before,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label .before,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .before {
  position: relative;
  margin-left: var(--horizontal-margin);
  border-radius: var(--item-radius) 0 0 var(--item-radius);
  padding: 0 0 0 var(--horizontal-padding);
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button > div:nth-child(2),
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label > div:nth-child(2),
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title > div:nth-child(2) {
  height: var(--item-height);
  padding: 0;
  border-radius: none;
  padding-right: 1px;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button > div:nth-child(2) > svg,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label > div:nth-child(2) > svg,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title > div:nth-child(2) > svg {
  height: 100% !important;
  width: auto !important;
  transform: scale(1.5);
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button > div:nth-child(2) > svg > rect:first-child,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label > div:nth-child(2) > svg > rect:first-child,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title > div:nth-child(2) > svg > rect:first-child {
  display: none;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button .checkbox-label,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label .checkbox-label,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .checkbox-label {
  width: 100%;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button .after,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label .after,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .after {
  position: relative;
  margin-right: var(--horizontal-margin);
  padding: 0 var(--horizontal-padding) 0 0;
  border-radius: 0 var(--item-radius) var(--item-radius) 0;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button .after.icon,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label .after.icon,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .after.icon {
  padding-left: calc(var(--horizontal-padding) * 2);
  padding-right: calc(var(--horizontal-padding) / 2);
  max-width: 30ch;
  display: inline-flex;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button .after.icon svg,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label .after.icon svg,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .after.icon svg {
  height: var(--svg-height);
  width: auto;
  transform: translate(0, var(--svg-translateY));
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button .after.icon svg *,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label .after.icon svg *,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .after.icon svg * {
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.25);
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button .after.icon .type-hint,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label .after.icon .type-hint,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .after.icon .type-hint {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  font-style: italic;
  padding-right: calc(1em * var(--scale));
  display: inline-block;
  max-width: 100%;
  line-height: 23px;
  vertical-align: baseline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button .after.icon .type-hint.font,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label .after.icon .type-hint.font,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .after.icon .type-hint.font {
  font-style: normal;
  font-size: calc(var(--fontSize-small) * var(--scale));
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button .shortcut,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label .shortcut,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .shortcut {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  float: right;
  margin-left: 1.5em;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.hover > .before, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.hover > div:nth-child(2), .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.hover > .after,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.hover > .before,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.hover > div:nth-child(2),
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.hover > .after,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.hover > .before,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.hover > div:nth-child(2),
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.hover > .after {
  background: var(--baseColor-accent);
  color: var(--baseColor-accent-reverse);
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.hover > .before svg *, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.hover > div:nth-child(2) svg *, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.hover > .after svg *,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.hover > .before svg *,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.hover > div:nth-child(2) svg *,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.hover > .after svg *,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.hover > .before svg *,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.hover > div:nth-child(2) svg *,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.hover > .after svg * {
  fill: var(--baseColor-accent-reverse);
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.hover > .before em, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.hover > div:nth-child(2) em, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.hover > .after em,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.hover > .before em,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.hover > div:nth-child(2) em,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.hover > .after em,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.hover > .before em,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.hover > div:nth-child(2) em,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.hover > .after em {
  opacity: 0.7;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.hover > .before .type-hint,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.hover > .before .shortcut, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.hover > div:nth-child(2) .type-hint,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.hover > div:nth-child(2) .shortcut, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.hover > .after .type-hint,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.hover > .after .shortcut,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.hover > .before .type-hint,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.hover > .before .shortcut,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.hover > div:nth-child(2) .type-hint,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.hover > div:nth-child(2) .shortcut,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.hover > .after .type-hint,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.hover > .after .shortcut,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.hover > .before .type-hint,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.hover > .before .shortcut,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.hover > div:nth-child(2) .type-hint,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.hover > div:nth-child(2) .shortcut,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.hover > .after .type-hint,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.hover > .after .shortcut {
  color: var(--baseColor-accent-reverse);
  opacity: 0.7;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button[disabled] *, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.disabled *,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label[disabled] *,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.disabled *,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title[disabled] *,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.disabled * {
  pointer-events: none !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  opacity: 0.35 !important;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button[disabled] .type-hint,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button[disabled] .shortcut, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.disabled .type-hint,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.disabled .shortcut,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label[disabled] .type-hint,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label[disabled] .shortcut,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.disabled .type-hint,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.disabled .shortcut,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title[disabled] .type-hint,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title[disabled] .shortcut,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.disabled .type-hint,
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.disabled .shortcut {
  color: var(--shortcut) !important;
  opacity: 1 !important;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title {
  --afterArrow-padding: 0 calc(10px / var(--scale)) 0 calc(15px / var(--scale));
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title > .after {
  padding: var(--afterArrow-padding);
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title > .after.icon {
  padding-left: calc(var(--horizontal-padding) * 2);
  padding-right: calc(var(--horizontal-padding) / 2);
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title > .after.icon svg {
  height: var(--svg-height);
  width: auto;
  transform: translate(0, var(--svg-translateY));
  margin-right: calc(8px * var(--scale));
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title > .after.icon svg * {
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.25);
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title > .after:after {
  opacity: 1;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='9' viewBox='0 0 5 9' fill='%23333'%3E%3Cpath d='M0.764331 9C0.973612 9 1.14195 8.92174 1.28753 8.76522L4.71338 5.175C4.90446 4.96467 4.99545 4.75924 5 4.5C5 4.24565 4.90901 4.03043 4.71338 3.825L1.28753 0.229891C1.14195 0.0782609 0.969063 0 0.764331 0C0.341219 0 0 0.361957 0 0.811957C0 1.03696 0.0864422 1.24239 0.245678 1.41359L3.21201 4.50489L0.245678 7.58641C0.0864422 7.75761 0 7.96304 0 8.18804C0 8.63315 0.341219 9 0.764331 9Z'/%3E%3C/svg%3E");
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title:active > .before, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title:active > div:nth-child(2), .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title:active > .after {
  opacity: 1 !important;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .sub-menu-label {
  position: relative;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .sub-menu-label:after, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .sub-menu-label:before {
  content: "";
  width: 1px;
  background: transparent;
  z-index: 10;
  height: 100%;
  position: absolute;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .sub-menu-label:after {
  right: -1px;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .sub-menu-label:before {
  left: 0;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.open {
  background: var(--baseColor-accent);
  color: var(--baseColor-accent-reverse);
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.open > .before, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.open > .after, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.open > .sub-menu-label {
  background: var(--hover-title-background);
  color: var(--sub-menu-text-color);
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.open > .sub-menu-label:after, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.open > .sub-menu-label:before {
  background: var(--hover-title-background);
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.open > .after {
  padding: var(--afterArrow-padding);
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.open > .after:after {
  color: var(--baseColor-accent-reverse);
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='9' viewBox='0 0 5 9' fill='%23333'%3E%3Cpath d='M0.764331 9C0.973612 9 1.14195 8.92174 1.28753 8.76522L4.71338 5.175C4.90446 4.96467 4.99545 4.75924 5 4.5C5 4.24565 4.90901 4.03043 4.71338 3.825L1.28753 0.229891C1.14195 0.0782609 0.969063 0 0.764331 0C0.341219 0 0 0.361957 0 0.811957C0 1.03696 0.0864422 1.24239 0.245678 1.41359L3.21201 4.50489L0.245678 7.58641C0.0864422 7.75761 0 7.96304 0 8.18804C0 8.63315 0.341219 9 0.764331 9Z'/%3E%3C/svg%3E");
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.open.hover {
  pointer-events: none;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.open.hover > .before, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.open.hover > .after, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.open.hover > div.sub-menu-label {
  background: var(--hover-title-background);
  color: var(--sub-menu-text-color);
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.open.hover > .after:after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='9' viewBox='0 0 5 9' fill='%23333'%3E%3Cpath d='M0.764331 9C0.973612 9 1.14195 8.92174 1.28753 8.76522L4.71338 5.175C4.90446 4.96467 4.99545 4.75924 5 4.5C5 4.24565 4.90901 4.03043 4.71338 3.825L1.28753 0.229891C1.14195 0.0782609 0.969063 0 0.764331 0C0.341219 0 0 0.361957 0 0.811957C0 1.03696 0.0864422 1.24239 0.245678 1.41359L3.21201 4.50489L0.245678 7.58641C0.0864422 7.75761 0 7.96304 0 8.18804C0 8.63315 0.341219 9 0.764331 9Z'/%3E%3C/svg%3E");
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .label-hovered {
  background: var(--baseColor-accent) !important;
  color: var(--baseColor-accent-reverse) !important;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .label-hovered.sub-menu-label:after, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .label-hovered.sub-menu-label:before {
  background: var(--baseColor-accent);
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .label-hovered.after:after {
  color: var(--baseColor-accent-reverse) !important;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='9' viewBox='0 0 5 9' fill='%23fff'%3E%3Cpath d='M0.764331 9C0.973612 9 1.14195 8.92174 1.28753 8.76522L4.71338 5.175C4.90446 4.96467 4.99545 4.75924 5 4.5C5 4.24565 4.90901 4.03043 4.71338 3.825L1.28753 0.229891C1.14195 0.0782609 0.969063 0 0.764331 0C0.341219 0 0 0.361957 0 0.811957C0 1.03696 0.0864422 1.24239 0.245678 1.41359L3.21201 4.50489L0.245678 7.58641C0.0864422 7.75761 0 7.96304 0 8.18804C0 8.63315 0.341219 9 0.764331 9Z'/%3E%3C/svg%3E");
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .time-label {
  opacity: 0.5;
  float: right;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.hover > .before:after, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.hover > .after:after, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.hover > .before:after, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.hover > .after:after {
  content: "";
  width: 1px;
  background: var(--baseColor-accent);
  z-index: 9999;
  height: 100%;
  position: absolute;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.hover > .before:after, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.hover > .before:after {
  right: 0;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui button.hover > .after:after, .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label.hover > .after:after {
  left: 0;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label[type=checkbox] > .before {
  padding: 0 0 0 calc(10px * var(--scale));
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label[type=checkbox] > .before:before {
  content: "";
  display: inline-block;
  height: 100%;
  font-weight: bold;
  padding: 0;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label[type=checkbox][checked-on=true] .before:before {
  padding: 0 calc(4px * var(--scale));
  content: "✓️";
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label[type=checkbox][checked-on=true] > .before {
  padding: 0;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
  display: none;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui label input[type=checkbox]:hover:checked + div.before {
  background: var(--baseColor-accent);
  color: var(--baseColor-accent-reverse);
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu {
  pointer-events: auto;
  position: absolute;
  transform: translate(0, calc(-6px * var(--scale)));
  z-index: var(--layer-9);
  box-shadow: var(--context-menu-shadow);
  background: var(--ui-outline-container);
  padding: 1px;
  border-radius: var(--outer-radius);
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu .list {
  padding: var(--vertical-padding) 0;
  background-color: var(--ui-background-container);
  border-radius: var(--inner-radius);
  display: grid;
  grid-template-columns: auto 1fr auto;
  line-height: var(--line-height);
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu.limited-submenu-width .checkbox-label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui hr {
  background: var(--hr-color);
  border-top: 7px solid var(--ui-background-container);
  border-bottom: 7px solid var(--ui-background-container);
  padding: 0.5px;
  height: 0.5px;
  display: block;
  margin: 0 calc(16px * var(--scale));
  grid-column-end: span 3;
}
.context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui hr.invisible {
  background: transparent;
}
.context-menu-layer .context-menu .flicker-on > .before,
.context-menu-layer .context-menu .flicker-on > div:nth-child(2),
.context-menu-layer .context-menu .flicker-on > .after {
  opacity: 1;
  background: var(--baseColor-accent) !important;
  color: var(--baseColor-accent-reverse) !important;
}
.context-menu-layer .context-menu .flicker-on > .before svg *, .context-menu-layer .context-menu .flicker-on > .before.icon svg *,
.context-menu-layer .context-menu .flicker-on > div:nth-child(2) svg *,
.context-menu-layer .context-menu .flicker-on > div:nth-child(2).icon svg *,
.context-menu-layer .context-menu .flicker-on > .after svg *,
.context-menu-layer .context-menu .flicker-on > .after.icon svg * {
  fill: var(--baseColor-accent-reverse) !important;
}
.context-menu-layer .context-menu .flicker-on > .before .type-hint,
.context-menu-layer .context-menu .flicker-on > .before .shortcut,
.context-menu-layer .context-menu .flicker-on > div:nth-child(2) .type-hint,
.context-menu-layer .context-menu .flicker-on > div:nth-child(2) .shortcut,
.context-menu-layer .context-menu .flicker-on > .after .type-hint,
.context-menu-layer .context-menu .flicker-on > .after .shortcut {
  color: rgba(var(--baseColor-default-rgb), 0.7) !important;
}
.context-menu-layer .context-menu .flicker-off > .before,
.context-menu-layer .context-menu .flicker-off > div:nth-child(2),
.context-menu-layer .context-menu .flicker-off > .after {
  opacity: 1;
  background: var(--ui-background-container) !important;
  color: var(--sub-menu-text-color) !important;
}
.context-menu-layer .context-menu .flicker-off > .before:after,
.context-menu-layer .context-menu .flicker-off > div:nth-child(2):after,
.context-menu-layer .context-menu .flicker-off > .after:after {
  display: none;
}
.context-menu-layer .context-menu .flicker-off > .before svg *,
.context-menu-layer .context-menu .flicker-off > div:nth-child(2) svg *,
.context-menu-layer .context-menu .flicker-off > .after svg * {
  fill: var(--baseColor-accent-reverse);
}

.fade {
  transition: opacity 1s linear;
}

[context-menu-trigger] {
  z-index: var(--layer-8);
}

.mobile .context-menu-layer .context-menu {
  --scale: 1.2;
}
.mobile .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title > .after:after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10.8' viewBox='0 0 5 9' fill='%23333'%3E%3Cpath d='M0.764331 9C0.973612 9 1.14195 8.92174 1.28753 8.76522L4.71338 5.175C4.90446 4.96467 4.99545 4.75924 5 4.5C5 4.24565 4.90901 4.03043 4.71338 3.825L1.28753 0.229891C1.14195 0.0782609 0.969063 0 0.764331 0C0.341219 0 0 0.361957 0 0.811957C0 1.03696 0.0864422 1.24239 0.245678 1.41359L3.21201 4.50489L0.245678 7.58641C0.0864422 7.75761 0 7.96304 0 8.18804C0 8.63315 0.341219 9 0.764331 9Z'/%3E%3C/svg%3E");
}
.mobile .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.open > .after:after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10.8' viewBox='0 0 5 9' fill='%23333'%3E%3Cpath d='M0.764331 9C0.973612 9 1.14195 8.92174 1.28753 8.76522L4.71338 5.175C4.90446 4.96467 4.99545 4.75924 5 4.5C5 4.24565 4.90901 4.03043 4.71338 3.825L1.28753 0.229891C1.14195 0.0782609 0.969063 0 0.764331 0C0.341219 0 0 0.361957 0 0.811957C0 1.03696 0.0864422 1.24239 0.245678 1.41359L3.21201 4.50489L0.245678 7.58641C0.0864422 7.75761 0 7.96304 0 8.18804C0 8.63315 0.341219 9 0.764331 9Z'/%3E%3C/svg%3E");
}
.mobile .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.open.hover > .after:after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10.8' viewBox='0 0 5 9' fill='%23333'%3E%3Cpath d='M0.764331 9C0.973612 9 1.14195 8.92174 1.28753 8.76522L4.71338 5.175C4.90446 4.96467 4.99545 4.75924 5 4.5C5 4.24565 4.90901 4.03043 4.71338 3.825L1.28753 0.229891C1.14195 0.0782609 0.969063 0 0.764331 0C0.341219 0 0 0.361957 0 0.811957C0 1.03696 0.0864422 1.24239 0.245678 1.41359L3.21201 4.50489L0.245678 7.58641C0.0864422 7.75761 0 7.96304 0 8.18804C0 8.63315 0.341219 9 0.764331 9Z'/%3E%3C/svg%3E");
}
.mobile .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .label-hovered,
.mobile .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .label-hovered.sub-menu-label,
.mobile .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .label-hovered.sub-menu-label:before,
.mobile .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .label-hovered.sub-menu-label:after {
  background: var(--hover-title-background) !important;
  color: var(--sub-menu-text-color) !important;
}
.mobile .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title .sub-menu label div.checkbox-label {
  width: 100%;
}
.mobile .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.open > .sub-menu-label.label-hovered,
.mobile .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.open > .before .sub-menu-title.open > .after {
  background: var(--hover-title-background) !important;
  color: var(--sub-menu-text-color) !important;
}

body.dark .context-menu-layer .context-menu {
  --ui-background-container: #292828;
  --sub-menu-text-color: rgba(var(--baseColor-default-reverse-rgb), 0.87);
  --shortcut: #cccccc;
  --ui-outline-container: var(--ui-window-border-background);
  --hover-title-background: #535353;
  --hr-color: #cfcdcb34;
}
body.dark .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui svg * {
  fill: var(--ui-color-flat-reverse);
}
body.dark .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title > .after:after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='9' viewBox='0 0 5 9' fill='rgba(255, 255, 255, 0.85)'%3E%3Cpath d='M0.764331 9C0.973612 9 1.14195 8.92174 1.28753 8.76522L4.71338 5.175C4.90446 4.96467 4.99545 4.75924 5 4.5C5 4.24565 4.90901 4.03043 4.71338 3.825L1.28753 0.229891C1.14195 0.0782609 0.969063 0 0.764331 0C0.341219 0 0 0.361957 0 0.811957C0 1.03696 0.0864422 1.24239 0.245678 1.41359L3.21201 4.50489L0.245678 7.58641C0.0864422 7.75761 0 7.96304 0 8.18804C0 8.63315 0.341219 9 0.764331 9Z'/%3E%3C/svg%3E");
}
body.dark .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.open > .after:after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='9' viewBox='0 0 5 9' fill='rgba(255, 255, 255, 0.85)'%3E%3Cpath d='M0.764331 9C0.973612 9 1.14195 8.92174 1.28753 8.76522L4.71338 5.175C4.90446 4.96467 4.99545 4.75924 5 4.5C5 4.24565 4.90901 4.03043 4.71338 3.825L1.28753 0.229891C1.14195 0.0782609 0.969063 0 0.764331 0C0.341219 0 0 0.361957 0 0.811957C0 1.03696 0.0864422 1.24239 0.245678 1.41359L3.21201 4.50489L0.245678 7.58641C0.0864422 7.75761 0 7.96304 0 8.18804C0 8.63315 0.341219 9 0.764331 9Z'/%3E%3C/svg%3E");
}
body.dark .context-menu-layer .context-menu .menu-background .menu-ui-body .menu-ui .sub-menu-title.open.hover > .after:after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='9' viewBox='0 0 5 9' fill='rgba(255, 255, 255, 0.85)'%3E%3Cpath d='M0.764331 9C0.973612 9 1.14195 8.92174 1.28753 8.76522L4.71338 5.175C4.90446 4.96467 4.99545 4.75924 5 4.5C5 4.24565 4.90901 4.03043 4.71338 3.825L1.28753 0.229891C1.14195 0.0782609 0.969063 0 0.764331 0C0.341219 0 0 0.361957 0 0.811957C0 1.03696 0.0864422 1.24239 0.245678 1.41359L3.21201 4.50489L0.245678 7.58641C0.0864422 7.75761 0 7.96304 0 8.18804C0 8.63315 0.341219 9 0.764331 9Z'/%3E%3C/svg%3E");
}

#uiWindow-layer .text-align {
  background: var(--ui-background-container);
  width: var(--height-row);
  height: calc(var(--height-row) * 3);
  padding: 0;
  margin-top: calc((-1 * var(--height-row-numeric) + 4) * 1px);
  margin-left: 6px;
}
#uiWindow-layer .window-layer-focus-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: var(--layer-9);
}

body {
  --grid-column-count: 8;
  --grid-row-count: 8;
  --grid-subcell-count: 3;
  --grid-width-sans-pad: calc(calc(var(--viewport-width) - var(--spacing-2)));
  --grid-height-sans-pad: calc(calc(var(--viewport-height) - var(--spacing-2)));
  --grid-template-columns: repeat(4, 1fr) repeat(4, 1fr);
  --grid-line-golden: 5;
  --grid-area-golden: 1 / var(--grid-line-golden) / -1 / -1;
  --grid-column-golden: var(--grid-line-golden) / -1;
  --grid-system-rows: repeat(var(--grid-row-count), 1fr);
}

body.mobile {
  --grid-column-count: 4;
  --grid-line-golden: 2;
  --grid-column-golden: 2 / -1;
  --grid-area-golden: 1 / 2 / -1 / -1;
  --grid-template-columns: repeat(var(--grid-column-count), 1fr);
}

body.not-authorized {
  background-color: var(--baseColor-container);
}

body.mobile .login-options button.account {
  display: none !important;
}

#login {
  --login-window-width: var(--ui-width-uiWindow);
  --login-input-height: 20px;
}
#login.login-window {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999999 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontFamily-default);
  /* transition values */
  --x1: 0.260;
  --y1: 0.270;
  --x2: 0.380;
  --y2: 0.925;
  --enter: cubic-bezier(var(--x1), var(--y1), var(--x2), var(--y2));
  /* Reversed transition values */
  --x1-r: calc(1 - var(--x2));
  --y1-r: calc(1 - var(--y2));
  --x2-r: calc(1 - var(--x1));
  --y2-r: calc(1 - var(--y1));
  --exit: cubic-bezier(var(--x1-r), var(--y1-r), var(--x2-r), var(--y2-r));
  opacity: 0;
  transition: opacity 0.28s var(--enter);
}
#login.login-window.active {
  opacity: 1;
  transition: opacity 0.28s var(--enter);
}
#login.login-window.active .login-modal {
  transform: translateY(0);
  opacity: 1;
  transition: opacity 0.28s var(--enter), transform 0.28s var(--enter);
}
#login.login-window.active-immediate {
  opacity: 1;
  transition: none;
}
#login.login-window.active-immediate .login-modal {
  transform: translateY(0);
  opacity: 1;
  transition: none;
}
#login form {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--login-input-height) var(--ui-padding-uiWindow) var(--ui-padding-uiWindow) var(--ui-padding-uiWindow);
}
#login form button,
#login form a {
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  background-color: transparent;
}
#login form button:active,
#login form a:active {
  opacity: var(--opacity-downstate-default);
}
#login form button.forgot,
#login form a.forgot {
  display: inline-block;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.5);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.5);
  margin: 0 0 var(--ui-padding-default-vertical) 5px;
  font-family: var(--fontFamily-default);
  font-size: var(--fontSize-default);
  padding: 0;
  border: none;
}
#login form button.account,
#login form a.account {
  display: inline-block;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.5);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.5);
  margin: 0 0 var(--ui-padding-default-vertical) 5px;
  font-family: var(--fontFamily-default);
  font-size: var(--fontSize-default);
  padding: 0;
  border: none;
  text-decoration: none;
  line-height: normal;
}
#login form button.login,
#login form a.login {
  position: relative;
  background: rgba(var(--baseColor-accent-rgb), 1);
  color: rgba(var(--baseColor-accent-reverse-rgb), 1);
  fill: rgba(var(--baseColor-accent-reverse-rgb), 1);
  font-size: var(--fontSize-default);
  font-family: var(--fontFamily-default);
  font-weight: 500;
  justify-content: flex-start;
  line-height: 1;
  flex: 0 1 125px;
  text-align: left;
  padding: 6.5px var(--ui-padding-default-horizontal) 7.5px;
  border-radius: var(--ui-button-border-radius);
  -webkit-appearance: none;
  border: none;
  white-space: nowrap;
  display: flex;
  min-height: 28.5px;
}
#login form .field-message {
  width: 100%;
  height: var(--height-row);
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
#login .login-header {
  padding: var(--ui-padding-uiWindow) 0;
  height: 44px;
}
#login .login-header .logo-box {
  width: 40px;
  height: 44px;
  position: absolute;
  left: 0;
  top: 0;
}
#login .login-header .title {
  text-align: center;
  font-family: var(--fontFamily-default);
  font-size: 17px;
  font-weight: 400;
  line-height: 0.8;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
#login .enter-email {
  width: 85%;
  line-height: 1.3;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  position: relative;
  bottom: 8.5px;
  flex: calc(var(--height-row) + 1px) 0;
  text-align: left;
  padding-top: 1px;
  margin-bottom: 6px;
  margin-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: break-spaces;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
#login .terms {
  display: inline-block;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.5);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.5);
  margin: 0 0 30px 5px;
  font-family: var(--fontFamily-default);
  font-size: var(--fontSize-small);
  padding: 0;
  border: none;
  text-align: left;
  line-height: 1.4;
}
#login .terms a {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.5);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.5);
  font-size: var(--fontSize-small);
  text-decoration: underline;
  text-decoration-color: rgba(var(--baseColor-default-reverse-rgb), 0.25);
}
#login .terms a:active {
  opacity: var(--opacity-downstate-default);
}
#login .login-options {
  flex: 1 1;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
}
#login .login-modal {
  background-color: var(--ui-background-container);
  width: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: var(--window-boxShadow-default);
  transform-origin: bottom center;
  transform: translateY(-15px);
  transition: opacity 0.28s var(--enter), transform 0.28s var(--enter);
  border-radius: var(--modalRadiusLarge);
  font-size: var(--fontSize-default);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  line-height: var(--lineHeight-default);
  position: fixed;
  border-radius: var(--modalRadiusLarge-inner);
  letter-spacing: var(--account-manager-letter-spacing);
}
#login .login-modal .under-input-row {
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  padding-top: 11px;
  padding-right: 5px;
  margin-top: -1px;
}
#login .login-modal .under-input-row .emoji {
  font-family: -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
#login .login-modal:after {
  content: "";
  box-shadow: inset 0px 0px 0px 1px rgba(var(--baseColor-default-reverse-rgb), 0.06);
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  pointer-events: none;
  border-radius: var(--modalRadiusLarge);
}
#login .input-fields {
  display: inline-grid;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  grid-auto-columns: auto;
  grid-gap: var(--element-gap);
  align-content: flex-start;
  padding-bottom: 30px;
  width: 100%;
}
#login .input-fields.new {
  padding-bottom: 15px;
}
#login .text-input {
  width: 100%;
  height: var(--height-row);
  margin-left: auto;
  background: transparent;
  border-radius: var(--height-row);
}
#login .input {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  margin-top: -6px;
}
#login input {
  border: none;
  border-radius: var(--height-row);
  font-family: var(--fontFamily-default);
  font-size: var(--fontSize-default);
  line-height: calc(var(--ui-lineHeight-default) - 0.1);
  color: var(--ui-color-default);
  caret-color: var(--ui-color-default);
  background: var(--ui-background-default);
  width: 100%;
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: calc(var(--ui-padding-default-vertical) - 0px);
  padding-bottom: calc(var(--ui-padding-default-vertical) + 1px);
  padding-left: calc(var(--ui-padding-default-horizontal) + 2px);
  padding-right: var(--ui-padding-default-horizontal);
}
#login input::-webkit-input-placeholder {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  -webkit-text-fill-color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
}
#login input:-webkit-autofill, #login input:-webkit-autofill:hover, #login input:-webkit-autofill:focus, #login input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--ui-background-default) inset !important;
  -webkit-text-fill-color: var(--ui-color-flat-reverse) !important;
  background: var(--ui-background-default) !important;
  background-clip: content-box !important;
}

.dark #login .login-modal::after {
  box-shadow: none;
  border: 1px solid var(--ui-window-border-background);
  inset: 0;
}

.mobile #login .login-modal {
  max-width: var(--ui-maxWidth-mobile);
}

.hint {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.75);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.75);
  background: var(--ui-hint-background);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08), 4px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 9px 13px 10px;
  border-radius: 7px;
  line-height: 1.3;
  position: relative;
  font-family: var(--fontFamily-default);
  font-size: 14.5px;
}
.hint .window-link {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.75);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.75);
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
  text-decoration-color: rgba(var(--baseColor-default-reverse-rgb), 0.75);
}
.hint .window-link:active {
  opacity: 0.7;
}
.hint .hint-seen {
  position: absolute;
  top: 12px;
  right: 7px;
  font-size: 1.75em;
  line-height: 0;
  font-weight: 200;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  color: var(--ui-color-flat-reverse);
}
.hint em {
  font-style: normal;
  color: #000 !important;
  opacity: 1 !important;
  font-size: 20px;
  line-height: 0;
}

body.mobile .hint {
  font-size: 12.5px;
}

body[cargo-jobs] {
  --border-color: rgba(var(--baseColor-default-reverse-rgb), .15);
  font-family: var(--fontFamily-CargoDiatype);
  font-weight: var(--fontWeight-CargoDiatype);
  letter-spacing: 0;
  font-style: normal;
  overflow: auto;
}
body[cargo-jobs].authenticated {
  --grid-column-gap: var(--spacing-1);
}
body[cargo-jobs].authenticated #manager {
  grid-gap: var(--grid-column-gap);
}
body[cargo-jobs].authenticated #manager [job-page] {
  --paddingTop: calc(var(--topAlignment) - var(--height-row) - 5px);
  --proxy-grid-cell-width: calc((var(--viewport-width) - 286px - var(--spacing-2)) / 8);
  max-height: calc(var(--viewport-height) - var(--height-row));
  line-height: var(--lineHeight);
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: var(--grid-column-gap);
  grid-column: 2/-1;
  grid-template-rows: none;
  padding-top: var(--paddingTop);
  margin: 0;
  margin-left: calc(var(--spacing-1) * -1);
  margin-right: calc(var(--spacing-1) * -1);
  overflow-y: scroll;
}
body[cargo-jobs].authenticated #manager [job-page]::before {
  height: calc(var(--paddingTop) + var(--height-row) + var(--element-gap));
  left: calc(var(--leftMenuBar-width) + var(--spacing-2));
  border-bottom: 0;
}
body[cargo-jobs].authenticated #manager [job-page]:after {
  display: none;
}
body[cargo-jobs].authenticated #manager [job-page] .right-column::before {
  display: none;
}
body[cargo-jobs].authenticated #manager [job-page] .right-column .right-column-inner-1 {
  position: fixed;
  max-width: calc(var(--proxy-grid-cell-width) * 2 + var(--grid-column-gap));
}
body[cargo-jobs].authenticated #manager [job-page] .right-column .right-column-inner-1 .company-details > * {
  max-width: calc(var(--proxy-grid-cell-width) * 2 - 1ch);
}
body[cargo-jobs].authenticated #manager [job-page] .right-column .right-column-inner-1 .company-name span {
  max-width: calc(var(--proxy-grid-cell-width) * 2 - var(--spacing-1) - 1ch);
}
body[cargo-jobs].authenticated #manager [job-page] .right-column .right-column-inner-2 .job-title {
  max-width: calc(var(--proxy-grid-cell-width) * 3 + var(--grid-column-gap) * 2);
}
body[cargo-jobs].unauthenticated [job-page] {
  min-height: var(--viewport-height);
}
body[cargo-jobs] #manager {
  display: grid;
  grid-template-columns: var(--grid-template-columns);
  grid-column-gap: var(--grid-column-gap);
  font-family: var(--fontFamily-CargoDiatype);
  font-weight: var(--fontWeight-CargoDiatype);
  letter-spacing: 0;
  font-style: normal;
  --content-row-start: 2;
  --lineHeight: 1.25;
  --lineReturn-height: calc(var(--fontSize-default) * var(--lineHeight));
  --row-rule-padding: calc((var(--height-row) - (var(--lineReturn-height))) * 0.5);
  /* STYLES ABOVE SHOULD BE PULLED FROM GLOBAL SOMEHOW — likely defined twice on cargo.site */
  --jobs-top-pos: calc( var(--grid-cell-height) + var(--spacing-1));
  --jobs-top-content: calc( var(--jobs-top-pos) + var(--spacing-1));
  --six-column-width: calc(var(--grid-cell-width) * 6 + (var(--grid-column-gap)) * 5);
  --close-button-size: var(--height-row);
  --close-button-offset: calc(var(--grid-cell-height) + var(--spacing-1) + 5px);
  position: relative;
  min-height: var(--viewport-height);
  padding: var(--spacing-1);
  z-index: var(--layer-1);
  position: fixed;
  inset: 0;
}
body[cargo-jobs] #manager [job-page] {
  --paddingTop: var(--height-row);
  display: grid;
  grid-column: 1/-1;
  grid-template-columns: var(--grid-template-columns);
  grid-column-gap: var(--grid-column-gap);
  grid-template-rows: var(--grid-system-rows);
  grid-row-gap: 0px;
  background: var(--background-3);
  height: calc(var(--viewport-height) - var(--spacing-2));
  min-height: var(--viewport-height);
  padding: var(--spacing-1);
  margin: calc(-1 * var(--spacing-1));
  font-family: var(--fontFamily-CargoDiatype);
  text-align: left;
  font-weight: 400;
  font-style: normal;
  line-height: var(--lineHeight);
  color: var(--color-default-secondary);
}
body[cargo-jobs] #manager [job-page] button,
body[cargo-jobs] #manager [job-page] button.text-button,
body[cargo-jobs] #manager [job-page] a,
body[cargo-jobs] #manager [job-page] span,
body[cargo-jobs] #manager [job-page] .select-display,
body[cargo-jobs] #manager [job-page] input,
body[cargo-jobs] #manager [job-page] div[contenteditable=true] {
  font-family: var(--fontFamily-CargoDiatype);
}
body[cargo-jobs] #manager [job-page]:before {
  content: "";
  height: calc(var(--grid-cell-height) + var(--spacing-1));
  width: auto;
  top: 0;
  position: fixed;
  background: var(--background-3);
  z-index: var(--layer-3);
  left: var(--spacing-1);
  right: var(--spacing-1);
}
body[cargo-jobs] #manager [job-page]:after {
  content: "";
  height: 1px;
  width: auto;
  top: calc(var(--grid-cell-height) + var(--spacing-1));
  position: fixed;
  left: var(--spacing-1);
  right: var(--spacing-1);
  z-index: var(--layer-3);
  background: var(--border-color);
}
body[cargo-jobs] #manager [job-page] .ui-group.text-ui {
  display: block;
  grid-gap: 0;
}
body[cargo-jobs] #manager [job-page] .ui-group .ui-group-spacer {
  height: var(--lineReturn-height);
}
body[cargo-jobs] #manager [job-page] .ui-group button.checked {
  color: var(--color-default);
}
body[cargo-jobs] #manager [job-page] .text-button {
  color: var(--color-default-secondary);
  text-decoration: underline;
  text-decoration-color: rgba(var(--baseColor-default-reverse-rgb), 0.25);
  line-height: var(--lineHeight);
}
body[cargo-jobs] #manager [job-page] .text-button.link-copied {
  text-decoration: none;
}
body[cargo-jobs] #manager [job-page] .left-column {
  grid-column: 1/span 2;
  grid-row: var(--content-row-start)/-1;
  position: relative;
}
body[cargo-jobs] #manager [job-page] .left-column .email {
  color: var(--color-default);
}
body[cargo-jobs] #manager [job-page] .left-column .email,
body[cargo-jobs] #manager [job-page] .left-column .job-filters {
  position: fixed;
}
body[cargo-jobs] #manager [job-page] .left-column .job-filters .text-checkbox .checkbox {
  line-height: var(--lineHeight);
}
body[cargo-jobs] #manager [job-page] .left-column .job-filters .filter-button.text-button {
  text-decoration: none;
}
body[cargo-jobs] #manager [job-page] .right-column {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: var(--grid-column-gap);
  grid-column: 3/-1;
  grid-row: var(--content-row-start)/-1;
  position: relative;
}
body[cargo-jobs] #manager [job-page] .right-column .right-column-inner-1 {
  grid-column: 1/span 2;
  max-width: calc(var(--grid-cell-width) * 2 + var(--grid-column-gap));
}
body[cargo-jobs] #manager [job-page] .right-column .right-column-inner-1 .company-name {
  color: var(--ui-color-default);
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: calc(var(--grid-cell-width) * 2 + var(--grid-column-gap) - 1ch);
}
body[cargo-jobs] #manager [job-page] .right-column .right-column-inner-1 .company-name:active {
  opacity: var(--opacity-downstate-default);
}
body[cargo-jobs] #manager [job-page] .right-column .right-column-inner-1 .company-name span {
  line-height: var(--lineHeight);
}
body[cargo-jobs] #manager [job-page] .right-column .right-column-inner-1 .company-details {
  padding-top: var(--row-rule-padding);
  display: block;
  max-width: fit-content;
  background: none;
  line-height: var(--lineHeight);
  text-align: left;
  white-space: nowrap;
}
body[cargo-jobs] #manager [job-page] .right-column .right-column-inner-1 .company-details div.checked {
  color: var(--ui-color-default);
}
body[cargo-jobs] #manager [job-page] .right-column .right-column-inner-2 {
  grid-column: 3/-1;
  position: relative;
}
body[cargo-jobs] #manager [job-page] .right-column .right-column-inner-2 .job-details {
  padding-top: var(--row-rule-padding);
  color: var(--ui-color-default);
  grid-gap: var(--lineReturn-height);
}
body[cargo-jobs] #manager [job-page] .right-column .right-column-inner-2 .job-description {
  padding-bottom: var(--grid-cell-height);
  line-height: var(--lineHeight);
  letter-spacing: 0;
}
body[cargo-jobs] #manager [job-page] .right-column .right-column-inner-2 .job-description p,
body[cargo-jobs] #manager [job-page] .right-column .right-column-inner-2 .job-description ul,
body[cargo-jobs] #manager [job-page] .right-column .right-column-inner-2 .job-description ol,
body[cargo-jobs] #manager [job-page] .right-column .right-column-inner-2 .job-description li,
body[cargo-jobs] #manager [job-page] .right-column .right-column-inner-2 .job-description strong,
body[cargo-jobs] #manager [job-page] .right-column .right-column-inner-2 .job-description b {
  line-height: var(--lineHeight);
}
body[cargo-jobs] #manager [job-page] .right-column .right-column-inner-2 .job-description a:active {
  opacity: var(--opacity-downstate-default);
}
body[cargo-jobs] #manager [job-page] .right-column .right-column-inner-2 .job-title {
  line-height: calc(var(--lineReturn-height) * 2);
  top: calc(var(--lineReturn-height) * -0.5);
  position: relative;
  white-space: nowrap;
  max-width: calc(var(--grid-cell-width) * 3 + var(--grid-column-gap) * 2);
  text-overflow: ellipsis;
  overflow-x: hidden;
}
body[cargo-jobs] #manager [job-page] .button-close {
  height: var(--close-button-size);
  width: var(--close-button-size);
  position: fixed;
  top: var(--close-button-offset);
  right: 5px;
  z-index: var(--layer-2);
}
body[cargo-jobs] #manager [job-page] .button-close:active {
  opacity: var(--opacity-downstate-default);
}
body[cargo-jobs] #manager [job-page] .button-close button {
  display: flex;
  pointer-events: all;
  height: var(--close-button-size);
  width: var(--close-button-size);
}
body[cargo-jobs] #manager [job-page] .button-close button svg {
  height: var(--close-button-size);
  width: var(--close-button-size);
}
body[cargo-jobs] #manager [job-page] .button-close button svg rect {
  fill: transparent;
}
body[cargo-jobs] #manager [job-page] .button-close button svg path {
  stroke: var(--ui-color-default);
}
body[cargo-jobs] #manager [job-page] .save-cancel-buttons {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
body[cargo-jobs] #manager [job-page] .save-cancel-buttons button,
body[cargo-jobs] #manager [job-page] .save-cancel-buttons button span,
body[cargo-jobs] #manager [job-page] .save-cancel-buttons button div {
  font-family: var(--fontFamily-default);
}
body[cargo-jobs] #manager [job-page] .save-cancel-buttons button.confirm {
  color: var(--ui-color-flat);
  background: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  justify-content: space-between;
}
body[cargo-jobs] #manager [job-page] .save-cancel-buttons button.cancel, body[cargo-jobs] #manager [job-page] .save-cancel-buttons button.preview {
  outline-color: var(--baseColor-border);
}
body[cargo-jobs] #manager [job-page] .filter-button {
  padding-top: var(--row-rule-padding);
}
body[cargo-jobs] #manager [job-page] .email {
  height: var(--height-row);
  line-height: 1;
  display: flex;
  align-items: center;
}
body[cargo-jobs] #manager [job-page] .text-select.select.error label .select-display .select-prefix,
body[cargo-jobs] #manager [job-page] .text-select.select.error label .select-display .select-value {
  color: var(--ui-color-error);
}
body[cargo-jobs] #manager [job-page] .text-select.select.unset label .select-display .select-prefix,
body[cargo-jobs] #manager [job-page] .text-select.select.unset label .select-display .select-value {
  color: var(--color-default-secondary);
}
body[cargo-jobs] #manager [job-page] .prose-editor.error .editable-area .ProseMirror .empty-node::before {
  color: var(--ui-color-error) !important;
}
body[cargo-jobs] #manager [job-page] .ProseMirror {
  font-size: inherit;
}
body[cargo-jobs] #manager [job-page] .ProseMirror * {
  line-height: var(--lineHeight);
}
body[cargo-jobs] #manager [job-page] .ProseMirror p:empty {
  display: block;
  height: var(--lineReturn-height);
  margin: 0;
  padding: 0;
}
body[cargo-jobs] #manager [job-page] .ProseMirror ul li, body[cargo-jobs] #manager [job-page] .ProseMirror ol li {
  white-space: break-spaces;
}
body[cargo-jobs] #manager [job-page] .ProseMirror ul {
  padding: 0 0 0 2em;
}
body[cargo-jobs] #manager [job-page] .ProseMirror ul li {
  list-style-type: disc;
  margin-left: 0.15em;
}
body[cargo-jobs] #manager [job-page] .ProseMirror ul ul li {
  list-style-type: disc;
}
body[cargo-jobs] #manager [job-page] .ProseMirror ol {
  padding: 0 0 0 2.5em;
}
body[cargo-jobs] #manager [job-page] .ProseMirror ol li {
  list-style-type: decimal;
  margin-left: 0.15em;
}
body[cargo-jobs] #manager [job-page] .ProseMirror ol ol li {
  list-style-type: decimal;
}
body[cargo-jobs] #manager [job-page] .ProseMirror blockquote {
  margin: 0;
  padding: 0 0 0 2em;
}
body[cargo-jobs] #manager [job-page] .ProseMirror a {
  font-size: var(--fontSize-default);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  text-decoration: underline;
  text-decoration-color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
}
body[cargo-jobs] #manager [job-page] .ProseMirror .empty-node::before {
  content: attr(data-placeholder);
  color: var(--color-default-secondary);
  pointer-events: none;
  height: 0;
  display: block;
}
body[cargo-jobs].mobile.authenticated #main-menu.top-menu-bar::before {
  content: "";
  position: absolute;
  width: var(--viewport-width);
  height: 0.5px;
  background-color: var(--baseColor-border);
  z-index: 1;
}
body[cargo-jobs].mobile.authenticated #manager [job-page]::before {
  top: var(--height-row);
  background: var(--background-3);
}
body[cargo-jobs].mobile.authenticated #manager [job-page] .left-column {
  grid-row: 1/span 1;
}
body[cargo-jobs].mobile.authenticated #manager [job-page] .right-column {
  margin-top: 0;
}
body[cargo-jobs].mobile.unauthenticated #manager [job-page] {
  grid-template-rows: none;
}
body[cargo-jobs].mobile.unauthenticated #manager [job-page]::before {
  top: 0;
  height: calc(var(--height-row) + var(--spacing-2) + var(--lineReturn-height) * 3);
}
body[cargo-jobs].mobile.unauthenticated #manager [job-page] .left-column.category-filters {
  margin-top: var(--height-row);
  grid-row: 1/span 1;
}
body[cargo-jobs].mobile.unauthenticated #manager [job-page] .right-column .job-list {
  top: 0;
}
body[cargo-jobs].mobile #manager [job-page] {
  --close-button-offset: 5px;
  padding: 0 var(--spacing-1);
}
body[cargo-jobs].mobile #manager [job-page]::after {
  top: var(--height-row);
  left: 0;
  right: 0;
  background: var(--border-color);
}
body[cargo-jobs].mobile #manager [job-page]::before {
  top: calc(var(--height-row) - (var(--height-row) - var(--lineReturn-height)) * 0.5);
  left: var(--spacing-1);
  width: calc(100% - var(--spacing-2));
  height: calc(var(--spacing-2) + var(--lineReturn-height) * 3);
  background: var(--background-3);
  box-shadow: 0 1px var(--border-color);
}
body[cargo-jobs].mobile #manager [job-page] .left-column {
  grid-column: 1/-1;
  grid-row: 2/span 1;
}
body[cargo-jobs].mobile #manager [job-page] .left-column.category-filters {
  height: 0;
  z-index: var(--layer-3);
}
body[cargo-jobs].mobile #manager [job-page] .left-column .job-filters {
  padding: var(--spacing-1) 0;
  width: calc(100% - 30px);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: var(--spacing-1);
}
body[cargo-jobs].mobile #manager [job-page] .left-column .job-filters .text-button,
body[cargo-jobs].mobile #manager [job-page] .left-column .job-filters button {
  z-index: var(--layer-3);
}
body[cargo-jobs].mobile #manager [job-page] .left-column .job-filters .filter-button {
  display: block;
  height: unset;
  padding-top: 0;
}
body[cargo-jobs].mobile #manager [job-page] .left-column .email {
  z-index: var(--layer-3);
}
body[cargo-jobs].mobile #manager [job-page] .right-column {
  margin-top: calc(var(--height-row) + var(--spacing-2) + var(--lineReturn-height) * 3);
  grid-column: 1/-1;
  grid-row: 3/-1;
}
body[cargo-jobs].mobile #manager [job-page] .right-column .right-column-inner-1 .company-name {
  max-width: unset;
}
body[cargo-jobs].mobile #manager [job-page] .right-column .right-column-inner-2 {
  overflow: hidden;
}
body[cargo-jobs].mobile #manager [job-page] .right-column .right-column-inner-2 .job-title {
  max-width: unset;
}
body[cargo-jobs].mobile #manager [job-page] .right-column .job-list {
  top: calc(var(--spacing-2) + var(--lineReturn-height) * 3);
}
body[cargo-jobs].mobile #manager [job-page] .ui-group .ui-group-spacer.desktop {
  display: none;
}
body[cargo-jobs].mobile #manager [job-page] .button-close {
  position: absolute;
  top: var(--close-button-offset);
  right: 0;
}
body[cargo-jobs].dark {
  --background-2: var(--background-1);
  --background-3: var(--background-1);
}

body {
  --intercom-messenger-right: 1.05rem;
  --intercom-messenger-bottom: 1.05rem;
  --line-height-vertical-offset: -.2em;
  --row-rule-offset: calc(-1 * var(--spacing-1));
  --leftMenuBar-width: 270px;
  --topAlignment: calc(var(--height-row) + var(--height-row));
  --borderRadius-small: 5px;
  font-family: var(--fontFamily-CargoDiatype);
  text-align: left;
  font-weight: 400;
  font-style: normal;
  line-height: var(--lineHeight-default);
  background-color: var(--background-1);
}
body .sub-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: var(--grid-subcolumn-gap);
}
body #manager {
  display: grid;
  grid-template-columns: var(--grid-template-columns);
  grid-column-gap: var(--grid-column-gap);
  font-family: var(--fontFamily-CargoDiatype);
  font-weight: var(--fontWeight-CargoDiatype);
  letter-spacing: 0;
  font-style: normal;
  position: relative;
  min-height: var(--viewport-height);
  padding: var(--spacing-1);
  z-index: var(--layer-1);
}
body #manager > * {
  grid-column: 1/-1;
}
body[data-route=folder] {
  background-color: var(--background-3);
}
body.unauthenticated #manager.static-page-active {
  padding: 0;
}
body.authenticated {
  --gtc-authenticatedPage: repeat(1, var(--leftMenuBar-width)) repeat(7, 1fr);
}
body.authenticated #manager {
  --grid-template-columns: var(--gtc-authenticatedPage);
  height: auto;
  padding-top: var(--height-row);
  grid-gap: 0;
}
body.authenticated.mobile {
  --gtc-authenticatedPage: auto;
}
body.authenticated.dark[data-route=templates], body.authenticated.dark[data-route=folder], body.authenticated.dark[data-route=public-folder] {
  --background-3: #111111;
}
body.mobile {
  --leftMenuBar-width: 0;
  overflow-x: hidden;
}
body.dark {
  --background-2: var(--background-1);
  --background-3: var(--background-1);
  --baseColor-border: #313032;
}

.clickout-layer {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: default;
  z-index: -1;
}

/* Base Color and Background Mixins */
#header {
  text-align: center;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.35);
  font-weight: 400;
  display: grid;
  grid-column: 1/-1;
  position: relative;
  top: var(--ui-padding-default-vertical);
}

#header .title {
  display: none;
}

#header .message {
  font-size: var(--fontSize-default);
}

#header .message button.empty-trash {
  font-size: calc(var(--fontSize-default) - 1px);
  line-height: 1;
  border-radius: 100px;
  user-select: none;
  font-weight: 500;
  cursor: pointer;
  padding: 0.18em 0.6em 0.28em;
  font-family: var(--fontFamily-default);
  background: rgba(var(--baseColor-default-reverse-rgb), 0.25);
  color: var(--background-3);
  letter-spacing: 0;
  margin: var(--spacing-half) auto 0;
}

#header .page {
  height: 100%;
  display: grid;
  grid-template-columns: var(--grid-template-columns);
  grid-column-gap: var(--grid-column-gap);
  display: grid;
  grid-template-rows: var(--grid-system-rows);
  grid-row-gap: 0px;
  grid-row: 1/-1;
  grid-column: 1/-1;
}

#header .sub-grid {
  grid-column: 5/span 1;
  grid-row: 3/span 1;
}

body.dark #header {
  --globe-color: #393939;
}

.mobile #header .sub-grid {
  grid-column: 2/span 1;
}

.mobile #header .no-sites {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

/* Base Color and Background Mixins */
body[data-route=home] #manager {
  background: var(--background-3);
}

body[data-route=home] .top-menu-bar {
  background: transparent;
}

body[data-route=home] .top-menu-bar .button-link {
  background: transparent;
}

body[data-route=home] .top-menu-bar #home-menu .button-link.intro, body[data-route=home] .top-menu-bar #home-menu .button-link.intro > * {
  pointer-events: none;
}

#home-page {
  --dinamo-size: 36px;
  display: grid;
  grid-template-columns: var(--grid-template-columns);
  grid-column-gap: var(--grid-column-gap);
  display: grid;
  grid-template-rows: var(--grid-system-rows);
  grid-row-gap: 0px;
  height: calc(var(--min-viewport-height) - var(--spacing-2));
  line-height: var(--lineHeight-default);
}

#home-page h1,
#home-page h2,
#home-page h2 button {
  --color-default: #3f3f3f;
  color: var(--color-default);
  font-family: var(--fontFamily-CargoDiatype);
  font-style: normal;
  font-weight: 500;
  margin: 0;
  padding: 0;
  font-size: calc(var(--grid-subcell-height) + var(--grid-subcell-height) / 8);
  top: calc(var(--grid-subcell-height) / -32);
  line-height: 1;
  width: var(--grid-cell-width);
  letter-spacing: -0.025em;
  position: relative;
  white-space: nowrap;
  user-select: none;
}

#home-page h1 {
  grid-column: 1/span 1;
  grid-row: 3/span 1;
}

#home-page h1 .cargo .c {
  margin-right: 0.5px;
}

#home-page h1 .cargo .r {
  margin-left: -0.3px;
}

#home-page h1 .site-builder .end-icon {
  font-size: 0.4em;
  font-weight: 300;
  position: absolute;
  margin-left: 0.175em;
  margin-top: 0.25em;
}

#home-page h2 {
  grid-column: var(--grid-line-golden)/span 1;
  grid-row: 3/span 1;
}

#home-page h2 .start .t {
  margin-right: 0.75px;
}

#home-page h2 .start .a {
  margin-right: -0.25px;
}

#home-page h2 .start .r {
  margin-right: 1.5px;
}

#home-page h2 .templates,
#home-page h2 .start {
  color: var(--color-default);
  cursor: pointer;
}

#home-page h2 .templates .arrow,
#home-page h2 .start .arrow {
  visibility: hidden;
}

#home-page h2 .templates:hover .arrow,
#home-page h2 .start:hover .arrow {
  visibility: visible;
}

#home-page h2 .templates:active,
#home-page h2 .start:active {
  opacity: var(--opacity-downstate-default);
}

#home-page h2 .arrow {
  font-weight: 200;
}

#home-page h2 button {
  opacity: inherit;
}

#home-page .fixed-information {
  display: grid;
  grid-template-columns: var(--grid-template-columns);
  grid-gap: var(--grid-column-gap);
  line-height: 1.15;
  z-index: var(--layer-2);
  position: fixed;
  left: var(--spacing-1);
  top: 0;
  transform: translateY(calc(var(--min-viewport-height) - 100% - var(--spacing-1)));
  right: var(--spacing-1);
  opacity: 1;
  transition: opacity 0.5 ease-in-out;
}

#home-page .fixed-information.text {
  mix-blend-mode: difference;
}

#home-page .fixed-information.text span {
  -webkit-mix-blend-mode: difference;
  mix-blend-mode: difference;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

#home-page .fixed-information.image span::after {
  content: "Cargo is a site builder for designers and artists. We are interested in two main things: helping creative people secure jobs/clients through showing work and displaying work, generally. As well, we host a network of peers to show great sites and work. This is Cargo’s passion and purpose. [more...]";
  visibility: hidden;
}

#home-page .fixed-information.image span .fonts-link {
  position: absolute;
  top: calc(var(--dinamo-size) * -2);
}

#home-page .fixed-information.image span .fonts-link:active .dinamo-image {
  opacity: var(--opacity-downstate-default);
}

#home-page .fixed-information.image span .fonts-link .dinamo-image {
  height: var(--dinamo-size);
  background-repeat: no-repeat;
  background-size: contain;
}

#home-page .fixed-information .fonts-link:active .dinamo-image {
  opacity: var(--opacity-downstate-default);
}

#home-page .fixed-information.fade-out {
  opacity: 0;
}

#home-page .fixed-information span {
  grid-column: 6/-1;
  position: relative;
  margin-left: calc(var(--grid-subcell-width) + var(--grid-subcolumn-gap));
  color: #D9D9D9;
}

#home-page .fixed-information a.more-information {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  display: inline;
  color: #D9D9D9;
}

#home-page .fixed-information a.more-information:active {
  opacity: var(--opacity-downstate-default);
}

#home-page .mobile-arrow {
  --mobileArrow-size: 40px;
  position: absolute;
  left: calc((var(--viewport-width) - var(--mobileArrow-size)) / 2);
  top: calc((var(--min-viewport-height) - var(--mobileArrow-size)) / 2);
  height: calc(var(--mobileArrow-size) * 1.111);
  width: var(--mobileArrow-size);
  background: transparent;
  z-index: var(--layer-3);
  border-radius: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  display: none;
}

#home-page .mobile-arrow svg {
  width: calc(var(--mobileArrow-size) * 0.9);
  height: calc(var(--mobileArrow-size) * 0.9);
}

#home-page .mobile-arrow svg path {
  fill: rgb(var(--baseColor-default-reverse-rgb), 0.6);
  fill-opacity: 1;
}

#home-page .desktop {
  visibility: visible;
}

#home-page .mobile {
  visibility: hidden;
}

body.mobile.dark #home-page::before {
  background-color: var(--background-force-dark);
}

body.mobile #home-page {
  --dinamo-size: 25px;
  --grid-system-rows: repeat(5, 1fr);
}

body.mobile #home-page::before {
  content: "";
  position: absolute;
  inset: 0;
  height: calc(var(--viewport-height));
  background-color: var(--background-3);
}

body.mobile #home-page h1 {
  font-size: calc(var(--grid-subcell-height) + var(--grid-subcell-height) / 1.2);
  top: calc(var(--grid-subcell-height) / -8);
  grid-row: 2/span 1;
}

body.mobile #home-page .fixed-information {
  right: var(--spacing-1);
  position: absolute;
}

body.mobile #home-page .fixed-information span {
  grid-column: 1/-1;
  margin-left: 0;
}

body.mobile #home-page .desktop {
  visibility: hidden;
}

body.mobile #home-page .mobile {
  visibility: visible;
}

body.mobile.dark .fixed-information {
  position: fixed !important;
}

body.dark #home-page h1, body.dark #home-page h2, body.dark #home-page h2 button {
  color: #dcdcdc;
  font-family: var(--fontFamily-CargoDiatype);
  font-weight: 500;
  user-select: none;
}

body.dark #home-page h2 .templates,
body.dark #home-page h2 .start {
  color: #dcdcdc;
  cursor: pointer;
}

body.dark #home-page::before {
  background-color: var(--background-3);
}

body.dark.mobile #home-page::before {
  background-color: var(--background-force-dark);
}

/* Base Color and Background Mixins */
body[data-route=fonts] #manager {
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 0;
}

body[data-route=fonts] #manager #fonts-page .background {
  position: fixed;
  inset: 0;
  margin: 0;
  z-index: -1;
}

body[data-route=fonts] #fonts-page {
  --scale: 1;
  display: block;
  margin-top: calc(-1 * var(--spacing-1));
}

body[data-route=fonts] #fonts-page .background {
  background: var(--background-3);
  z-index: -1;
}

body[data-route=fonts] #fonts-page .axis-slider {
  font-size: calc(var(--scale) * var(--fontSize-default));
}

body[data-route=fonts].mobile #fonts-page {
  --scale: 0.6;
}

body[data-route=fonts].mobile #fonts-page .font-testers .font-tester span[contenteditable=true] {
  margin-top: 0;
  margin-bottom: 0;
}

body[data-route=fonts].mobile.unauthenticated #fonts-page {
  margin-left: 0;
}

body.authenticated[data-route=fonts] {
  padding-top: 0;
}

body.authenticated[data-route=fonts] #manager {
  padding-top: 0;
}

body.authenticated[data-route=fonts] #fonts-page {
  height: calc(var(--viewport-height) - var(--height-row));
  margin-top: var(--height-row);
  margin-left: calc(var(--leftMenuBar-width) + var(--spacing-1));
  padding-top: 0;
}

body.authenticated[data-route=fonts] #fonts-page .page-window .page-scroll::before {
  content: "Type Tester";
  position: absolute;
  font-family: var(--fontFamily-CargoDiatype);
  font-size: var(--fontSize-default);
  top: calc(var(--fontSize-default) * -1 - var(--spacing-1) - 1px);
}

body.authenticated[data-route=fonts] #fonts-page .dinamo-inside {
  top: 43.5px;
  right: 85.5px;
}

body.authenticated[data-route=fonts] #fonts-page .fill.row {
  height: calc(var(--height-row) * 2 - 2.5px);
}

/* <body> has the attribute data-route, not a child of <body>. so, this CSS should apply nowhere */
#fonts-page {
  --outline-background: #3f3f3f;
  --color-solid-60: #656565;
  --color-solid-85: #262626;
  display: block;
  color: var(--ui-color-default);
  line-height: var(--lineHeight-CargoDiatype);
  overflow-x: hidden;
  padding-left: var(--spacing-1);
}

#fonts-page .dinamo-inside {
  height: auto;
  width: 120px;
  position: fixed;
  right: 85px;
  top: calc(var(--grid-cell-height) - 65px);
  z-index: 1;
}

#fonts-page .dinamo-inside:active {
  opacity: var(--opacity-downstate-default);
}

#fonts-page .dinamo-inside #circle-fill {
  display: none;
}

#fonts-page .page-scroll {
  position: relative;
}

#fonts-page .fill.row {
  height: calc(var(--grid-cell-height) + var(--spacing-1));
}

#fonts-page .row.page-window {
  max-height: unset;
  min-height: unset;
}

#fonts-page .row.page-window:before {
  display: none;
}

#fonts-page .provider-link {
  margin-left: 15px;
  display: inline-block;
  text-decoration: none !important;
  border-bottom: 0 !important;
}

#fonts-page .provider-link span {
  color: var(--ui-color-default);
  font-style: normal;
  transform: translate(0, -2px);
  display: inline-block;
}

#fonts-page .provider-link img {
  width: 21px;
  height: auto;
  margin-right: 5px;
  transform: translate(0px, 1px);
}

#fonts-page .font-testers {
  position: relative;
  margin-right: calc(var(--spacing-1) * -1);
}

#fonts-page .font-testers .font-tester {
  padding: 0 0 var(--spacing-1) var(--spacing-1);
  width: 100%;
  overflow: hidden;
  padding-left: 0px;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  padding-left: var(--spacing-1);
  padding-right: var(--spacing-1);
  margin-left: calc(var(--spacing-1) * -1);
  margin-right: calc(var(--spacing-1) * -1);
}

#fonts-page .font-testers .font-tester button.copy {
  display: none;
}

#fonts-page .font-testers .font-tester:before {
  content: "";
  display: block;
  width: calc(100% + var(--spacing-1));
  height: 1px;
  background-color: rgba(var(--baseColor-default-reverse-rgb), 0.15);
}

#fonts-page .font-testers .font-tester span[contenteditable=true] {
  margin: var(--spacing-half) 0;
  padding: 0;
  line-height: 1.2;
  white-space: nowrap;
  transform: translateZ(0px);
  transition: font-size 33ms linear, font-variation-settings 33ms linear;
  display: block;
  letter-spacing: 0;
  padding-left: var(--spacing-1);
  padding-right: var(--spacing-1);
  margin-left: calc(var(--spacing-1) * -1);
  margin-right: calc(var(--spacing-1) * -1);
  font-size: 70px;
}

#fonts-page .font-grid .title {
  font-size: var(--fontSize-large);
  letter-spacing: 0;
  display: inline-block;
  border-radius: 300px;
  color: var(--ui-color-default);
  padding: 0 0.5em;
  margin: 40px 0 10px;
  font-weight: 450;
  line-height: 1.4;
}

#fonts-page .font-grid ul {
  width: calc(100% + var(--spacing-1));
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 1px;
  padding-left: var(--spacing-1);
  margin-left: calc(var(--spacing-1) * -1);
  padding-right: var(--spacing-1);
  margin-right: calc(var(--spacing-1) * -1);
}

#fonts-page .font-grid ul li {
  min-width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: block;
  white-space: nowrap;
  position: relative;
  padding: 0.2em 0;
  padding-left: var(--spacing-1);
  padding-right: var(--spacing-1);
  margin-left: calc(var(--spacing-1) * -1);
  margin-right: calc(var(--spacing-1) * -1);
  line-height: 1;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#fonts-page .font-grid ul li:before {
  content: "";
  display: block;
  width: calc(100% + var(--spacing-1));
  height: 1px;
  background-color: rgba(var(--baseColor-default-reverse-rgb), 0.2);
  position: absolute;
  top: -1px;
}

#fonts-page .font-grid ul li::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

#fonts-page .font-grid ul li:last-child:after {
  content: "";
  display: block;
  width: calc(100% + var(--spacing-1));
  height: 1px;
  background-color: rgba(var(--baseColor-default-reverse-rgb), 0.2);
  position: absolute;
  bottom: 1px;
}

#fonts-page .font-grid ul li.in-progress {
  color: transparent !important;
}

#fonts-page .font-grid ul li span.italic, #fonts-page .font-grid ul li i {
  font-style: normal !important;
}

#fonts-page .font-grid section ul li:last-child:after {
  content: "";
  display: block;
  width: calc(100% + var(--spacing-1));
  height: 1px;
  background-color: rgba(var(--baseColor-default-reverse-rgb), 0.2);
  position: absolute;
  bottom: 1px;
}

#fonts-page .font-grid section {
  margin-bottom: 100px;
  margin-top: 25px;
}

#fonts-page .sliders {
  position: relative;
  z-index: 10;
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 10px;
}

#fonts-page .sliders .axis-slider {
  --height: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-default);
  margin-right: var(--spacing-2);
  /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out 
          how to remove the virtical space around the range input in IE
  */
}

#fonts-page .sliders .axis-slider span {
  margin-right: 10px;
  white-space: nowrap;
}

#fonts-page .sliders .axis-slider input {
  margin: 7px 0;
  background-color: transparent;
  -webkit-appearance: none;
  min-width: calc(var(--grid-cell-width) - var(--spacing-2));
}

#fonts-page .sliders .axis-slider input[type=range] {
  position: relative;
  cursor: pointer;
}

#fonts-page .sliders .axis-slider input[type=range]:after {
  background: var(--color-solid-60);
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  height: 1px;
  top: calc(50% - 0.5px);
}

#fonts-page .sliders .axis-slider input[type=range]:focus {
  outline: none;
}

#fonts-page .sliders .axis-slider input[type=range]::-webkit-slider-runnable-track {
  background: transparent;
  border: 0;
  width: 100%;
  height: var(--height);
  cursor: pointer;
}

#fonts-page .sliders .axis-slider input[type=range]::-webkit-slider-thumb, #fonts-page .sliders .axis-slider input[type=range].indicator {
  width: var(--height);
  height: var(--height);
  background: var(--background-1);
  border: 1px solid var(--color-solid-60);
  border-radius: 50px;
  cursor: pointer;
  -webkit-appearance: none;
}

#fonts-page .sliders .axis-slider input[type=range]::-webkit-slider-thumb:hover {
  background: var(--color-solid-85);
}

#fonts-page .sliders .axis-slider input[type=range]::-webkit-slider-thumb:active {
  background: var(--color-solid-85);
}

#fonts-page .sliders .axis-slider input[type=range]::-moz-range-thumb:active {
  background: var(--color-solid-85);
}

#fonts-page .sliders .axis-slider input[type=range]:active::-webkit-slider-thumb {
  background: var(--color-solid-85);
}

#fonts-page .sliders .axis-slider input[type=range]::-moz-range-track {
  background: var(--color-default);
  border: 0;
  width: 100%;
  cursor: pointer;
  height: 1px;
}

#fonts-page .sliders .axis-slider input[type=range]::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: var(--background-1);
  border: 1px solid var(--color-default);
  border-radius: 50px;
  cursor: pointer;
}

#fonts-page .sliders .axis-slider input[type=range]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 7.05px 0;
  color: transparent;
  width: 100%;
  height: var(--height);
  cursor: pointer;
}

#fonts-page .sliders .axis-slider input[type=range]::-ms-fill-lower {
  background: var(--color-default);
  border: 0;
}

#fonts-page .sliders .axis-slider input[type=range]::-ms-fill-upper {
  background: var(--color-default);
  border: 0;
}

#fonts-page .sliders .axis-slider input[type=range]::-ms-thumb {
  width: 18px;
  height: 18px;
  background: var(--background-1);
  border: 1px solid var(--color-default);
  border-radius: 50px;
  cursor: pointer;
  margin-top: 0px;
  /* Needed to keep the Edge thumb centered */
}

#fonts-page .sliders .axis-slider input[type=range]:focus::-ms-fill-lower {
  background: var(--color-default);
}

#fonts-page .sliders .axis-slider input[type=range]:focus::-ms-fill-upper {
  background: var(--color-default);
}

#fonts-page .sliders .axis-slider .slide-wrapper {
  position: relative;
}

#fonts-page .sliders .axis-slider .indicator {
  position: absolute;
  margin-top: -1px;
  cursor: pointer;
}

@supports (-ms-ime-align: auto) {
  #fonts-page .sliders .axis-slider {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  }
  #fonts-page .sliders .axis-slider input[type=range] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}
#fonts-page .font-size-slider {
  padding-top: var(--grid-cell-height);
}

#fonts-page .font-size-slider .axis-slider {
  display: none;
  --height: 19px;
  --range-light: var(--background-1);
  --range-accent: var(--ui-color-default);
  width: 200px;
  height: calc(var(--height) + 2px);
  margin: 0;
  z-index: 999;
  border-radius: 108px;
  border: 1px solid var(--ui-color-default);
  padding: 0 1px;
  background: var(--baseColor-default);
  top: var(--spacing-1);
  position: sticky;
}

#fonts-page .font-size-slider .axis-slider input[type=range] {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  transform: 0;
  height: var(--height);
  width: 100%;
  border-radius: 108px;
}

#fonts-page .font-size-slider .axis-slider input[type=range]:focus {
  outline: none;
}

#fonts-page .font-size-slider .axis-slider input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px var(--range-accent);
  border: none;
  height: calc(var(--height) - 2px);
  width: calc(var(--height) - 2px);
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='15' fill='%23000000D9'/%3E%3C/svg%3E%0A");
  background-size: 100% 100%;
  background-repeat: none;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: 1px;
}

#fonts-page .font-size-slider .axis-slider input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px var(--range-accent);
  border: none;
  height: calc(var(--height) - 2px);
  width: calc(var(--height) - 2px);
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='15' fill='%23000000D9'/%3E%3C/svg%3E%0A");
  background-size: 100% 100%;
  background-repeat: none;
  cursor: pointer;
}

#fonts-page .font-size-slider .axis-slider input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--range-light);
}

#fonts-page .font-size-slider .axis-slider input[type=range]::-moz-range-track {
  width: 100%;
  height: var(--height);
  cursor: pointer;
  animate: none;
  box-shadow: 0px 0px 0px var(--range-accent);
  background: var(--range-light);
  border-radius: var(--height);
  border: 0;
}

#fonts-page .font-size-slider .axis-slider input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: var(--height);
  cursor: pointer;
  animate: none;
  box-shadow: 0px 0px 0px var(--range-accent);
  background: rgba(var(--baseColor-default-rgb), 1);
  border-radius: var(--height);
  border: 0;
}

#fonts-page .editable {
  height: inherit;
  overflow: hidden;
  padding-right: var(--spacing-1);
  margin-right: calc(var(--spacing-1) * -1);
  padding-left: var(--spacing-2);
  margin-left: calc(var(--spacing-2) * -1);
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#fonts-page .editable::-webkit-scrollbar {
  display: none;
}

body.authenticated:not(.mobile) #fonts-page .font-testers .font-tester span[contenteditable=true] {
  padding-left: calc(var(--spacing-1) - 3px);
  margin-left: 0;
}

body.authenticated:not(.mobile) #fonts-page .font-testers .font-tester button.copy {
  position: absolute;
  top: 9px;
  right: 8px;
  width: var(--height-row);
  height: var(--height-row);
  background: transparent;
  z-index: var(--layer-2);
  display: block;
  visibility: hidden;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border-radius: var(--borderRadius-small);
  overflow: hidden;
  background-color: var(--ui-load-spinner-large-background-color);
}

body.authenticated:not(.mobile) #fonts-page .font-testers .font-tester button.copy svg {
  width: 100%;
  height: auto;
}

body.authenticated:not(.mobile) #fonts-page .font-testers .font-tester button.copy svg path {
  fill: #fff;
}

body.authenticated:not(.mobile) #fonts-page .font-testers .font-tester button.copy svg rect {
  display: none;
}

body.authenticated:not(.mobile) #fonts-page .font-testers .font-tester:hover button.copy {
  visibility: visible;
}

body.dark #fonts-page {
  --color-solid-60: #A1A1A1;
  --color-solid-85: #DCDCDC;
}

body.dark #fonts-page .font-size-slider .axis-slider input[type=range]::-webkit-slider-thumb {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='15' fill='%23FFFFFFD9'/%3E%3C/svg%3E%0A");
}

body.dark #fonts-page .font-size-slider .axis-slider input[type=range]::-moz-range-thumb {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='15' fill='%23FFFFFFD9'/%3E%3C/svg%3E%0A");
}

/* Base Color and Background Mixins */
#uiWindow-layer .window-loader {
  position: fixed;
  inset: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: calc(var(--layer-9) + 1);
}

#uiWindow-layer .window-loader .loader > div {
  width: 75px;
  height: 75px;
}

#uiWindow-layer.fix-scroll {
  position: fixed;
  inset: 0;
  z-index: 9999;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#uiWindow-layer.fix-scroll::-webkit-scrollbar {
  display: none;
}

.uiWindow {
  pointer-events: auto;
  --contentFrameWindowMargin: 15;
  --topMenuBar-height: calc(var(--height-row-numeric) + 1);
  --contentFrameMarginCombined: calc(var(--contentFrameWindowMargin) * 2);
  --fullHeightWindowVerticalMargin: calc( var(--topMenuBar-height) + var(--contentFrameMarginCombined) );
  --fullHeightWindow: calc( 100vh - calc( calc( var(--height-row-numeric) + var(--contentFrameMarginCombined) ) * 1px ) );
  --fixed-pane-width: 382;
  border-radius: var(--windowRadiusSmall);
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  position: absolute;
  top: 0;
  right: 0;
  width: var(--ui-width-uiWindow);
  max-width: var(--ui-maxWidth-mobile);
  overflow: hidden;
  visibility: hidden;
}

.uiWindow .uiWindow-content {
  border-radius: var(--windowRadiusSmall);
}

.uiWindow .uiWindow-content .uiWindow-inner {
  border-radius: var(--windowRadiusSmall-inner);
}

.uiWindow::-webkit-scrollbar {
  display: none;
}

.uiWindow.hoisted {
  z-index: calc(var(--layer-9) + 10) !important;
}

.uiWindow[invokee-state=receed] {
  opacity: 0.4;
}

.uiWindow[invokee-state=receed]:after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: calc(var(--layer-3) + 99);
}

.uiWindow .section-header {
  margin-bottom: 12px;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.6);
}

.uiWindow .section-header span {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}

.uiWindow .ui-group + .section-header {
  margin-top: -10px;
}

.uiWindow[prevent-events=true] .uiWindow-inner {
  pointer-events: none;
}

.uiWindow[droppable=true]:after {
  position: absolute;
  inset: 0;
  content: "";
  pointer-events: none;
  border: 2px solid var(--ui-color-accent);
  border-radius: inherit;
}

.uiWindow[type=popover] {
  box-shadow: var(--window-boxShadow-default);
  z-index: calc(var(--layer-3) + 20);
  height: auto;
}

.uiWindow[type=popover][full-height=true] {
  min-height: var(--fullHeightWindow);
}

.uiWindow[type=popover][full-height=true] .uiWindow-content {
  max-height: var(--fullHeightWindow) !important;
}

.uiWindow[type=popover][full-height=true] .uiWindow-content .uiWindow-inner {
  min-height: calc(var(--fullHeightWindow) - 2px);
}

.uiWindow[type=popover][tabbed=true] {
  cursor: default;
}

.uiWindow[type=popover][tabbed=true] window-tabs {
  display: flex;
  flex-direction: row;
  height: var(--height-row);
  min-height: var(--height-row);
}

.uiWindow[type=popover][tabbed=true] window-tabs window-tab {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--ui-padding-default);
  color: var(--ui-color-default);
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.uiWindow[type=popover][tabbed=true] window-tabs window-tab[chosen=false] {
  background: rgba(var(--baseColor-default-reverse-rgb), 0.07);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  cursor: pointer;
}

.uiWindow[type=popover][tabbed=true] window-tabs window-tab[chosen=false]:active {
  background: rgba(var(--baseColor-default-reverse-rgb), 0.1);
}

.uiWindow[type=popover][tabbed=true] window-tabs window-tab[chosen=true] {
  background: var(--ui-background-container);
}

.uiWindow[type=popover][tabbed=true] window-tabs window-tab::after {
  content: " ";
  position: absolute;
  top: 17px;
  right: 16px;
  height: 8px;
  width: 8px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0);
}

.uiWindow[type=popover][tabbed=true] window-tabs window-tab.changes::after {
  background: rgba(0, 0, 0, 0.85);
}

.uiWindow[type=popover][tabbed=true] window-content {
  padding: var(--ui-padding-uiWindow);
}

.uiWindow[type=popover][tabbed=true] .uiWindow-content {
  min-height: 180px;
}

.uiWindow[type=popover][tabbed=true] .uiWindow-content .uiWindow-inner {
  display: flex;
  flex-direction: column;
  padding: 0;
  min-height: 180px;
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.uiWindow[type=popover][tabbed=true] .uiWindow-content .uiWindow-inner::-webkit-scrollbar {
  display: none;
}

.uiWindow[type=popover] .uiWindow-content {
  padding: 1px;
  background: var(--ui-window-border-background);
  max-height: calc(100vh - var(--height-row) * 2);
}

.uiWindow[type=popover] .uiWindow-content .uiWindow-inner {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow: scroll;
  padding: var(--ui-padding-uiWindow);
  background-color: var(--ui-background-container);
  max-height: calc(100vh - var(--height-row) * 2 - 2px);
}

.uiWindow[type=popover] .uiWindow-content .uiWindow-inner::-webkit-scrollbar {
  display: none;
}

.uiWindow[type=popover] .uiWindow-content .window-title {
  display: flex;
  justify-content: space-between;
  color: rgba(var(--baseColor-default-reverse-rgb), 1);
  font-size: var(--uc-fontSize-default);
  line-height: var(--lineHeight-default);
  text-align: left;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.uiWindow[type=popover] .uiWindow-content .window-title .action {
  color: var(--ui-color-accent);
  text-align: right;
}

.uiWindow[type=popover] .uiWindow-content .window-title .action span {
  cursor: pointer;
  font-variant: small-caps;
}

.uiWindow[type=popover] .uiWindow-content .window-title .action span:active {
  opacity: var(--opacity-downstate-default);
}

.uiWindow[type=fixed-pane] {
  border-radius: 0;
  position: fixed;
  z-index: 800;
  height: 100vh;
  width: calc((var(--fixed-pane-width) + 1) * 1px);
  border-left: 1px solid rgba(0, 0, 0, 0.17);
}

.uiWindow[type=fixed-pane] .uiWindow-content {
  border-radius: 0;
}

.uiWindow[type=fixed-pane] .uiWindow-content .uiWindow-inner {
  border-radius: 0;
}

.uiWindow[type=fixed-pane][position=fixed-right] {
  top: 0;
  right: 0;
  bottom: 0;
}

.uiWindow[type=fixed-pane][position=fixed-right]:before {
  content: "";
  top: -50vh;
  right: 0;
  width: calc(var(--fixed-pane-width) * 1px - 1px);
  background: #fff;
  position: fixed;
  height: 200vh;
  z-index: -1;
  pointer-events: none;
  box-shadow: var(--window-boxShadow-default);
}

.uiWindow[type=fixed-pane][position=fixed-right] div.close {
  position: relative;
  z-index: 3;
}

.uiWindow[type=fixed-pane][position=fixed-right] div.close button {
  position: absolute;
  top: var(--ui-padding-uiWindow);
  right: var(--ui-padding-uiWindow);
}

.uiWindow[type=fixed-pane] .uiWindow-content {
  padding: 1px;
  background: var(--ui-background-container);
  min-height: 100%;
}

.uiWindow[type=fixed-pane] .uiWindow-content .uiWindow-inner {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  height: 100vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
}

.uiWindow[type=fixed-pane] .uiWindow-content .uiWindow-inner::-webkit-scrollbar {
  display: none;
}

.uiWindow[type=fixed-pane] .uiWindow-content .uiWindow-inner window-content {
  padding: var(--ui-padding-uiWindow);
}

.uiWindow[type=pane] {
  box-shadow: var(--window-boxShadow-default);
  z-index: calc(var(--layer-3) + 75);
  height: auto;
  width: calc(40vw - var(--height-row));
}

.uiWindow[type=pane] .uiWindow-content {
  padding: 1px;
  background: var(--ui-window-border-background);
}

.uiWindow[type=pane] .uiWindow-content .uiWindow-inner {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  height: 100vh;
  overflow: scroll;
  padding: var(--ui-padding-uiWindow);
  background-color: var(--ui-background-default);
  display: flex;
  flex-direction: column;
  max-height: var(--fullHeightWindow);
}

.uiWindow[type=pane] .uiWindow-content .uiWindow-inner::-webkit-scrollbar {
  display: none;
}

.uiWindow[type=pane] window-content {
  flex: 1;
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.uiWindow[type=pane] window-content::-webkit-scrollbar {
  display: none;
}

.uiWindow[type=pane] window-footer .footer {
  z-index: 4;
}

.uiWindow[type=code-popover] {
  border-radius: var(--windowRadiusLarge);
  box-shadow: var(--window-boxShadow-default);
  z-index: calc(var(--layer-3) + 75);
  height: auto;
  width: 420px;
  --codePopoverMaxHeight: 280px;
}

.uiWindow[type=code-popover] .uiWindow-content {
  border-radius: var(--windowRadiusLarge);
}

.uiWindow[type=code-popover] .uiWindow-content .uiWindow-inner {
  border-radius: var(--windowRadiusLarge-inner);
}

.uiWindow[type=code-popover] .close {
  position: absolute;
  top: var(--ui-padding-uiWindow);
  right: var(--ui-padding-uiWindow);
  z-index: 710;
}

.uiWindow[type=code-popover] .uiWindow-content {
  padding: 1px;
  background: var(--ui-window-border-background);
}

.uiWindow[type=code-popover] .uiWindow-content .uiWindow-inner {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 0;
  background-color: var(--ui-background-container);
  height: calc(var(--codePopoverMaxHeight) - 2px);
  min-height: calc(var(--codePopoverMaxHeight) - 2px);
  position: relative;
  background: #fff;
}

.uiWindow[type=code-popover] .uiWindow-content .uiWindow-inner::-webkit-scrollbar {
  display: none;
}

.uiWindow[type=code-popover] .uiWindow-content .uiWindow-inner window-content {
  flex: 1 1;
  overflow: hidden;
}

.uiWindow[type=code-popover] .uiWindow-content .window-title {
  display: flex;
  justify-content: space-between;
  color: rgba(var(--baseColor-default-reverse-rgb), 1);
  font-size: var(--uc-fontSize-default);
  line-height: var(--lineHeight-default);
  text-align: left;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.uiWindow[type=code-popover] .uiWindow-content .window-title .action {
  color: var(--ui-color-accent);
  text-align: right;
}

.uiWindow[type=code-popover] .uiWindow-content .window-title .action span {
  cursor: pointer;
  font-variant: small-caps;
}

.uiWindow[type=code-popover] .uiWindow-content .window-title .action span:active {
  opacity: var(--opacity-downstate-default);
}

.uiWindow[type=code-pane] {
  box-shadow: var(--window-boxShadow-default);
  height: auto;
  width: calc(40vw - var(--height-row));
  min-width: 460px;
  border-radius: var(--windowRadiusLarge);
}

.uiWindow[type=code-pane] .uiWindow-content {
  border-radius: var(--windowRadiusLarge);
}

.uiWindow[type=code-pane] .uiWindow-content .uiWindow-inner {
  border-radius: var(--windowRadiusLarge-inner);
}

.uiWindow[type=code-pane] .close {
  position: absolute;
  top: var(--ui-padding-uiWindow);
  right: var(--ui-padding-uiWindow);
}

.uiWindow[type=code-pane] .uiWindow-content {
  padding: 1px;
  background: var(--ui-window-border-background);
}

.uiWindow[type=code-pane] .uiWindow-content .uiWindow-inner {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  height: 100vh;
  overflow: scroll;
  padding: 0;
  background-color: var(--ui-background-default);
  display: flex;
  flex-direction: column;
  max-height: calc(var(--fullHeightWindow) - 2px);
}

.uiWindow[type=code-pane] .uiWindow-content .uiWindow-inner::-webkit-scrollbar {
  display: none;
}

.uiWindow[type=code-pane] window-content {
  flex: 1;
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow: hidden;
}

.uiWindow[type=code-pane] window-content::-webkit-scrollbar {
  display: none;
}

.uiWindow[type=code-pane] window-footer .footer {
  z-index: 4;
}

.uiWindow[type=focus-modal] {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 15px;
  right: 0;
  height: auto;
  width: auto;
  z-index: var(--layer-8);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  border-radius: var(--windowRadiusSmall);
}

.uiWindow[type=focus-modal] .uiWindow-content {
  border-radius: var(--windowRadiusSmall);
}

.uiWindow[type=focus-modal] .uiWindow-content .uiWindow-inner {
  border-radius: var(--windowRadiusSmall-inner);
}

.uiWindow[type=focus-modal] .uiWindow-content {
  padding: 1px;
  box-shadow: var(--window-boxShadow-default);
  background: var(--ui-window-border-background);
  --maxHeight: auto;
  max-height: var(--maxHeight);
  height: var(--maxHeight);
}

.uiWindow[type=focus-modal] .uiWindow-content .uiWindow-inner {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 372px;
  background-color: var(--ui-background-container);
  max-height: var(--maxHeight);
  height: var(--maxHeight);
  position: relative;
}

.uiWindow[type=focus-modal] .uiWindow-content .uiWindow-inner::-webkit-scrollbar {
  display: none;
}

.uiWindow[type=focus-modal] .uiWindow-content .uiWindow-inner .close {
  position: absolute;
  top: var(--ui-padding-uiWindow);
  right: var(--ui-padding-uiWindow);
  z-index: calc(var(--layer-7) + 1);
}

.uiWindow[type=focus-modal] .uiWindow-content .uiWindow-inner window-content {
  padding: var(--ui-padding-uiWindow);
}

.uiWindow[type=typography-modal] {
  z-index: var(--layer-8);
  border-radius: var(--windowRadiusLarge);
}

.uiWindow[type=typography-modal] .uiWindow-content {
  border-radius: var(--windowRadiusLarge);
}

.uiWindow[type=typography-modal] .uiWindow-content .uiWindow-inner {
  border-radius: var(--windowRadiusLarge-inner);
}

.uiWindow window-header {
  margin: var(--ui-padding-uiWindow);
  margin-bottom: 0;
  position: relative;
}

.uiWindow window-header .icon {
  color: rgb(0, 0, 0);
}

.uiWindow window-header .notice {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.6);
}

.uiWindow window-label {
  letter-spacing: 0;
  cursor: default;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.uiWindow window-label[label-size=large], .uiWindow window-label[label-size=large] a {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  font-size: var(--fontSize-header);
  line-height: 1;
  display: inline-block;
}

.uiWindow window-label[label-size=large] a {
  cursor: pointer;
}

.uiWindow window-label[label-size=large] a:hover {
  text-decoration: underline;
  text-decoration-color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
}

.uiWindow window-label[label-size=large] a:active {
  opacity: var(--opacity-downstate-default);
  color: inherit;
}

.uiWindow .hint {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.75);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.75);
  background: #B7C9FF;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08), 4px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 9px 13px 10px;
  border-radius: 7px;
  line-height: 1.3;
  position: relative;
}

.uiWindow .hint .window-link {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.75);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.75);
  text-decoration: underline;
  display: inline-block;
  text-decoration-color: rgba(var(--baseColor-default-reverse-rgb), 0.75);
}

.uiWindow .hint .window-link:active {
  opacity: 0.7;
}

.uiWindow .hint .hint-seen {
  position: absolute;
  top: 12px;
  right: 7px;
  font-size: 1.75em;
  line-height: 0;
  font-weight: 200;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.uiWindow .hint em {
  font-style: normal;
  color: #000 !important;
  opacity: 1 !important;
  font-size: 20px;
  line-height: 0;
}

.uiWindow[position=fixed-right] {
  position: fixed;
  top: calc((var(--height-row-numeric) + var(--contentFrameWindowMargin)) * 1px);
  right: calc((var(--height-row-numeric) + var(--contentFrameWindowMargin)) * 1px);
}

.uiWindow[position=fixed-left] {
  position: fixed;
  top: calc((var(--height-row-numeric) + var(--contentFrameWindowMargin)) * 1px);
  left: calc(var(--contentFrameWindowMargin) * 1px);
}

.uiWindow[position=center] {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.uiWindow .uiWindow-spacer {
  height: var(--ui-padding-uiWindow);
}

.uiWindow .uiWindow-spacer.tall {
  height: calc(var(--ui-padding-uiWindow) * 2);
}

.uiWindow span.window-text {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.25);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.25);
  margin: 5px 0 0 0;
  display: block;
}

.uiWindow .loader {
  opacity: 0.6;
}

.clickout-layer {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: default;
  z-index: var(--layer-9);
}

.clickout-layer.dim {
  background: rgba(0, 0, 0, 0.5);
}

/* Base Color and Background Mixins */
.more-button:active {
  opacity: 0.5;
}

.more-button.ui-background {
  background-color: var(--ui-background-container);
}

.more-button.ui-background svg > * {
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
}

.more-button svg {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
  user-select: none;
}

/* Base Color and Background Mixins */
.more-actions {
  display: flex;
  grid-gap: 5px;
  justify-content: flex-end;
  color: var(--ui-color-accent);
  padding: 2px 0;
}

.more-actions.space-between {
  justify-content: space-between;
}

.more-actions.left {
  justify-content: flex-start;
  text-align: left;
}

.more-actions.grey {
  color: var(--color-default-secondary);
}

.more-actions.window-bottom {
  line-height: 1;
  padding-bottom: 0;
  margin-bottom: -2px;
}

.more-actions div,
.more-actions span,
.more-actions button {
  font-size: 16px;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}

.more-actions div.text-button,
.more-actions span.text-button,
.more-actions button.text-button {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  margin-top: 8px;
  margin-right: 6px;
  line-height: 1;
}

.more-actions div.text-button.code,
.more-actions span.text-button.code,
.more-actions button.text-button.code {
  margin-top: 6px;
  margin-right: 0;
}

.more-actions div[type=submit],
.more-actions span[type=submit],
.more-actions button[type=submit] {
  color: rgba(var(--baseColor-accent-reverse-rgb), 1);
  fill: rgba(var(--baseColor-accent-reverse-rgb), 1);
  background: rgba(var(--baseColor-accent-rgb), 1);
  display: inline-block;
  font-weight: 500;
  padding: 6.5px var(--ui-padding-default-horizontal) 7.5px var(--ui-padding-default-horizontal);
  margin-top: 10px;
  height: auto;
  flex: 0;
  min-width: 100px;
  line-height: 1;
  display: inline-block;
  text-align: left;
  height: 28px !important;
  font-size: 14px;
}

.more-actions div[type=submit].auto-width,
.more-actions span[type=submit].auto-width,
.more-actions button[type=submit].auto-width {
  min-width: auto;
}

.more-actions div[type=cancel],
.more-actions span[type=cancel],
.more-actions button[type=cancel] {
  background: rgba(var(--baseColor-default-reverse-rgb), 0.1);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  display: inline-block;
  font-weight: 500;
  padding: 6.5px var(--ui-padding-default-horizontal) 7.5px var(--ui-padding-default-horizontal);
  margin-top: 10px;
  height: auto;
  flex: 0;
  min-width: 100px;
  line-height: 1;
  display: inline-block;
  text-align: left;
  height: 28px !important;
  font-size: 14px;
}

.more-actions div:active,
.more-actions span:active,
.more-actions button:active {
  opacity: var(--opacity-downstate-default);
}

.more-actions .pid-field {
  display: inline-flex;
  align-items: flex-start;
}

.more-actions .pid-field div {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  margin-top: 6px;
  line-height: 1;
  margin-left: auto;
}

.more-actions .pid-field input {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  margin-top: 2px;
  line-height: 1;
  pointer-events: none;
  background: transparent;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
}

.more-actions .pid-field input::selection {
  background: transparent;
}

.more-actions div[type=submit],
.more-actions span[type=submit],
.more-actions button[type=submit] {
  font-size: var(--fontSize-default);
}

/* Base Color and Background Mixins */
.top-menu-bar {
  --menu-buttonWidth: 200px;
  height: calc(var(--height-row) + 0);
  width: 100vw;
  background: var(--baseColor-border);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  position: relative;
  z-index: var(--layer-3);
}

.top-menu-bar::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: var(--element-gap);
  background: var(--ui-border-bars);
  bottom: -1px;
}

.top-menu-bar .area {
  display: grid;
  grid-auto-columns: auto;
  grid-gap: var(--element-gap);
  grid-auto-flow: column;
  flex: 0 1 auto;
}

.top-menu-bar.dynamic {
  flex: 1;
}

.top-menu-bar button,
.top-menu-bar .button-link,
.top-menu-bar button.duplicate,
.top-menu-bar button.copy,
.top-menu-bar .button-link.copy,
.top-menu-bar .button-link.duplicate {
  height: var(--height-row);
  position: relative;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.75);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.75);
  background-color: var(--background-2);
}

.top-menu-bar button.min-width,
.top-menu-bar .button-link.min-width,
.top-menu-bar button.duplicate.min-width,
.top-menu-bar button.copy.min-width,
.top-menu-bar .button-link.copy.min-width,
.top-menu-bar .button-link.duplicate.min-width {
  min-width: var(--menu-buttonWidth);
}

.top-menu-bar .background-fill {
  background: var(--background-2);
  flex: 1;
  height: var(--height-row);
  flex-grow: 1;
}

.top-menu-bar .account-area {
  width: calc(var(--height-row) * 2);
  height: var(--height-row);
  background: var(--background-2);
}

.top-menu-bar .home-area {
  background: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2px;
  display: none;
}

.top-menu-bar .home-area button.cargo-home {
  height: var(--height-row);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
}

.top-menu-bar .home-area button.cargo-home span.index-link {
  font-family: var(--fontFamily-mono);
  font-size: var(--fontSize-small);
  margin-left: -0.25em;
  line-height: 0;
}

.top-menu-bar .home-area button.cargo-home span.index-link svg {
  margin-right: 0.25em;
}

.top-menu-bar .home-area button.cargo-home span.index-link svg * {
  fill: rgba(0, 0, 0, 0.6);
}

.top-menu-bar .home-area button.cargo-home .circle {
  color: #fff;
  font-family: var(--fontFamily-mono);
  content: "";
  background: rgba(0, 0, 0, 0.85);
  padding: 0.55em 0.5em 0.5em;
  border-radius: 100rem;
  width: 24px;
  height: 24px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.top-menu-bar .home-area button.cargo-home .circle span {
  pointer-events: none;
  transform: translate(0.01em 0);
}

.top-menu-bar:has(#home-menu)::after {
  display: none;
}

.top-menu-bar:has(#home-menu) button,
.top-menu-bar:has(#home-menu) .button-link,
.top-menu-bar:has(#home-menu) .button-link.selected {
  background: transparent;
}

.top-menu-bar:has(#home-menu) .button-link {
  line-height: 1;
  align-items: center;
}

#admin .top-menu-bar::after {
  right: calc(var(--height-row) + var(--element-gap));
  width: calc(100vw - (var(--height-row) + var(--element-gap)));
}

body[data-route=community] .top-menu-bar#main-menu, body[data-route=home].mobile .top-menu-bar#main-menu {
  mix-blend-mode: difference;
  background: transparent;
}

body[data-route=community] .top-menu-bar#main-menu #home-menu button,
body[data-route=community] .top-menu-bar#main-menu #home-menu .button-link, body[data-route=home].mobile .top-menu-bar#main-menu #home-menu button,
body[data-route=home].mobile .top-menu-bar#main-menu #home-menu .button-link {
  color: #D9D9D9;
}

body[data-route=community] .top-menu-bar#main-menu #home-menu button.selected > span, body[data-route=community] .top-menu-bar#main-menu #home-menu button:hover > span,
body[data-route=community] .top-menu-bar#main-menu #home-menu .button-link.selected > span,
body[data-route=community] .top-menu-bar#main-menu #home-menu .button-link:hover > span, body[data-route=home].mobile .top-menu-bar#main-menu #home-menu button.selected > span, body[data-route=home].mobile .top-menu-bar#main-menu #home-menu button:hover > span,
body[data-route=home].mobile .top-menu-bar#main-menu #home-menu .button-link.selected > span,
body[data-route=home].mobile .top-menu-bar#main-menu #home-menu .button-link:hover > span {
  color: rgba(255, 255, 255, 0.45);
}

body[data-route=community] .top-menu-bar#main-menu #home-menu button span,
body[data-route=community] .top-menu-bar#main-menu #home-menu .button-link span, body[data-route=home].mobile .top-menu-bar#main-menu #home-menu button span,
body[data-route=home].mobile .top-menu-bar#main-menu #home-menu .button-link span {
  -webkit-mix-blend-mode: difference;
  mix-blend-mode: difference;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 999;
  background: transparent;
}

body[data-route=community] .top-menu-bar#main-menu #home-menu .return-area .button-link svg *, body[data-route=home].mobile .top-menu-bar#main-menu #home-menu .return-area .button-link svg * {
  fill: rgba(255, 255, 255, 0.6);
}

body[data-route=community] .top-menu-bar#main-menu #home-menu .return-area .button-link svg *.arrow-back, body[data-route=home].mobile .top-menu-bar#main-menu #home-menu .return-area .button-link svg *.arrow-back {
  fill: rgba(255, 255, 255, 0.3);
}

body[cargo-writing] .top-menu-bar#main-menu {
  background: var(--background-3);
}

body[cargo-writing] .top-menu-bar#main-menu #home-menu::after {
  content: "";
  position: absolute;
  right: var(--spacing-1);
  left: var(--spacing-1);
  height: 1px;
  background: rgba(var(--baseColor-default-reverse-rgb), 0.15);
  z-index: 0;
  bottom: -1px;
}

body[cargo-writing] .top-menu-bar#main-menu, body[cargo-information] .top-menu-bar#main-menu {
  background: var(--background-3);
}

body[cargo-writing] .top-menu-bar#main-menu::after, body[cargo-information] .top-menu-bar#main-menu::after {
  display: none;
}

body[cargo-writing].mobile .top-menu-bar#main-menu, body[cargo-information].mobile .top-menu-bar#main-menu {
  background: var(--background-1);
}

body[cargo-writing].mobile .top-menu-bar#main-menu::after, body[cargo-information].mobile .top-menu-bar#main-menu::after {
  display: block;
}

body[cargo-writing].mobile .top-menu-bar#main-menu #home-menu::after, body[cargo-information].mobile .top-menu-bar#main-menu #home-menu::after {
  display: none;
}

body.authenticated[cargo-writing] .top-menu-bar#main-menu, body.authenticated[cargo-information] .top-menu-bar#main-menu, body.authenticated.community .top-menu-bar#main-menu {
  --grid-template-columns: repeat(4, 1fr) repeat(4, 1fr);
}

body.authenticated[cargo-writing] .top-menu-bar#main-menu #home-menu .left-area, body.authenticated[cargo-information] .top-menu-bar#main-menu #home-menu .left-area, body.authenticated.community .top-menu-bar#main-menu #home-menu .left-area {
  margin-left: 0;
}

body.authenticated[cargo-writing] .top-menu-bar#main-menu #home-menu .right-area, body.authenticated[cargo-information] .top-menu-bar#main-menu #home-menu .right-area, body.authenticated.community .top-menu-bar#main-menu #home-menu .right-area {
  margin-right: 0;
}

body.authenticated[cargo-writing] .top-menu-bar#main-menu #home-menu #login-menu, body.authenticated[cargo-information] .top-menu-bar#main-menu #home-menu #login-menu, body.authenticated.community .top-menu-bar#main-menu #home-menu #login-menu {
  right: 0;
}

body.authenticated[cargo-writing] .top-menu-bar#main-menu button,
body.authenticated[cargo-writing] .top-menu-bar#main-menu .button-link, body.authenticated[cargo-information] .top-menu-bar#main-menu button,
body.authenticated[cargo-information] .top-menu-bar#main-menu .button-link, body.authenticated.community .top-menu-bar#main-menu button,
body.authenticated.community .top-menu-bar#main-menu .button-link {
  background: transparent;
}

body.authenticated[cargo-writing].mobile .top-menu-bar#main-menu, body.authenticated[cargo-information].mobile .top-menu-bar#main-menu, body.authenticated.community.mobile .top-menu-bar#main-menu {
  --grid-template-columns: repeat(4, 1fr);
}

body.authenticated[cargo-writing].mobile .top-menu-bar#main-menu #home-menu, body.authenticated[cargo-information].mobile .top-menu-bar#main-menu #home-menu, body.authenticated.community.mobile .top-menu-bar#main-menu #home-menu {
  --grid-template-columns: 1fr;
}

body.authenticated[cargo-writing].mobile .top-menu-bar#main-menu #home-menu .return-area, body.authenticated[cargo-information].mobile .top-menu-bar#main-menu #home-menu .return-area, body.authenticated.community.mobile .top-menu-bar#main-menu #home-menu .return-area {
  margin-left: 0;
}

body.authenticated[cargo-writing].mobile .top-menu-bar#main-menu #home-menu .right-area, body.authenticated[cargo-information].mobile .top-menu-bar#main-menu #home-menu .right-area, body.authenticated.community.mobile .top-menu-bar#main-menu #home-menu .right-area {
  grid-column: 2/span 1;
}

body #home-menu {
  --link-spacing: calc(var(--spacing-1) * 5 / 6);
  height: var(--height-row);
  grid-column: 1/-1;
  background: transparent;
  display: grid;
  grid-template-columns: var(--grid-template-columns);
  grid-column-gap: var(--grid-column-gap);
}

body #home-menu button,
body #home-menu .button-link {
  font-family: var(--fontFamily-CargoDiatype);
  padding: var(--ui-padding-default-vertical) 0;
  pointer-events: none;
}

body #home-menu button > *,
body #home-menu .button-link > * {
  pointer-events: auto;
}

body #home-menu button + button, body #home-menu button + .button-link,
body #home-menu .button-link + button,
body #home-menu .button-link + .button-link {
  margin-left: var(--link-spacing);
}

body #home-menu button.selected > span, body #home-menu button:hover > span,
body #home-menu .button-link.selected > span,
body #home-menu .button-link:hover > span {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.45);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.45);
}

body #home-menu button.home-link,
body #home-menu .button-link.home-link {
  height: var(--height-row);
  grid-column: 1/span 1;
  margin-left: calc(var(--spacing-1) * -1);
  padding: 8.5px;
}

body #home-menu button.home-link img,
body #home-menu .button-link.home-link img {
  height: 100%;
  width: auto;
}

body #home-menu .left-area {
  grid-column: 1/span 1;
  justify-content: flex-start;
  display: flex;
}

body #home-menu .right-area {
  grid-column: var(--grid-line-golden)/-1;
  pointer-events: none;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-width: fit-content;
}

body #home-menu .right-area > * {
  pointer-events: auto;
}

body #home-menu .right-area > span {
  display: flex;
}

body #home-menu .return-area {
  margin-left: calc(var(--spacing-1) * -1);
  display: inline-block;
}

body #home-menu .return-area .button-link {
  display: inline-block;
  background: transparent;
  align-content: center;
  padding: 10px 8px !important;
}

body #home-menu .return-area .button-link:active {
  opacity: var(--opacity-downstate-default);
}

body #home-menu .return-area .button-link svg * {
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.6);
}

body #home-menu .return-area .button-link svg *.arrow-back {
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.3);
}

body #home-menu .intro {
  cursor: default;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

body #home-menu .intro:active {
  opacity: 1;
}

body #home-menu #login-menu {
  height: var(--height-row);
  margin-left: var(--element-gap);
  height: var(--height-row);
  display: flex;
  justify-content: flex-end;
  flex: 1 0 auto;
}

body #home-menu #login-menu button,
body #home-menu #login-menu .button-link {
  font-family: var(--fontFamily-CargoDiatype);
  line-height: 1;
  padding: var(--ui-padding-default-vertical) 0;
  align-items: center;
  pointer-events: none;
}

body #home-menu #login-menu button > *,
body #home-menu #login-menu .button-link > * {
  pointer-events: auto;
}

body #home-menu #login-menu button.start,
body #home-menu #login-menu .button-link.start {
  margin-left: var(--link-spacing);
}

body #home-menu #login-menu button.login svg,
body #home-menu #login-menu button.login svg *,
body #home-menu #login-menu .button-link.login svg,
body #home-menu #login-menu .button-link.login svg * {
  fill: var(--ui-color-flat-reverse);
}

body.mobile #home-menu {
  --link-spacing: var(--spacing-exp-2);
  --optical-correction: 1.0px;
}

body.mobile #home-menu .right-area {
  margin-top: var(--optical-correction);
  grid-column: 1/span 1;
  display: flex;
  justify-content: flex-end;
}

body.mobile #home-menu #login-menu {
  display: inline-flex;
  position: absolute;
  top: 0;
  right: var(--spacing-1);
  margin-top: var(--optical-correction);
}

body.mobile #home-menu #login-menu .button-link.login {
  justify-content: flex-end;
  margin-left: var(--spacing-1);
}

body.community #home-menu .return-area .button-link:active {
  opacity: var(--opacity-downstate-default);
}

body.community #home-menu .return-area .button-link svg * {
  fill: rgba(255, 255, 255, 0.6);
}

body.community #home-menu .return-area .button-link svg *.arrow-back {
  fill: rgba(255, 255, 255, 0.3);
}

body.authenticated .top-menu-bar {
  background: var(--baseColor-border);
  --grid-template-columns: var(--gtc-authenticatedPage);
}

body.authenticated .top-menu-bar .left-area {
  grid-column: 1/span 1;
  display: flex;
}

body.authenticated .top-menu-bar .right-area {
  grid-column: 2/-1;
  display: flex;
  margin-right: calc(var(--spacing-1) * -1);
}

body.authenticated .top-menu-bar .right-area .templates-add {
  padding: 0;
  z-index: 1;
  background: var(--background-2);
}

body.authenticated .top-menu-bar .right-area .templates-add .element-gap {
  border-right: 1px solid var(--baseColor-border);
}

body.authenticated .top-menu-bar .right-area .templates-add .button-link {
  background-color: var(--background-3);
  padding-left: var(--spacing-1);
}

body.authenticated .top-menu-bar .right-area .templates-add .button-link:active {
  opacity: var(--opacity-downstate-default);
}

body.authenticated .top-menu-bar .right-area .templates-title {
  margin-right: calc(-1 * var(--element-gap));
  pointer-events: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

body.authenticated .top-menu-bar .fill-between {
  background-color: var(--background-2);
  flex: 1;
  height: var(--height-row);
  flex-grow: 1;
}

body.authenticated .top-menu-bar .fill-between.before {
  grid-column: 2/var(--grid-line-golden);
}

body.authenticated .top-menu-bar .fill-between.after {
  grid-column: 7/-1;
}

body.authenticated .top-menu-bar button, body.authenticated .top-menu-bar .button-link {
  padding: var(--ui-padding-default-vertical) var(--spacing-1);
  align-items: center;
  line-height: 1;
}

body.authenticated .top-menu-bar button.jobs-link.square, body.authenticated .top-menu-bar .button-link.jobs-link.square {
  justify-content: center;
  font-size: 19px;
  padding-top: 3px;
}

body.authenticated .top-menu-bar button.fonts-link.square, body.authenticated .top-menu-bar button.jobs-link.square, body.authenticated .top-menu-bar .button-link.fonts-link.square, body.authenticated .top-menu-bar .button-link.jobs-link.square {
  padding: 0;
}

body.authenticated .top-menu-bar button.fonts-link.square svg, body.authenticated .top-menu-bar button.jobs-link.square svg, body.authenticated .top-menu-bar .button-link.fonts-link.square svg, body.authenticated .top-menu-bar .button-link.jobs-link.square svg {
  pointer-events: none;
}

body.authenticated .top-menu-bar button.fonts-link.square svg *, body.authenticated .top-menu-bar button.jobs-link.square svg *, body.authenticated .top-menu-bar .button-link.fonts-link.square svg *, body.authenticated .top-menu-bar .button-link.jobs-link.square svg * {
  fill: var(--color-default);
}

body.authenticated .top-menu-bar button.fonts-link.square svg rect, body.authenticated .top-menu-bar button.jobs-link.square svg rect, body.authenticated .top-menu-bar .button-link.fonts-link.square svg rect, body.authenticated .top-menu-bar .button-link.jobs-link.square svg rect {
  display: none;
}

body.authenticated .top-menu-bar button.fonts-link.square.selected, body.authenticated .top-menu-bar button.jobs-link.square.selected, body.authenticated .top-menu-bar .button-link.fonts-link.square.selected, body.authenticated .top-menu-bar .button-link.jobs-link.square.selected {
  background: var(--background-2);
}

body.authenticated .top-menu-bar button.community.square, body.authenticated .top-menu-bar .button-link.community.square {
  padding: 7px;
}

body.authenticated .top-menu-bar button.community.square .🌐, body.authenticated .top-menu-bar .button-link.community.square .🌐 {
  pointer-events: none;
  height: auto;
  width: 100%;
}

body.authenticated .top-menu-bar button.jobs-link:active, body.authenticated .top-menu-bar button.fonts-link:active, body.authenticated .top-menu-bar button.community:active, body.authenticated .top-menu-bar .button-link.jobs-link:active, body.authenticated .top-menu-bar .button-link.fonts-link:active, body.authenticated .top-menu-bar .button-link.community:active {
  opacity: 1;
  background: var(--background-3);
}

body.authenticated .top-menu-bar button.jobs-link:active > *, body.authenticated .top-menu-bar button.fonts-link:active > *, body.authenticated .top-menu-bar button.community:active > *, body.authenticated .top-menu-bar .button-link.jobs-link:active > *, body.authenticated .top-menu-bar .button-link.fonts-link:active > *, body.authenticated .top-menu-bar .button-link.community:active > * {
  opacity: var(--opacity-downstate-default);
}

body.authenticated.mobile[data-route=templates] .top-menu-bar::before, body.authenticated.mobile[data-route=folder] .top-menu-bar::before {
  content: "";
  position: absolute;
  width: var(--viewport-width);
  height: 0.5px;
  background-color: var(--baseColor-border);
  z-index: 1;
}

body.authenticated.mobile .top-menu-bar {
  display: flex;
  padding: 0;
  grid-template-columns: auto;
  grid-gap: var(--element-gap);
}

body.unauthenticated.community .top-menu-bar#main-menu, body.unauthenticated[data-route=templates] .top-menu-bar#main-menu, body.unauthenticated[data-route=fonts] .top-menu-bar#main-menu, body.unauthenticated[cargo-jobs] .top-menu-bar#main-menu {
  background: transparent;
}

body.unauthenticated.mobile[data-route=templates] .top-menu-bar::before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--height-row);
  width: var(--viewport-width);
  background: var(--background-2);
  box-shadow: 0 var(--element-gap) 0 rgba(var(--baseColor-default-reverse-rgb), 0.15);
}

.top-menu-bar {
  display: grid;
  grid-template-columns: var(--grid-template-columns);
  grid-column-gap: var(--grid-column-gap);
  position: fixed;
  top: 0;
  grid-gap: 0;
  padding: 0 var(--spacing-1);
  background: var(--baseColor-default);
  z-index: 302;
}

.top-menu-bar * {
  background: transparent;
}

.top-menu-bar button, .top-menu-bar .button-link {
  padding: var(--ui-padding-default-vertical) var(--spacing-1);
  align-items: center;
  font-family: var(--fontFamily-CargoDiatype);
  font-weight: var(--fontWeight-CargoDiatype);
  letter-spacing: 0;
  font-style: normal;
  line-height: 1;
}

/* Base Color and Background Mixins */
body.community #community-menu {
  --activeCategory-color: rgb(255, 255, 255, 0.85);
  mix-blend-mode: exclusion;
}

body.community #community-menu .button-link {
  color: rgba(255, 255, 255, 0.4);
}

body.community #community-menu .button-link:hover {
  color: rgb(255, 255, 255);
}

body.community #community-menu .button-link.selected {
  color: rgba(255, 255, 255, 0.85);
}

body .community-menu-text {
  display: block;
  color: transparent;
  text-wrap: nowrap;
  z-index: 302;
  pointer-events: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

body #community-menu,
body .community-menu-text {
  --lineHeight: 1.15;
  position: fixed;
  top: calc(var(--height-row) + var(--spacing-2) + var(--row-rule-offset));
  left: var(--spacing-1);
  line-height: var(--lineHeight);
}

body #community-menu .fountain,
body .community-menu-text .fountain {
  color: rgb(var(--baseColor-default-rgb), 1);
}

body #community-menu {
  --activeCategory-color: rgb(var(--baseColor-default-reverse-rgb), 0.85);
  color: rgba(var(--baseColor-default-rgb), 1);
  fill: rgba(var(--baseColor-default-rgb), 1);
  width: var(--grid-cell-width);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  z-index: 301;
}

body #community-menu .button-link {
  color: rgb(var(--baseColor-default-reverse-rgb), 0.4);
  font-family: var(--fontFamily-CargoDiatype);
  font-weight: var(--fontWeight-CargoDiatype);
  letter-spacing: 0;
  font-style: normal;
  width: fit-content;
}

body #community-menu .button-link:hover {
  color: rgb(var(--baseColor-default-reverse-rgb), 1);
}

body #community-menu .button-link.selected {
  color: rgb(var(--baseColor-default-reverse-rgb), 0.85);
}

body #community-menu .button-link .fountain-spacer {
  width: calc(var(--fontSize-default) / 16 * 19);
}

body #community-menu .category:has(.selected) .category-link {
  color: var(--activeCategory-color);
}

body #community-menu .category-items {
  margin-left: var(--spacing-1);
}

/* Animations */
@keyframes fadeInMenu {
  from {
    opacity: 0;
    transform: translateY(1px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
#community #community-menu,
#community .community-menu-text {
  opacity: 0;
  transform: translateY(1px);
}

#community.animate-in #community-menu,
#community.animate-in .community-menu-text {
  animation: fadeInMenu 0.35s ease-out forwards;
}

#community.animation-ended #community-menu,
#community.animation-ended .community-menu-text {
  opacity: 1;
  transform: translateY(0);
}

/* Base Color and Background Mixins */
#all-button,
#saved-button,
#folder-menu,
#folder-menu + .add-folder-area {
  --button-height: 30px;
  --selectedBackground: rgba(var(--baseColor-default-reverse-rgb), 0.05);
  --folder-color: #666;
  height: auto;
  width: auto;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

#all-button .drop-indicator,
#saved-button .drop-indicator,
#folder-menu .drop-indicator,
#folder-menu + .add-folder-area .drop-indicator {
  position: absolute;
  top: 0;
  left: 18px;
  right: 18px;
  height: 1px;
  z-index: 1;
  background: rgba(var(--baseColor-default-reverse-rgb), 0.2);
}

#all-button > div,
#saved-button > div,
#folder-menu > div,
#folder-menu + .add-folder-area > div {
  height: var(--button-height);
}

#all-button > div.dragged-original-folder,
#saved-button > div.dragged-original-folder,
#folder-menu > div.dragged-original-folder,
#folder-menu + .add-folder-area > div.dragged-original-folder {
  position: absolute;
}

#all-button > div .button-link,
#saved-button > div .button-link,
#folder-menu > div .button-link,
#folder-menu + .add-folder-area > div .button-link {
  color: var(--folder-color);
  padding: 0 10px;
  border-radius: var(--borderRadius-small);
  height: var(--button-height);
  align-items: center;
  line-height: 1;
  position: relative;
  user-select: none;
}

#all-button > div .button-link.drop-highlight,
#saved-button > div .button-link.drop-highlight,
#folder-menu > div .button-link.drop-highlight,
#folder-menu + .add-folder-area > div .button-link.drop-highlight {
  background: rgba(var(--baseColor-accent-rgb), 1);
  color: rgba(var(--baseColor-accent-reverse-rgb), 1);
  fill: rgba(var(--baseColor-accent-reverse-rgb), 1);
}

#all-button > div .button-link.drop-highlight .folder-icon svg *,
#saved-button > div .button-link.drop-highlight .folder-icon svg *,
#folder-menu > div .button-link.drop-highlight .folder-icon svg *,
#folder-menu + .add-folder-area > div .button-link.drop-highlight .folder-icon svg * {
  color: rgba(var(--baseColor-accent-reverse-rgb), 1);
  fill: rgba(var(--baseColor-accent-reverse-rgb), 1);
}

#all-button > div .button-link.selected,
#saved-button > div .button-link.selected,
#folder-menu > div .button-link.selected,
#folder-menu + .add-folder-area > div .button-link.selected {
  background: var(--selectedBackground);
}

#all-button > div .button-link.add-folder .folder-icon,
#saved-button > div .button-link.add-folder .folder-icon,
#folder-menu > div .button-link.add-folder .folder-icon,
#folder-menu + .add-folder-area > div .button-link.add-folder .folder-icon {
  margin-right: 0;
}

#all-button > div .button-link.add-folder .folder-icon svg,
#saved-button > div .button-link.add-folder .folder-icon svg,
#folder-menu > div .button-link.add-folder .folder-icon svg,
#folder-menu + .add-folder-area > div .button-link.add-folder .folder-icon svg {
  width: 21px;
  height: auto;
}

#all-button > div .button-link .folder-icon,
#saved-button > div .button-link .folder-icon,
#folder-menu > div .button-link .folder-icon,
#folder-menu + .add-folder-area > div .button-link .folder-icon {
  margin-right: 7.5px;
}

#all-button > div .button-link .folder-icon svg,
#saved-button > div .button-link .folder-icon svg,
#folder-menu > div .button-link .folder-icon svg,
#folder-menu + .add-folder-area > div .button-link .folder-icon svg {
  width: 17px;
  height: 14px;
  margin-top: 1px;
}

#all-button > div .button-link .folder-icon svg path,
#saved-button > div .button-link .folder-icon svg path,
#folder-menu > div .button-link .folder-icon svg path,
#folder-menu + .add-folder-area > div .button-link .folder-icon svg path {
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
}

#all-button > div .button-link .folder-title,
#saved-button > div .button-link .folder-title,
#folder-menu > div .button-link .folder-title,
#folder-menu + .add-folder-area > div .button-link .folder-title {
  font-family: var(--fontFamily-CargoDiatype);
  font-weight: var(--fontWeight-CargoDiatype);
  letter-spacing: 0;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: var(--button-height);
}

#saved-button > div .button-link .folder-icon {
  margin-left: -1px;
}

#saved-button > div .button-link .folder-icon svg {
  height: auto;
  width: 18px;
}

body.mobile .folder-area {
  flex: 1;
  max-width: calc(var(--viewport-width) - (var(--height-row) + var(--element-gap)) * 2);
}

body.mobile .folder-area.open .folder-menu-button .folder-icon,
body.mobile .folder-area.open .folder-menu-button .folder-title,
body.mobile .folder-area.open .folder-menu-button .caret-icon {
  opacity: 0.4;
}

body.mobile .folder-area.open .drop-down-menu-clickout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: -2;
  pointer-events: all;
  background: transparent;
}

body.mobile .folder-area .folder-icon {
  margin-right: 10px;
}

body.mobile .folder-area .folder-menu-button {
  width: 100%;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  background: var(--background-2);
}

body.mobile .folder-area .folder-menu-button .folder-icon.saved-icon svg {
  width: 22px;
}

body.mobile .folder-area .folder-menu-button .folder-icon.saved-icon svg path {
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
}

body.mobile .folder-area .folder-menu-button .folder-icon,
body.mobile .folder-area .folder-menu-button .folder-title {
  padding-top: 7.5px;
  padding-bottom: 6px;
}

body.mobile .folder-area .folder-menu-button .folder-title {
  text-overflow: ellipsis;
  overflow-x: hidden;
  line-height: var(--height-row);
}

body.mobile .folder-area .folder-menu-button .caret-icon {
  height: 40px;
  margin-right: 3.5px;
  margin-left: auto;
}

body.mobile .folder-area .folder-menu-button .caret-icon svg {
  height: 100%;
  width: auto;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
}

body.mobile .folder-area .folder-menu-button:active {
  opacity: 1;
  background: var(--background-3);
}

body.mobile .folder-area .folder-menu-button:active > * {
  opacity: var(--opacity-downstate-default);
}

body.mobile .drop-down-menu {
  width: calc(100% + 2px);
  border-radius: 0px 0px 16px 16px;
  border: 1px solid #D4D6DA;
  background-color: var(--background-2);
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);
  right: -1px;
  left: -1px;
  margin-top: -1px;
  margin-left: 0;
  max-height: calc(var(--viewport-height) - var(--height-row) + 1px);
  overflow-y: scroll;
  position: absolute;
  z-index: -1;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.15);
}

body.mobile .drop-down-menu .relative-parent {
  position: relative;
  pointer-events: auto;
}

body.mobile .drop-down-menu button,
body.mobile .drop-down-menu [role=button] {
  z-index: 1;
}

body.mobile .drop-down-menu #all-button,
body.mobile .drop-down-menu #saved-button,
body.mobile .drop-down-menu #folder-menu.vertical {
  align-items: stretch;
  margin-right: 0;
  grid-gap: 0;
}

body.mobile .drop-down-menu #all-button div[role=button],
body.mobile .drop-down-menu #saved-button div[role=button],
body.mobile .drop-down-menu #folder-menu.vertical div[role=button] {
  position: relative;
}

body.mobile .drop-down-menu #all-button div[role=button] .button-link,
body.mobile .drop-down-menu #saved-button div[role=button] .button-link,
body.mobile .drop-down-menu #folder-menu.vertical div[role=button] .button-link {
  padding-top: 10px;
  padding-bottom: 9px;
}

body.mobile .drop-down-menu #all-button *,
body.mobile .drop-down-menu #saved-button *,
body.mobile .drop-down-menu #folder-menu.vertical * {
  background: transparent;
}

body.mobile .drop-down-menu #all-button,
body.mobile .drop-down-menu #saved-button,
body.mobile .drop-down-menu #folder-menu.vertical,
body.mobile .drop-down-menu #folder-menu + .add-folder-area {
  --button-height: var(--height-row);
  padding: 0;
  margin-left: 0.5px;
}

body.mobile .drop-down-menu #all-button > div,
body.mobile .drop-down-menu #saved-button > div,
body.mobile .drop-down-menu #folder-menu.vertical > div,
body.mobile .drop-down-menu #folder-menu + .add-folder-area > div {
  padding: 0;
}

body.mobile .drop-down-menu #all-button > div .button-link,
body.mobile .drop-down-menu #saved-button > div .button-link,
body.mobile .drop-down-menu #folder-menu.vertical > div .button-link,
body.mobile .drop-down-menu #folder-menu + .add-folder-area > div .button-link {
  padding: 0 12px;
}

body.mobile .drop-down-menu #all-button > div .button-link .folder-icon,
body.mobile .drop-down-menu #saved-button > div .button-link .folder-icon,
body.mobile .drop-down-menu #folder-menu.vertical > div .button-link .folder-icon,
body.mobile .drop-down-menu #folder-menu + .add-folder-area > div .button-link .folder-icon {
  margin-right: 10px;
}

body.mobile .drop-down-menu #all-button > div .button-link .folder-icon svg,
body.mobile .drop-down-menu #saved-button > div .button-link .folder-icon svg,
body.mobile .drop-down-menu #folder-menu.vertical > div .button-link .folder-icon svg,
body.mobile .drop-down-menu #folder-menu + .add-folder-area > div .button-link .folder-icon svg {
  margin-top: 2px;
  margin-left: -1px;
}

body.mobile .drop-down-menu #all-button .button-link:active,
body.mobile .drop-down-menu #saved-button .button-link:active,
body.mobile .drop-down-menu #folder-menu.vertical .button-link:active,
body.mobile .drop-down-menu #folder-menu + .add-folder-area .button-link:active {
  background-color: rgba(var(--baseColor-default-reverse-rgb), 0.055);
}

body.mobile .drop-down-menu #saved-button div[role=button]::after,
body.mobile .drop-down-menu #folder-menu.vertical div[role=button]::after {
  content: "";
  position: absolute;
  left: 12px;
  right: 12px;
  top: 0;
  height: 0.5px;
  background: var(--ui-border-bars);
}

body.mobile .drop-down-menu #folder-menu + .add-folder-area {
  position: sticky;
  bottom: 0;
  background: var(--background-2);
}

body.mobile .drop-down-menu #folder-menu + .add-folder-area:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0.5px;
  background: var(--ui-border-bars);
  z-index: 1;
}

body.mobile .drop-down-menu #folder-menu + .add-folder-area > div .button-link {
  padding-bottom: 3px;
}

body.dark #all-button,
body.dark #saved-button,
body.dark #folder-menu,
body.dark #folder-menu + .add-folder-area {
  --selectedBackground: rgba(var(--baseColor-default-reverse-rgb), 0.095);
  --folder-color: #A1A1A1;
}

body.dark .drop-down-menu {
  border-color: rgb(var(--darkBorder-rgb)) !important;
}

/* Base Color and Background Mixins */
#search-area {
  display: flex;
  justify-content: start;
  align-items: flex-end;
  padding-bottom: var(--spacing-half);
  padding-left: var(--spacing-half);
  padding-left: 7px;
}

#search-area.searched svg path {
  color: rgba(var(--baseColor-accent-rgb), 1);
  fill: rgba(var(--baseColor-accent-rgb), 1);
}

#search-area #search-reveal {
  padding: 0;
  height: var(--height-row);
  width: var(--height-row);
}

#search-area #search-reveal:active {
  opacity: 1;
}

#search-area #search-reveal:active svg {
  opacity: var(--opacity-downstate-default);
}

#search-area .search-form {
  display: flex;
  align-items: center;
  height: 100%;
}

#search-area .search-form input#search {
  padding: var(--ui-padding-default-vertical) var(--spacing-1);
  padding-left: 0;
  background: var(--background-2);
}

#search-area .search-form .clear-search {
  padding: 0;
}

#search-area .search-form .clear-search svg {
  padding: 10px;
}

#search-area .search-form .clear-search svg circle {
  fill: transparent;
}

#search-area svg {
  width: auto;
  height: 100%;
}

#search-area svg path {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
}

.top-menu-bar .area.account-area {
  display: flex;
  padding: 0;
  justify-content: stretch;
  position: relative;
  width: calc(100% + var(--spacing-1));
  margin-left: calc(var(--spacing-1) * -1);
  margin-right: var(--element-gap);
}

.top-menu-bar .area.account-area a {
  color: var(--color-default);
}

.top-menu-bar .area.account-area a:active {
  opacity: var(--opacity-downstate-default);
}

.top-menu-bar .area.account-area .your-sites {
  height: var(--height-row);
  display: flex;
  align-items: center;
  position: absolute;
  inset: 0;
  padding-left: 45px;
}

.top-menu-bar .area.account-area img,
.top-menu-bar .area.account-area svg {
  width: auto;
  height: 100%;
}

.top-menu-bar .area.account-area button,
.top-menu-bar .area.account-area .button-link {
  height: 100%;
}

.top-menu-bar .area.account-area button.your-sites-button,
.top-menu-bar .area.account-area .button-link.your-sites-button {
  flex: 1;
  cursor: pointer;
  padding-left: calc(var(--height-row) + 5px);
}

.top-menu-bar .area.account-area button.your-sites-button:active,
.top-menu-bar .area.account-area .button-link.your-sites-button:active {
  opacity: var(--opacity-downstate-default);
}

.top-menu-bar .area.account-area button.profile,
.top-menu-bar .area.account-area .button-link.profile {
  padding: 0;
  padding: 7px;
  margin-left: 3px;
  z-index: 1;
  background: transparent;
  position: absolute;
}

.top-menu-bar .area.account-area button span,
.top-menu-bar .area.account-area .button-link span {
  line-height: 0;
  height: inherit;
}

.top-menu-bar .area.account-area button:active,
.top-menu-bar .area.account-area .button-link:active {
  opacity: 1;
}

.top-menu-bar .area.account-area button:active img,
.top-menu-bar .area.account-area button:active svg,
.top-menu-bar .area.account-area .button-link:active img,
.top-menu-bar .area.account-area .button-link:active svg {
  opacity: var(--opacity-downstate-default);
}

.mobile .top-menu-bar .account-area {
  margin: 0;
  margin-right: 0;
}

.mobile .top-menu-bar .account-area button.globe {
  left: 0;
  right: auto;
}

.mobile .top-menu-bar .account-area button.profile,
.mobile .top-menu-bar .account-area .button-link.profile {
  margin-left: 0;
}

.mobile .top-menu-bar .account-area .your-sites-button {
  justify-content: flex-start;
  padding-left: calc(var(--height-row) + var(--spacing-half) / 2);
}

.mobile .top-menu-bar .account-area:active .profile span {
  opacity: var(--opacity-downstate-default);
}

/* Base Color and Background Mixins */
body.mobile .left-menu-bar {
  display: none;
}

.mobile .add-folder-area,
.pointer-pen .add-folder-area,
.pointer-touch .add-folder-area {
  visibility: visible !important;
}

.left-menu-bar {
  position: fixed;
  top: var(--spacing-1);
  width: calc(var(--leftMenuBar-width) + var(--spacing-1) - 1px);
  margin-top: calc(-1 * var(--spacing-1));
  z-index: 301;
  padding-right: 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: var(--viewport-height);
  pointer-events: all;
  background: var(--background-2);
  transition: width 0.2s cubic-bezier(0.08, 0.82, 0.17, 1);
}

.left-menu-bar.no-transition {
  transition: none;
}

.left-menu-bar .inner {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  min-height: var(--viewport-height);
}

.left-menu-bar .inner::-webkit-scrollbar {
  display: none;
}

.left-menu-bar .add-folder-area {
  visibility: hidden;
}

.left-menu-bar:hover .add-folder-area {
  visibility: visible;
}

.left-menu-bar:after {
  right: -1px;
  content: "";
  bottom: 0;
  position: absolute;
  width: 1px;
  height: calc(100% - var(--height-row) - 1px);
  background: var(--ui-border-bars);
}

.left-menu-bar .background-fill-row {
  width: 100%;
  min-height: var(--topAlignment);
  max-height: var(--topAlignment);
}

.left-menu-bar.collapsed {
  width: calc(var(--height-row) + 0);
}

.left-menu-bar .background-fill {
  flex: 1;
}

body[slim-left-bar] .left-menu-bar,
.light-mode[slim-left-bar] .left-menu-bar,
.dark[slim-left-bar] .left-menu-bar {
  --leftMenuBar-slimming-factor: 0.666;
  width: calc((var(--leftMenuBar-width) + var(--spacing-1) - 1px) * var(--leftMenuBar-slimming-factor));
}

body.authenticated[slim-left-bar] [static-page] .background {
  --background-left-stop: calc( calc( var(--leftMenuBar-width) + var(--spacing-1) - 1px) * var(--leftMenuBar-slimming-factor));
  left: calc(var(--background-left-stop) + var(--spacing-1) + 1px);
}

/* Base Color and Background Mixins */
.footer-frame {
  --nav-pad-y: .25rem;
  --blue: rgba(255, 0, 0, 1);
  --blue-outline: rgba(255, 0, 0, 0.4);
  --blue-border: rgba(255, 0, 0, .2);
  --black: rgba(0,0,0,0.85);
  --main-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, "Sans Serif", Icons;
  --font-diatype: "Diatype", Icons;
  --font-sf-mono: "SF Mono Regular", Icons;
  --bodycopy: 22px;
  --small: 16px;
  --fontSize-small-mono: 14px;
  --fontSize-h1-larger: 32px;
  --fontSize-h1-smaller: 24px;
  --blog-border: 15px;
  --mb-blog-border: 10px;
  --card-radius: 40px;
}

.footer-frame {
  overflow: hidden;
  position: relative;
  display: inline-block;
  line-height: 1.5;
  font-family: var(--font-sf-mono);
  font-size: var(--fontSize-small-mono);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  font-style: normal;
  line-height: 1.4;
}

.footer-frame:not(.authenticated) {
  color: var(--blue);
}

.footer-frame:not(.authenticated) a {
  color: var(--blue);
}

.footer-frame a {
  color: rgba(0, 0, 0, 0.4);
  padding-bottom: 0.1em;
  border-bottom: 0em solid rgba(127, 127, 127, 0.2);
  text-decoration: none;
}

.footer-frame a:active {
  opacity: 0.7;
}

.footer-frame {
  text-align: left !important;
  overflow: hidden;
  position: relative;
  display: inline-block;
  line-height: 1.5;
  font-family: var(--font-sf-mono);
  font-size: var(--fontSize-small-mono);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  font-style: normal;
  line-height: 1.4;
}

.footer-frame:not(.authenticated) {
  color: var(--blue);
}

.footer-frame:not(.authenticated) a {
  color: var(--blue);
}

.footer-frame a {
  color: rgba(0, 0, 0, 0.4);
  padding-bottom: 0.1em;
  border-bottom: 0em solid rgba(127, 127, 127, 0.2);
  text-decoration: none;
}

.footer-frame a:active {
  opacity: 0.7;
}

.footer {
  color: var(--ui-color-default);
  padding: 0 var(--spacing-1);
  line-height: 1;
  height: var(--height-row);
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  display: flex;
  align-items: center;
  width: fit-content;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 302;
}

.footer a {
  color: var(--ui-color-default);
}

.footer button,
.footer .button-link {
  font-family: var(--fontFamily-CargoDiatype);
  font-weight: var(--fontWeight-CargoDiatype);
  letter-spacing: 0;
  font-style: normal;
  padding: var(--ui-padding-default-vertical) 0;
  pointer-events: none;
}

.footer button > *,
.footer .button-link > * {
  pointer-events: auto;
}

.footer .information {
  pointer-events: none;
  grid-column: 1/span 1;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.footer .information:active {
  opacity: var(--opacity-downstate-default);
}

/* Base Color and Background Mixins */
[data-route=folder] #manager {
  display: grid;
  grid-template-columns: var(--grid-template-columns);
  grid-column-gap: var(--grid-column-gap);
  max-height: var(--viewport-height);
}

[data-route=folder] #siteslist {
  margin-right: calc(-1 * var(--spacing-1));
}

[data-route=folder] .site button.duplicate, [data-route=folder] .site button.copy {
  display: none;
}

[data-route=folder].mobile #siteslist {
  margin-left: calc(-1 * var(--spacing-1));
}

#siteslist {
  --siteFrame-background: #cccccc;
  display: grid;
  grid-template-columns: repeat(var(--sitesPerRow), minmax(0, 1fr));
  grid-column-gap: var(--sites-columnGap);
  grid-row-gap: var(--sites-rowGap);
  grid-column: 2/-1 !important;
  min-height: calc(var(--viewport-height) - var(--height-row));
  height: calc(var(--viewport-height) - var(--height-row));
  padding: var(--sites-padding);
  padding-top: calc(var(--topAlignment) - var(--height-row));
  align-content: flex-start;
  overflow-y: auto;
  line-height: 16px;
  padding-bottom: var(--topAlignment);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
}

#siteslist a {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
}

#siteslist.transitioning-opacity.fade-in {
  transition: opacity 800ms cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 1;
}

#siteslist.transitioning-opacity {
  opacity: 0;
}

#siteslist .transition-measurer {
  width: 10px;
}

#siteslist .site-ghost {
  position: fixed !important;
  top: 0;
  right: 0;
  z-index: 301;
  transition: transform 0ms ease 0s !important;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

#siteslist .site-ghost * {
  pointer-events: none;
}

#siteslist .site-ghost.hidden {
  display: none;
}

#siteslist .site-ghost .site-preview,
#siteslist .site-ghost .site-link-frame a {
  display: flex;
  cursor: inherit;
  position: relative;
  cursor: pointer;
  width: 100%;
  line-height: 0;
  border-radius: var(--site-radius);
  box-shadow: var(--site-shadow);
  transition: transform 0.3s ease;
  transform: scale(1);
}

#siteslist .site-ghost .site-preview img,
#siteslist .site-ghost .site-link-frame a img {
  width: 100%;
  height: auto;
  border-radius: var(--site-radius);
  transition: opacity 200ms;
}

#siteslist .site-ghost.indicate-duplication * {
  cursor: copy !important;
}

#siteslist .site-ghost .details {
  display: none !important;
}

#siteslist .more-button:hover {
  visibility: visible;
}

#siteslist .sortable {
  z-index: var(--layer-1) !important;
}

#siteslist .sortable.over {
  z-index: var(--layer-2) !important;
}

#siteslist .no-sites {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  border-radius: var(--site-radius);
  display: grid;
  grid-column: 1/span 1;
  grid-row: 1/span 1;
}

#siteslist .no-sites .no-sites-button {
  position: relative;
  line-height: 0;
  border-radius: var(--site-radius);
  transition: var(--site-shadow-transition), var(--site-transformTransition);
}

#siteslist .no-sites .no-sites-button:hover {
  box-shadow: var(--site-shadow);
  transform: var(--site-transform);
}

#siteslist .no-sites .no-sites-button.clickable {
  cursor: pointer;
}

#siteslist .no-sites .no-sites-button.clickable:active {
  opacity: 0.7;
}

#siteslist .no-sites .no-sites-button #add-sites-placeholder svg {
  inset: 0;
  border: 1px solid rgba(var(--baseColor-default-reverse-rgb), 0.075);
  border-radius: var(--site-radius);
  width: 100%;
  height: auto;
  background: rgba(var(--baseColor-default-reverse-rgb), 0.025);
}

#siteslist .no-sites .no-sites-button #add-sites-plus {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 36px;
  height: 36px;
  z-index: 1;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border-radius: var(--borderRadius-small);
  background-color: var(--baseColor-accent);
}

#siteslist .no-sites .no-sites-button #add-sites-plus svg {
  width: 100%;
  height: auto;
}

#siteslist .no-sites .no-sites-button #add-sites-plus svg path {
  fill: var(--baseColor-accent-reverse);
}

body #manager {
  --sitesPerRow: 4;
  --site-radius: var(--borderRadius-small);
  --color-indicators: rgba(0,0,0,.3);
  --siteTitle-color: #979797;
  --sites-padding: 26px;
  --sites-columnGap: var(--spacing-1);
  --sites-rowGap: 65px;
  --site-shadowRadius: 15px;
  --site-shadow: 0px 5px var(--site-shadowRadius) 0px rgba(var(--color-shadow-rgb), 0.11);
  --site-shadow-transition: box-shadow 0.25s ease;
  --site-transform: translateY(-0.5px);
  --site-transformTransition: transform .25s ease;
  --addSites-circle-fill: var(--baseColor-default);
  --addSites-plus-stroke: #027AFF;
}

body.mobile {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

body.mobile #manager {
  --sitesPerRow: 1;
  --sites-padding: 15px;
}

body.mobile #manager #siteslist,
body.mobile #manager .no-sites {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-row-gap: 6vw;
}

body.mobile #manager #siteslist > *:nth-child(1),
body.mobile #manager .no-sites > *:nth-child(1) {
  margin-bottom: calc(-6vw + 40px); /* Offset the grid gap for the first item and give the header a fixed margin */
}

body.mobile #templates {
  --site-width: calc(var(--viewport-width) - 65px);
}

body.mobile #templates .site-scroller {
  scroll-snap-type: x mandatory;
}

body.mobile #templates .site {
  scroll-snap-align: center;
}

body.mobile #siteslist {
  right: 0;
}

body.mobile #intercom-launcher {
  display: none;
}

body.dark #manager {
  --siteTitle-color: #707070;
  --color-indicators: rgba(255,255,255,.4);
  --site-shadow: 0px 5px var(--site-shadowRadius) 0px rgba(var(--color-shadow-rgb), 0.16);
  --site-border: 1px solid rgba(var(--darkBorder-rgb), .85);
  --addSites-plus-stroke: #FF3D00;
}

body.dark #siteslist,
body.dark .siteslist {
  --siteFrame-background: #202020;
}

body.dark #siteslist .site-preview.cloning,
body.dark #siteslist .site-link-frame a.cloning,
body.dark .siteslist .site-preview.cloning,
body.dark .siteslist .site-link-frame a.cloning {
  filter: brightness(82%);
  opacity: 0.55;
}

@media (min-width: 0) {
  body #manager {
    --sitesPerRow: 2;
  }
  #templates {
    --site-width: calc(((var(--viewport-width) - (var(--leftMenuBar-width) + var(--spacing-1) - 1px)) - (var(--sites-padding) * 2) - (var(--sites-columnGap) * (var(--sitesPerRow) - 1))) / var(--sitesPerRow));
  }
}
@media (min-width: 800px) {
  body #manager {
    --sitesPerRow: 2;
  }
  #templates {
    --site-width: calc(((var(--viewport-width) - (var(--leftMenuBar-width) + var(--spacing-1) - 1px)) - (var(--sites-padding) * 2) - (var(--sites-columnGap) * (var(--sitesPerRow) - 1))) / var(--sitesPerRow));
  }
}
@media (min-width: 1300px) {
  body #manager {
    --sitesPerRow: 3;
  }
  #templates {
    --site-width: calc(((var(--viewport-width) - (var(--leftMenuBar-width) + var(--spacing-1) - 1px)) - (var(--sites-padding) * 2) - (var(--sites-columnGap) * (var(--sitesPerRow) - 1))) / var(--sitesPerRow));
  }
}
@media (min-width: 1850px) {
  body #manager {
    --sitesPerRow: 4;
  }
  #templates {
    --site-width: calc(((var(--viewport-width) - (var(--leftMenuBar-width) + var(--spacing-1) - 1px)) - (var(--sites-padding) * 2) - (var(--sites-columnGap) * (var(--sitesPerRow) - 1))) / var(--sitesPerRow));
  }
}
@media (min-width: 2300px) {
  body #manager {
    --sitesPerRow: 5;
  }
  #templates {
    --site-width: calc(((var(--viewport-width) - (var(--leftMenuBar-width) + var(--spacing-1) - 1px)) - (var(--sites-padding) * 2) - (var(--sites-columnGap) * (var(--sitesPerRow) - 1))) / var(--sitesPerRow));
  }
}
/* Base Color and Background Mixins */
.section .row {
  --siteScroller-padding: 15px;
  --headerBackground: rgba(0,0,0,.85);
  display: flex;
  position: relative;
  top: 0;
  bottom: 0;
  padding: 0;
  margin-bottom: 0;
  height: inherit;
  overflow: hidden;
}

.section .row .navigation {
  transition: 0.1s opacity linear;
  opacity: 1;
  width: 100%;
  height: var(--site-height);
  flex-direction: column;
  display: flex;
  align-content: center;
  justify-content: center;
  position: absolute;
}

.section .row .navigation button.next {
  z-index: 1;
  position: absolute;
  right: var(--spacing-2);
  height: 35px;
  width: 35px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  display: none;
  z-index: var(--layer-4);
}

.section .row .navigation button.next:active {
  opacity: 0.7;
}

.section .row .navigation button.next svg {
  height: auto;
  width: 100%;
  backdrop-filter: blur(22px);
  -webkit-backdrop-filter: blur(22px);
  border-radius: 50%;
}

.section .row .navigation button.next svg .cls-1 {
  fill: rgba(var(--baseColor-default-rgb), 0.4);
  color: rgba(var(--baseColor-default-rgb), 0.4);
}

.section .row .navigation button.next svg .cls-2 {
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.25);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.25);
}

.section .row .navigation button.next svg .cls-3 {
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
}

.section .row .site-scroller {
  display: flex;
  flex-wrap: nowrap;
  grid-column-gap: var(--siteScroller-padding);
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: 0;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  z-index: 1;
  padding-bottom: 0;
  height: inherit;
}

.section .row .site-scroller.scrubbing {
  cursor: -webkit-grabbing;
}

.section .row .site-scroller.scrubbing .site {
  cursor: -webkit-grabbing;
}

.section .row .site-scroller::-webkit-scrollbar {
  display: none;
}

.section .row .site-scroller > img {
  flex: 1 0 var(--site-width);
  max-width: var(--site-width);
  transform: translate(0px, 0.6rem);
}

.section .row .site-scroller > div.placeholder {
  --margin-right: calc(calc(var(--siteScroller-padding) * 0.95) / 2);
  flex: 1 0 var(--spacing-1);
  max-width: var(--spacing-1);
  margin-right: 0;
  pointer-events: none;
}

.section .row .site-scroller > div.link {
  --squareWidth: calc(var(--height-row) - var(--spacing-2));
  align-self: center;
  line-height: 0;
}

.section .row .site-scroller > div.link[dimensions=square] {
  padding-left: var(--spacing-1);
  padding-bottom: var(--spacing-2);
  padding-top: 0;
  padding-right: 0;
  flex: 1 0 calc(var(--height-row) - var(--spacing-2));
}

.section .row .site-scroller > div.link[dimensions=square] a {
  max-height: var(--squareWidth);
  max-width: var(--squareWidth);
}

.section .row .site-scroller > div.link[dimensions=square] img {
  max-width: var(--squareWidth);
  max-height: var(--squareWidth);
  width: 100%;
  height: auto;
  border-radius: var(--site-radius);
}

.section .row .site-scroller > div.link[dimensions=square] .details {
  display: none;
}

.section .row .site-scroller > div.link a {
  border-radius: var(--site-radius);
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  box-shadow: var(--site-shadow);
}

.section .row .site-scroller > div.link a:active {
  opacity: 0.7;
}

.section .row .site-scroller > div.link a:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: var(--site-radius);
}

.section .row .site-scroller > div .details {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: var(--siteDetails-height);
}

.section .row .site-scroller > div .details .site-links .domain.link {
  display: none;
}

.section .row .site-scroller > div .details .site-links .site-instagram {
  display: none;
}

.section .row .site-scroller > div .details .spacer {
  display: none;
}

.section .row .site-scroller > div .details .title-wrapper {
  max-width: var(--site-width);
}

.section .row .site-scroller > div .details .title {
  line-height: 1.3;
  user-select: none;
  -webkit-user-select: none;
  color: var(--siteTitle-color);
  text-align: center;
  flex: auto;
  height: 100%;
  display: block;
}

.section .row .site-scroller > div .details .title .title {
  line-height: 1.3;
  margin-top: -3px;
}

.section .row .site-scroller > div .details .indicators {
  margin-left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.section .row .site-scroller > div .details .indicators button {
  display: block;
  visibility: hidden;
  margin-top: -5px;
}

.section .row .site-scroller .site-card {
  position: sticky;
  left: var(--left-margin);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  transition: all 0.1s ease;
}

.section .row .site-scroller .site-card.stacked .site .details .title, .section .row .site-scroller .site-card.stacked .site:hover .details .title {
  display: none;
}

.section .row .site-scroller .site-card.stacked .site .details .title-wrapper:after, .section .row .site-scroller .site-card.stacked .site:hover .details .title-wrapper:after {
  display: none;
}

.section .row .site-scroller .site-card.stacked .site .details .indicators, .section .row .site-scroller .site-card.stacked .site:hover .details .indicators {
  display: none;
}

.section .row .site-scroller .site {
  flex-shrink: 0;
  max-height: 100%;
  height: fit-content;
  flex-basis: 100%;
}

.section .row .site-scroller .site:hover .details .title-wrapper:after {
  display: none;
}

.section .row .site-scroller .site a {
  flex: 1 0 auto;
}

.section .row .site-scroller .site a .site-preview {
  height: 100%;
}

.section .row .site-scroller .site a .site-preview img {
  height: 100%;
  width: var(--site-width);
  aspect-ratio: 16/10;
}

.section .row .site-scroller .site:not(.duplicable) .details .title {
  overflow: auto;
  text-overflow: unset;
}

.section .row .site-scroller[folder-id="783692"] .details {
  display: none;
}

.section .row:hover .navigation button.next {
  display: flex;
}

.section .row.scroll-end .navigation button.next svg rect {
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.85);
}

.section .row.scroll-end .navigation button.next svg path {
  stroke: rgba(var(--baseColor-default-rgb), 0.9);
}

body.mobile .section .row .navigation button.next {
  display: flex;
}

/* Base Color and Background Mixins */
#manager {
  --site-borderWidth: 0px;
}

.site {
  --rotate-transform: rotate( 0deg);
  --base-translate: translate(0, 0);
  --translate-left: translateX( calc( 10px * -1 ) );
  --translate-right: translateX( 10px );
  --translate-up: translateY(-20px);
  --translate-down: translateY(20px);
  --siteBackground: rgba(var(--baseColor-default-reverse-rgb), 0.15);
  --site-outline-color: rgba(var(--baseColor-default-reverse-rgb), 0.12);
  --site-border: 1px solid var(--site-outline-color);
  z-index: 200;
  transition: transform 200ms ease-out 0s;
  position: relative;
  transform-origin: center center;
  transform: var(--base-translate, translate(0)) var(--rotate-transform, translate(0));
  user-select: none;
  padding-bottom: 0;
  pointer-events: none;
}

.site.sparse {
  height: 0;
  padding-bottom: calc(62.5% + 50px);
}

.site > * {
  pointer-events: all;
}

.site.higher-z-index, .site.hovering, .site.menu {
  z-index: 202;
}

.site:hover {
  z-index: 203;
}

.site > a .site-preview,
.site .deleted-site .site-preview {
  position: relative;
  box-shadow: none;
}

.site > a .site-preview img,
.site .deleted-site .site-preview img {
  position: relative;
  pointer-events: none;
  border-radius: var(--site-radius);
  outline: var(--site-border);
  transition: var(--site-shadow-transition), var(--site-transformTransition);
}

.site.unavailable, .site.unavailable * {
  pointer-events: none;
}

.site.dragged {
  opacity: 0.5;
  transform: none;
  cursor: default !important;
}

.site.dropping {
  opacity: 1;
  z-index: 300;
}

.site.dropping img {
  opacity: 1;
}

.site.deleted .details .indicators span {
  pointer: default;
  pointer-events: none;
}

.site.deleted .deleted-site .site-preview {
  pointer: default;
}

.site a .site-preview {
  cursor: zoom-in;
}

.site a .site-preview * {
  cursor: zoom-in;
}

.site a .site-preview.cloning {
  filter: brightness(55%);
}

.site.menu-from-button .more-button {
  opacity: 0.6;
}

.site.menu-from-button .more-button svg rect {
  fill: #222222;
}

.site:hover .site-preview, .site.menu .site-preview {
  cursor: zoom-in;
}

.site:hover .site-preview img, .site.menu .site-preview img {
  box-shadow: var(--site-shadow);
  transform: var(--site-transform);
}

.site:hover .site-preview *, .site.menu .site-preview * {
  cursor: zoom-in;
}

.site:hover .deleted-site .site-preview, .site.menu .deleted-site .site-preview {
  cursor: default;
}

.site:hover .site-buttons,
.site:hover .more-button,
.site:hover .more-button + .site-badges,
.site:hover .more-button:hover + .site-badges, .site.menu .site-buttons,
.site.menu .more-button,
.site.menu .more-button + .site-badges,
.site.menu .more-button:hover + .site-badges {
  transform: var(--site-transform);
}

.site:hover .more-button svg, .site.menu .more-button svg {
  transform: translateZ(0);
}

.site a:hover + .more-button,
.site a:hover + .site-badges site-badge + .details + .more-button,
.site .deleted-site:hover + .more-button,
.site a:hover + .more-button + .site-badges site-badge,
.site a + .more-button:hover + .site-badges site-badge {
  visibility: visible;
}

.site a,
.site .deleted-site {
  display: flex;
  position: relative;
  cursor: pointer;
}

.site a:active,
.site .deleted-site:active {
  opacity: 1;
}

.site a .progress,
.site .deleted-site .progress {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 15px;
  height: 5px;
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  pointer-events: none;
  z-index: 205;
}

.site a .progress .track,
.site .deleted-site .progress .track {
  background: rgba(255, 255, 255, 0.25);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
}

.site a .progress .track .completed,
.site .deleted-site .progress .track .completed {
  background: #fff;
  transition: width 0.3s ease-out;
  height: 100%;
}

.site a .loader,
.site .deleted-site .loader {
  z-index: 205;
}

.site .site-preview,
.site .site-link-frame a {
  width: 100%;
  line-height: 0;
  border-radius: var(--site-radius);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.site .site-preview img,
.site .site-link-frame a img {
  width: 100%;
  height: auto;
  border-radius: var(--site-radius);
  transition: opacity 200ms;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.site .site-badges {
  position: absolute;
  inset: var(--site-borderWidth);
  aspect-ratio: 16/10;
  pointer-events: none;
  transition: var(--site-transformTransition);
}

.site .site-badges site-badge {
  position: absolute;
  right: 12px;
  bottom: 11px;
  width: auto;
  user-select: none;
  display: flex;
  flex-direction: row;
  background: var(--baseColor-accent-alt1);
  justify-content: center;
  align-items: center;
  font-family: var(--fontFamily-default);
  font-size: 11px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  padding: 2.5px 5px 4px 5px;
  line-height: normal;
  height: 20px;
  outline: 1px solid rgba(0, 0, 0, 0.12);
  outline-offset: -1px;
  visibility: hidden;
  pointer-events: none;
}

.site .site-badges site-badge.second-badge {
  right: 62px;
}

.site .site-badges site-badge[type=cargo-2] {
  visibility: visible;
  background: #CECEFF;
}

.site .site-badges site-badge[type=inuse] {
  visibility: visible;
  background: #FF3D00;
  color: #fff;
}

.site .deleted-site {
  cursor: default;
}

.site.active {
  opacity: 0.7;
}

.site.clone {
  pointer-events: none;
  cursor: default;
}

.site.clone .details {
  display: none;
}

.site:not(.image-loaded) img {
  visibility: hidden;
}

.site .more-button {
  display: inline-flex;
  position: absolute;
  top: calc(12px + var(--site-borderWidth));
  right: calc(12px + var(--site-borderWidth));
  visibility: hidden;
  padding: 0;
  z-index: var(--layer-1);
  transition: var(--site-transformTransition);
}

.site .more-button svg {
  height: auto !important;
  fill: #fff;
  width: 36px;
}

.site .more-button svg rect {
  fill: #949494;
}

.site .more-button:hover svg rect, .site .more-button:active svg rect {
  fill: #222222;
}

.site .more-button:active {
  opacity: 0.6;
}

.site .details {
  position: relative;
  font-size: var(--fontSize-default);
  font-style: normal;
  line-height: 1.3;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  height: 50px;
  pointer-events: none;
}

.site .details > *:not(:empty) {
  pointer-events: all;
}

.site .details.stacked .site-links {
  flex-direction: column;
}

.site .details.stacked .site-links .direct-site-links {
  display: flex;
  flex-direction: column;
}

.site .details.stacked .site-links .direct-site-links .domain {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.25);
  font-size: var(--fontSize-small);
  margin-top: 1px;
}

.site .details.stacked .site-links .direct-site-links .domain:hover:after {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.3);
}

.site .details.stacked .site-links .site-instagram {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.3);
  font-size: var(--fontSize-small);
  margin-top: 1px;
  max-width: fit-content;
}

.site .details.stacked .site-links .site-instagram:hover {
  text-decoration: underline;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.3);
}

.site .details.stacked .site-links .site-instagram:active {
  opacity: 0.7;
}

.site .details .direct-site-links:hover .title-wrapper {
  display: flex;
}

.site .details .direct-site-links:hover .title-wrapper:after {
  content: "↗";
  padding-left: 0.25em;
  display: inline-block;
  transform: translate(0px, -2px);
}

.site .details .direct-site-links:hover .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.site .details .site-links {
  color: var(--siteTitle-color);
  align-self: flex-start;
  margin-bottom: 5px;
  overflow-x: hidden;
  flex-direction: row;
  padding-top: calc(var(--spacing-1) - 5px);
  pointer-events: all;
}

.site .details .site-links .title,
.site .details .site-links > a,
.site .details .site-links a {
  color: var(--siteTitle-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: fit-content;
  margin: -1px -1px 0 -1px;
  padding: 1px 1px 0 1px;
}

.site .details .site-title {
  cursor: default;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: calc(var(--spacing-1) - 5px);
}

.site .details .site-title .deleted {
  cursor: default;
}

.site .details .spacer {
  flex: 1;
  pointer-events: none;
}

.site .details .indicators {
  cursor: default;
  align-self: flex-start;
  grid-gap: 5px;
  display: inline-grid;
  grid-template-columns: auto;
  grid-auto-flow: column;
  padding-top: calc(var(--spacing-1) - 5px);
}

.site .details .indicators button.duplicate,
.site .details .indicators button.copy {
  display: none;
}

.site .details .indicators button.duplicate.saved,
.site .details .indicators button.copy.saved {
  transform: translate(-1px, -0.6em);
}

.site .details .indicators button.copy {
  margin-right: 0;
  background: var(--baseColor-accent);
}

.site .details .indicators span {
  cursor: pointer;
}

.site .details .indicators span.site-private {
  pointer-events: none;
  cursor: default;
}

.site .details .indicators span.icon {
  position: relative;
  display: inline-flex;
  height: 1.66em;
  margin-right: 1px;
}

.site .details .indicators span.icon:active {
  opacity: 0.7 !important;
}

.site .details .indicators span.icon img {
  width: 1.25em;
  height: 1.25em;
}

.site .details .indicators span.icon svg {
  height: 1.2em;
  width: 1.2em;
}

.site .details .indicators span.icon.site-private, .site .details .indicators span.icon.read-only {
  width: 1.66em;
  transform: translate(0, -0.25em);
}

.site .details .indicators span.icon.site-private svg, .site .details .indicators span.icon.read-only svg {
  height: auto !important;
  width: 100%;
}

.site .details .indicators span.icon.site-private svg *, .site .details .indicators span.icon.read-only svg * {
  fill: var(--color-indicators);
}

.site .details .indicators span.icon.read-only {
  width: 1.3em;
  pointer-events: none;
}

.site .details .indicators span.icon.associated.outline {
  opacity: 1;
}

.site .details .indicators .editors {
  display: flex;
  flex-direction: row-reverse;
  transform: translate(0, -4px);
  cursor: pointer;
}

.site .details .indicators .editors:active {
  opacity: 0.7;
}

.site .details .indicators .editors svg {
  width: auto;
  height: 20px;
}

.site .details .indicators:has(*) {
  margin-left: 2ch;
}

.site.menu .more-button {
  visibility: visible;
}

.site.duplicable {
  position: relative;
}

.site.duplicable .site-buttons {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 8px;
  right: 8px;
  width: auto;
  justify-content: flex-end;
  z-index: var(--layer-1);
  transition: var(--site-transformTransition);
}

.site.duplicable .site-buttons .duplicate, .site.duplicable .site-buttons .copy {
  top: var(--site-borderWidth);
  right: var(--site-borderWidth);
}

.site.duplicable .site-buttons button + button {
  margin-left: 5px;
}

.site.duplicable button {
  position: relative;
  right: 4px;
  width: 36px;
  height: 36px;
  background: transparent;
  z-index: 1;
  visibility: hidden;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border-radius: var(--borderRadius-small);
  overflow: hidden;
  background-color: var(--ui-load-spinner-large-background-color);
}

.site.duplicable button:hover {
  background-color: #222222; /* same as [...] in Your Sites */
}

.site.duplicable button svg {
  width: 100%;
  height: auto;
}

.site.duplicable button svg path {
  fill: #fff;
}

.site.duplicable button svg rect {
  display: none;
}

.site.duplicable:hover button {
  visibility: visible;
}

.dark .site {
  --siteBackground: rgba(var(--baseColor-default-reverse-rgb), 0.02);
}

.pointer-touch .more-button,
.pointer-pen .more-button {
  visibility: visible !important;
}

.mobile .site .site-preview:not(:hover) img {
  box-shadow: 0px 5px 12px 0px rgba(var(--color-shadow-rgb), 0.05); /* light shadow always present */
}

.mobile .site .details {
  height: 52px;
}

.mobile .site .details .indicators .upgraded {
  pointer-events: none;
}

.mobile .site.sparse {
  padding-bottom: calc(62.5% + 52px);
}

.mobile .site .site-badges site-badge {
  visibility: visible;
}

.mobile .site .more-button {
  visibility: visible !important;
  top: 15px;
  right: 15px;
}

.mobile .site .more-button svg {
  width: 40px;
}

#siteslist.dragging-list-item .site:hover .site-preview, #siteslist.dragging-list-item .site.menu .site-preview {
  cursor: zoom-in;
  box-shadow: none;
  transform: translateY(0px);
}

#siteslist.dragging-list-item .site:hover .site-buttons,
#siteslist.dragging-list-item .site:hover .more-button,
#siteslist.dragging-list-item .site:hover .more-button + .site-badges,
#siteslist.dragging-list-item .site:hover .more-button:hover + .site-badges, #siteslist.dragging-list-item .site.menu .site-buttons,
#siteslist.dragging-list-item .site.menu .more-button,
#siteslist.dragging-list-item .site.menu .more-button + .site-badges,
#siteslist.dragging-list-item .site.menu .more-button:hover + .site-badges {
  transform: none;
}

#siteslist.dragging-list-item .site:hover > a,
#siteslist.dragging-list-item .site:hover .site-buttons {
  transform: translateY(0px);
}

#siteslist.dragging-list-item .site:hover .site-preview {
  box-shadow: none !important;
  filter: none;
}

body.meta-key-pressed #siteslist .site a .site-preview {
  cursor: pointer;
}

/* Base Color and Background Mixins */
body.unauthenticated site-previewer {
  --site-link-color: rgba(0,0,0,.4);
  --site-link-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.3);
  --arrow-stroke: rgba(0, 0, 0, 0.4);
  --close-fill: rgba(120,120,120, .6);
}

body.unauthenticated site-previewer .top-menu-bar {
  background: var(--baseColor-border);
}

body.safari site-previewer .top-menu-bar button:not(.mobile):not(.desktop), body.safari site-previewer .top-menu-bar a {
  padding: 5px 10px 5px !important;
}

body site-previewer {
  --header-height: var(--height-row);
  --border-color: rgba(0,0,0,.15);
  --site-link-color: rgba(0,0,0,.4);
  --site-link-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.3);
  --arrow-stroke: rgba(0, 0, 0, 0.4);
  --close-fill: rgba(120,120,120, .6);
  position: fixed;
  inset: 0;
  z-index: 900;
  display: flex;
  flex-direction: column;
}

body site-previewer .dotdotdot {
  white-space: nowrap;
  pointer-events: auto;
  outline: none;
  border: none;
  background: none;
  display: block;
  padding: 0;
  margin-left: 5px;
  margin-top: 1px;
  width: 22px;
  height: 22px;
  z-index: 1002;
}

body site-previewer .dotdotdot svg {
  display: block;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body site-previewer .dotdotdot svg .inner-circle {
  fill: white;
}

body site-previewer .dotdotdot svg .outer-circle {
  stroke: black;
  stroke-opacity: 0.25;
}

body site-previewer .dotdotdot svg .dot {
  opacity: 0.4;
  fill: black;
}

body site-previewer .background {
  background: var(--background-blur-rgba);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  background: rgba(252, 252, 252, 0.75);
  position: absolute;
  inset: 0;
}

body site-previewer .top-menu-bar {
  display: flex;
  justify-content: space-between;
  position: relative;
  grid-gap: var(--element-gap);
  padding: 0;
}

body site-previewer .top-menu-bar::after {
  display: block;
}

body site-previewer .top-menu-bar button .actions,
body site-previewer .top-menu-bar .button-link .actions {
  display: flex;
}

body site-previewer .top-menu-bar button::after,
body site-previewer .top-menu-bar .button-link::after {
  content: unset;
}

body site-previewer .top-menu-bar button .centered-area,
body site-previewer .top-menu-bar .button-link .centered-area {
  flex-direction: row;
  align-items: center;
  height: var(--height-row);
  background: transparent;
  display: flex;
  justify-content: space-between;
}

body site-previewer .top-menu-bar button .centered-area:nth-child(1),
body site-previewer .top-menu-bar .button-link .centered-area:nth-child(1) {
  padding: 0;
}

body site-previewer .top-menu-bar button .centered-area:nth-child(2),
body site-previewer .top-menu-bar .button-link .centered-area:nth-child(2) {
  flex: 1;
  justify-content: center;
}

body site-previewer .top-menu-bar button .centered-area:nth-child(3),
body site-previewer .top-menu-bar .button-link .centered-area:nth-child(3) {
  justify-content: flex-end;
}

body site-previewer .top-menu-bar button.desktop,
body site-previewer .top-menu-bar .button-link.desktop {
  padding-right: 3px !important;
}

body site-previewer .top-menu-bar button.mobile,
body site-previewer .top-menu-bar .button-link.mobile {
  padding-left: 3px !important;
}

body site-previewer .top-menu-bar button.desktop, body site-previewer .top-menu-bar button.mobile,
body site-previewer .top-menu-bar .button-link.desktop,
body site-previewer .top-menu-bar .button-link.mobile {
  padding: 0 3px 0;
}

body site-previewer .top-menu-bar button.desktop svg *, body site-previewer .top-menu-bar button.mobile svg *,
body site-previewer .top-menu-bar .button-link.desktop svg *,
body site-previewer .top-menu-bar .button-link.mobile svg * {
  opacity: 0.3;
  fill: var(--ui-color-default);
}

body site-previewer .top-menu-bar button.desktop.selected svg *, body site-previewer .top-menu-bar button.mobile.selected svg *,
body site-previewer .top-menu-bar .button-link.desktop.selected svg *,
body site-previewer .top-menu-bar .button-link.mobile.selected svg * {
  opacity: 1;
  fill: var(--baseColor-accent);
}

body site-previewer .top-menu-bar button.site-link, body site-previewer .top-menu-bar button.close,
body site-previewer .top-menu-bar .button-link.site-link,
body site-previewer .top-menu-bar .button-link.close {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

body site-previewer .top-menu-bar button.site-link svg, body site-previewer .top-menu-bar button.close svg,
body site-previewer .top-menu-bar .button-link.site-link svg,
body site-previewer .top-menu-bar .button-link.close svg {
  width: 100%;
  height: auto;
}

body site-previewer .top-menu-bar button.site-link svg rect, body site-previewer .top-menu-bar button.close svg rect,
body site-previewer .top-menu-bar .button-link.site-link svg rect,
body site-previewer .top-menu-bar .button-link.close svg rect {
  fill: none;
}

body site-previewer .top-menu-bar button.site-link svg path,
body site-previewer .top-menu-bar .button-link.site-link svg path {
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.85);
}

body site-previewer .top-menu-bar button.close svg path,
body site-previewer .top-menu-bar .button-link.close svg path {
  stroke: rgba(var(--baseColor-default-reverse-rgb), 0.85);
}

body site-previewer .top-menu-bar .proceed-area {
  height: var(--height-row);
  display: flex;
  align-items: center;
}

body site-previewer .top-menu-bar .proceed-area button {
  background-color: var(--background-3);
}

body site-previewer .top-menu-bar .proceed-area button + button, body site-previewer .top-menu-bar .proceed-area button + button + button {
  margin-left: var(--element-gap);
}

body site-previewer .top-menu-bar .proceed-area button.start {
  background-color: var(--background-2);
}

body site-previewer .top-menu-bar .proceed-area button.start::before {
  content: "";
  position: relative;
  padding: 5px;
  margin: 10px;
  margin-top: 11px;
  margin-left: 0;
  background: var(--baseColor-accent);
  border-radius: 50%;
  height: auto;
}

body site-previewer .top-menu-bar .viewport-buttons {
  display: flex;
  justify-content: center;
  position: absolute;
  pointer-events: none;
  max-width: 150px;
  min-width: 100%;
}

body site-previewer .top-menu-bar .viewport-buttons button {
  pointer-events: auto;
}

body site-previewer .preview-nav .button-area.next button.next-site {
  transform: scale(-1);
}

body site-previewer .site-frame {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  pointer-events: none;
  z-index: 1;
  padding: 25px 0 25px;
}

body site-previewer .site-frame .site-frame-border {
  position: relative;
  line-height: 0;
}

body site-previewer .site-frame .site-frame-border:after {
  content: "";
  inset: 0;
  position: absolute;
  z-index: 1;
  border: 1px solid rgba(var(--baseColor-default-reverse-rgb), 0.15);
  pointer-events: none;
  border-radius: 10px;
}

body site-previewer .site-frame iframe {
  position: absolute;
  margin: auto;
  inset: -100vmax;
  transform-origin: center;
  box-shadow: 0px 11px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: calc(var(--border-radius-scale, 1) * 10px);
  pointer-events: auto;
  background: var(--baseColor-default);
}

body site-previewer .measuring-frame {
  position: absolute;
  top: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  pointer-events: none;
  width: 100%;
  bottom: 0;
  padding: 0;
  z-index: 0;
  visibility: hidden;
}

body site-previewer .measuring-frame .measure {
  z-index: 0;
  pointer-events: none;
  visibility: hidden;
}

body site-previewer .preview-nav {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  top: var(--header-height);
  padding-top: 15px;
  padding-bottom: 30px;
}

body site-previewer .preview-nav .button-area {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 60px;
  position: relative;
}

body site-previewer .preview-nav .button-area button {
  pointer-events: auto;
  margin: 0.25rem;
  transform: translate(-1.5vw, calc(var(--rowPage-preview-width) * 0.3125 + 1rem - 1.3vw));
  height: 40px;
  width: 40px;
  background: transparent;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

body site-previewer .preview-nav .button-area button.close {
  position: absolute;
  top: 0;
  margin-top: 0;
  padding-top: 0;
  align-items: flex-start;
}

body site-previewer .preview-nav .button-area button svg {
  height: auto;
  width: 32px;
  fill: none;
  padding: 1px;
}

body site-previewer .preview-nav .button-area button svg .outer-color {
  stroke-linecap: square;
  stroke-width: 2px;
  stroke: var(--arrow-stroke);
}

body site-previewer .preview-nav .button-area button svg .inner-color {
  fill: none;
  stroke-linecap: square;
  stroke-width: 1.5px;
  stroke: var(--arrow-stroke);
}

body site-previewer .preview-nav .button-area button .right-arrow {
  transform: rotate(180deg);
}

body.dark site-previewer {
  --site-link-color: rgba(255, 255, 255, .5);
  --site-link-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.45);
  --arrow-stroke: rgba(255, 255, 255, 0.65);
  --border-color: var(--legacyColor-grayscale-darker);
  --close-fill: #aaa;
  --ui-checkers-bg: #1A1A1A;
}

body.dark site-previewer .site-frame iframe {
  box-shadow: 0px 11px 20px 0px rgba(0, 0, 0, 0.25);
  pointer-events: auto;
}

body.dark site-previewer .top-menu-bar {
  background: var(--baseColor-container);
}

body.dark site-previewer .background {
  background: var(--background-blur-rgba);
  position: absolute;
  inset: 0;
}

body.dark site-previewer .background::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 12 12' enable-background='new 0 0 12 12' xml:space='preserve'%3E%3Crect x='-0.00797' y='-0.00797' fill='%23484848' width='6' height='6'/%3E%3Crect x='5.99203' y='5.99203' fill='%23484848' width='6' height='6'/%3E%3C/svg%3E");
}

.browser-bar {
  mix-blend-mode: normal;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  height: 35px;
  width: 100%;
  background: #343434;
  position: relative;
  margin: auto auto 0 auto;
  padding: 0;
  top: -35px;
  left: 0;
  width: 100%;
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.browser-bar:after {
  position: absolute;
  content: "";
  display: block;
  inset: 0px -1px -1px -1px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 105;
}

.browser-bar .browser-window-button {
  height: 9px;
  width: 9px;
  border-radius: 100%;
  background: #262626;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.browser-bar .browser-window-button:nth-child(1) {
  left: 15px;
}

.browser-bar .browser-window-button:nth-child(2) {
  left: 31px;
}

.browser-bar .browser-window-button:nth-child(3) {
  left: 47px;
}

.browser-bar .address-bar {
  height: 20px;
  max-width: 40%;
  flex-grow: 1;
  color: #858585;
  background: #262626;
  border-radius: 4.5px;
  display: flex;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px;
  margin: 0 80px;
}

body.mobile .browser-bar {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  height: 25px;
  top: -25px;
  font-size: 10px;
}

body.mobile .browser-bar .address-bar {
  min-width: 60px;
}

body.mobile .browser-bar .browser-window-button {
  height: 7.5px;
  width: 7.5px;
}

body.mobile .browser-bar .browser-window-button:nth-child(1) {
  left: 10px;
}

body.mobile .browser-bar .browser-window-button:nth-child(2) {
  left: 23.5px;
}

body.mobile .browser-bar .browser-window-button:nth-child(3) {
  left: 37px;
}

.fade-in-glide {
  opacity: 0;
  animation: 0.35s ease-in-out 1 both;
  animation-delay: 2350ms;
  -webkit-animation-delay: 2350ms;
}

.fade-in-glide.up {
  animation-name: fade-in-glide-up;
}

.fade-in-glide.down {
  animation-name: fade-in-glide-down;
}

@keyframes fade-in-glide-up {
  0% {
    opacity: 0;
    transform: translateY(1px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fade-in-glide-down {
  0% {
    opacity: 0;
    transform: translateY(-1px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Base Color and Background Mixins */
body[data-subroute=all],
body[data-subroute=graphic-design],
body[data-subroute=in-use] {
  background-color: var(--background-force-dark);
}

body[data-subroute=style],
body[data-subroute=photo],
body[data-subroute=architecture-design],
body[data-subroute=art],
body[data-subroute=shops] {
  background-color: #FCFCFC;
}

body[data-route=home].mobile #manager.grid-overlay-active::before,
body[data-route=community] #manager.grid-overlay-active::before {
  content: " ";
  z-index: 1;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 50vw;
  display: block;
  background-color: #fff;
}

body[data-route=home].mobile #manager,
body[data-route=community] #manager {
  background: transparent;
  padding-top: var(--spacing-1);
}

#community {
  margin: calc(var(--spacing-1) * -1);
  --caption-height: calc( ( var(--spacing-1) + 32px + var(--spacing-half) ) );
  /* Animations */
}

#community.preload {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--min-viewport-height);
  overflow: hidden;
  visibility: hidden !important;
  z-index: -1 !important;
}

#community.preload .crease, #community.preload .caption {
  display: none !important;
}

#community.preload .layout, #community.preload img, #community.preload .cell, #community.preload .item {
  visibility: hidden !important;
}

#community:not(.all) .layout.double, #community:not(.all) .layout.shop, #community:not(.all) .layout.spread, #community:not(.all) .layout.spreadfit {
  padding-bottom: var(--spacing-4);
}

#community:not(.all) .layout.double:after, #community:not(.all) .layout.shop:after, #community:not(.all) .layout.spread:after, #community:not(.all) .layout.spreadfit:after {
  display: none;
}

#community:not(.all) .layout.fitcolumn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

#community:not(.all) .layout.fitcolumn.reverse {
  flex-direction: row-reverse;
}

#community:not(.all) .layout.fitcolumn .cell {
  width: 50%;
  flex-grow: 0;
}

#community:not(.all) .layout.fitcolumn .cell .item {
  width: 100%;
  flex-direction: column;
}

#community:not(.all) .layout.fitcolumn .cell .item .caption {
  width: 100%;
  order: 2;
}

#community:not(.all) .layout.fitcolumn .cell a.image-link {
  order: 1;
  width: 100%;
}

#community.all:after {
  content: "";
  display: block;
  position: absolute;
  inset: 0 0 auto 0;
  height: 100%;
  background: var(--background-force-dark);
  z-index: -1;
}

#community .layout .cell {
  visibility: hidden;
}

#community .layout .crease {
  display: none;
}

#community .layout.crease-loaded.loaded .cell {
  visibility: visible;
}

#community .layout.crease-loaded .crease {
  display: block;
}

#community.art .layout:not(.art) {
  display: none;
}

#community.art .layout.art.category-view-only {
  display: flex;
}

#community.photo .layout:not(.photo) {
  display: none;
}

#community.photo .layout.photo.category-view-only {
  display: flex;
}

#community.graphicdesign .layout:not(.graphicdesign) {
  display: none;
}

#community.graphicdesign .layout.graphicdesign.category-view-only {
  display: flex;
}

#community.architecturedesign .layout:not(.architecturedesign) {
  display: none;
}

#community.architecturedesign .layout.architecturedesign.category-view-only {
  display: flex;
}

#community.style .layout:not(.style) {
  display: none;
}

#community.style .layout.style.category-view-only {
  display: flex;
}

#community.photo .layout:not(.photo) {
  display: none;
}

#community.photo .layout.photo.category-view-only {
  display: flex;
}

#community.inuse .layout:not(.inuse) {
  display: none;
}

#community.inuse .layout.inuse.category-view-only {
  display: flex;
}

#community.shop .layout:not(.shop) {
  display: none;
}

#community.shop .layout.shop.category-view-only {
  display: flex;
}

#community.inuse, #community.graphicdesign {
  background: var(--background-force-dark);
}

#community.inuse .layout:after, #community.graphicdesign .layout:after {
  content: "";
  display: block;
  position: absolute;
  inset: -0.5px;
  border: 1px solid #313131;
  pointer-events: none;
}

#community.inuse {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#community.inuse a.image-link {
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.8);
}

#community.inuse .layout.inuse.left:after {
  border-left: 0px solid #313131;
}

#community.inuse .layout.inuse.right:after {
  border-right: 0px solid #313131;
}

#community.inuse .layout.inuse.inuse-spread {
  width: 100%;
  --layout-height: 112.5svh;
}

#community.inuse .layout.inuse.inuse-spread:after {
  border-right: 0px solid #313131;
  border-left: 0px solid #313131;
}

#community.inuse .layout.inuse:not(.inuse-spread) {
  padding-right: var(--spacing-2);
  padding-left: var(--spacing-2);
  --layout-height:calc( clamp( 62svh , ( var(--viewport-width-unitless) / 1500 ) * 78svh + ( ( 1500 - var(--viewport-width-unitless)) / 1500 ) * 50svh , 78svh ) );
  width: 50%;
  flex-shrink: 0;
  flex-grow: 0;
}

#community.inuse .layout.inuse:not(.inuse-spread).margin-top {
  margin-top: calc(var(--layout-height) / 3);
}

#community.inuse .layout.inuse:not(.inuse-spread).margin-bottom {
  margin-bottom: calc(var(--layout-height) / 3);
}

#community.inuse .layout.inuse:not(.inuse-spread).first-visible-in-page {
  height: var(--layout-height);
  padding-top: var(--spacing-3);
}

#community.inuse .layout.inuse:not(.inuse-spread).first-visible-in-page a.image-link {
  transform: translateY(0);
}

#community.inuse .layout.inuse:not(.inuse-spread).second-visible-in-page {
  height: var(--layout-height);
  padding-top: var(--spacing-3);
}

#community.inuse .layout.inuse:not(.inuse-spread) .caption {
  padding-left: 0px;
  width: calc(100% + var(--spacing-1));
  margin-left: calc(-1 * var(--spacing-1));
}

#community.inuse .layout.inuse:not(.inuse-spread) a.image-link {
  width: min((var(--layout-height) + -1 * var(--spacing-3) + -1 * var(--caption-height) - 35px) * var(--aspect-ratio), 100%);
}

#community .layout {
  --layout-height: 105svh;
  min-height: var(--layout-height);
  contain: layout;
  display: flex;
  width: 100%;
  position: relative;
}

#community .layout.first-visible-in-page.graphicdesign, #community .layout.first-visible-in-page.inuse {
  --layout-height:calc( var(--min-viewport-height) - var(--spacing-1) );
}

#community .layout.crease-loaded {
  background: #FCFCFC;
}

#community .layout.category-view-only {
  display: none;
}

#community .layout.inuse {
  --layout-height: 112.5svh;
  min-height: auto;
  width: 100%;
  max-width: 100vw;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  justify-items: stretch;
  position: relative;
  z-index: 108;
  height: var(--layout-height);
}

#community .layout.inuse.crease-loaded {
  background: var(--background-force-dark);
}

#community .layout.inuse .cell {
  flex-grow: 1;
  position: relative;
}

#community .layout.inuse .item {
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  width: 100%;
  height: 100%;
  margin: auto;
  justify-content: flex-end;
  align-items: flex-end;
}

#community .layout.inuse .item .caption {
  width: 100%;
  margin: 0;
  text-align: left;
}

#community .layout.inuse .item a.image-link {
  margin: auto;
  display: block;
  position: relative;
  width: min((var(--layout-height) + -5 * var(--spacing-1) - 4svh + -1 * var(--caption-height)) * var(--aspect-ratio), 66.66% - 2vw);
  aspect-ratio: var(--aspect-ratio);
  box-sizing: content-box;
  inset: 0;
  margin: auto;
  padding-top: 35px;
  border-radius: 8px;
}

#community .layout.inuse .item a.image-link .browser-bar:after {
  position: absolute;
  content: "";
  inset: 0px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 0px solid rgba(255, 255, 255, 0.1);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

#community .layout.inuse .item a.image-link:after {
  position: absolute;
  content: "";
  inset: 35px 0px 0px 0px;
  border: 1px solid #313131;
  border-top: 0px solid #313131;
  mix-blend-mode: plus-lighter;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

#community .layout.inuse .item img {
  outline: 1px solid rgba(0, 0, 0, 0.2);
  outline-offset: -1px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

#community .layout.double .cell .image-link, #community .layout.spreadfit .cell .image-link, #community .layout.spread .cell .image-link, #community .layout.shop .cell .image-link {
  mix-blend-mode: darken;
}

#community .layout.double:after, #community .layout.spreadfit:after, #community .layout.spread:after, #community .layout.shop:after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-top: 1px solid rgba(100, 100, 100, 0.25);
  border-bottom: 1px solid rgba(100, 100, 100, 0.2);
  mix-blend-mode: plus-lighter;
  z-index: 102;
  pointer-events: none;
}

#community .layout.double:not(.mixed-overlap) {
  flex-direction: row;
  flex-wrap: nowrap;
  /**
      in these layouts the 'inertia' always flows ↘
      so that in order to reliably get the 'opposite'
      layout we can take a preexisting layout and
      flip the column order
  **/
}

#community .layout.double:not(.mixed-overlap) .cell {
  order: 0;
}

#community .layout.double:not(.mixed-overlap) .cell .item {
  margin-right: 0;
}

#community .layout.double:not(.mixed-overlap) .cell:nth-child(2) {
  order: 1;
}

#community .layout.double:not(.mixed-overlap) .cell:nth-child(2) .item {
  margin-left: 0;
}

#community .layout.double:not(.mixed-overlap).reverse .cell {
  order: 1;
}

#community .layout.double:not(.mixed-overlap).reverse .cell .item {
  margin-left: 0;
}

#community .layout.double:not(.mixed-overlap).reverse .cell:nth-child(2) {
  order: 0;
}

#community .layout.double:not(.mixed-overlap).reverse .cell:nth-child(2) .item {
  margin-right: 0;
}

#community .layout.double:not(.mixed-overlap).variation:not(.center-aligned).unequal-bottom-aligned.normal .cell:nth-child(1) .item {
  margin-top: 0;
}

#community .layout.double:not(.mixed-overlap).variation:not(.center-aligned).unequal-bottom-aligned.reverse .cell:nth-child(2) .item {
  margin-top: 0;
}

#community .layout.double:not(.mixed-overlap).variation:not(.center-aligned).variation-small .cell:nth-child(1) .item {
  width: 25%;
  min-width: min(300px, 100%);
}

#community .layout.double:not(.mixed-overlap).variation:not(.center-aligned) .cell:nth-child(1) .item {
  padding-top: var(--spacing-1);
  width: 50%;
}

#community .layout.double:not(.mixed-overlap).variation:not(.center-aligned).right-align .cell:nth-child(1) .item .caption {
  padding-left: 0px;
}

#community .layout.double:not(.mixed-overlap).variation:not(.center-aligned).right-align .cell:nth-child(1) .item {
  margin-left: auto;
  margin-right: 0;
}

#community .layout.double:not(.mixed-overlap).variation:not(.center-aligned).left-align .cell:nth-child(1) .item {
  margin-left: 0;
  margin-right: auto;
}

#community .layout.double:not(.mixed-overlap) .cell {
  order: 0;
  width: 50%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

#community .layout.double:not(.mixed-overlap) .cell:nth-child(2) {
  order: 1;
}

#community .layout.double:not(.mixed-overlap).unequal-top-aligned .item {
  margin-top: 0;
}

#community .layout.double:not(.mixed-overlap).unequal-bottom-aligned .item {
  margin-bottom: 0;
}

#community .layout.double:not(.mixed-overlap).portrait-dip .item {
  margin-top: 0;
}

#community .layout.double:not(.mixed-overlap).portrait-dip .cell:nth-child(2) .item {
  margin-top: 30vh;
}

#community .layout.double:not(.mixed-overlap).portrait-push {
  min-height: 75svh;
}

#community .layout.double:not(.mixed-overlap).portrait-push .item {
  margin-bottom: 0;
}

#community .layout.double:not(.mixed-overlap).portrait-push .cell:nth-child(1) .item {
  margin-bottom: 30vh;
}

#community .layout.double:not(.mixed-overlap).center-aligned {
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

#community .layout.double:not(.mixed-overlap).center-aligned .cell {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

#community .layout.double:not(.mixed-overlap).center-aligned .cell:nth-child(2) {
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

#community .layout.double:not(.mixed-overlap).center-aligned .cell:nth-child(2) .item {
  margin-bottom: 0;
}

#community .layout.double:not(.mixed-overlap).center-aligned .cell .item {
  margin: 0;
  width: 50%;
}

#community .layout.double:not(.mixed-overlap).center-aligned .cell:nth-child(1) .item {
  margin-bottom: calc(var(--caption-height) * -1);
}

#community .layout.double:not(.mixed-overlap).center-aligned.reverse .cell:nth-child(1) .item {
  margin-bottom: 0;
}

#community .layout.double:not(.mixed-overlap).center-aligned.reverse .cell:nth-child(2) .item {
  margin-bottom: calc(var(--caption-height) * -1);
}

#community .layout.graphicdesign {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  justify-items: stretch;
  height: var(--layout-height);
  width: 100%;
  padding: var(--spacing-1) 0;
  position: relative;
  z-index: 108;
}

#community .layout.graphicdesign.crease-loaded {
  background: var(--background-force-dark);
}

#community .layout.graphicdesign.default .cell .item {
  width: 75%;
}

#community .layout.graphicdesign.default .cell .item .caption {
  margin-left: -16.6665%;
}

#community .layout.graphicdesign.uneven.reverse .cell:nth-child(2) .item {
  width: 75%;
}

#community .layout.graphicdesign.uneven.reverse .cell:nth-child(2) .item .caption {
  margin-left: -16.6665%;
}

#community .layout.graphicdesign.uneven.normal .cell:nth-child(1) .item {
  width: 75%;
}

#community .layout.graphicdesign.uneven.normal .cell:nth-child(1) .item .caption {
  margin-left: -16.6665%;
}

#community .layout.graphicdesign .cell {
  width: auto;
  flex-grow: 1;
  mix-blend-mode: lighten;
  margin: 0 var(--spacing-1);
  position: relative;
}

#community .layout.graphicdesign .item {
  position: absolute;
  inset: 0 0 0 0;
  display: flex;
  flex-direction: column;
}

#community .layout.graphicdesign .item .caption {
  top: auto;
  bottom: var(--spacing-1);
  margin: auto 0 0 0;
  height: auto;
  padding: 0;
}

#community .layout.graphicdesign .item a.image-link {
  width: min((var(--layout-height) + -2 * var(--caption-height)) * var(--aspect-ratio), 100%);
  aspect-ratio: var(--aspect-ratio);
  height: auto;
  margin: auto;
  position: absolute;
  inset: 0;
}

#community .layout.graphicdesign .item img {
  object-fit: contain;
  position: absolute;
  margin: auto;
  inset: -5svh;
  width: 100%;
  height: 100%;
}

#community .layout.spread {
  min-height: auto;
}

#community .layout.spread .cell {
  width: 100%;
  flex-grow: 1;
}

#community .layout.shop {
  --shop-caption-height: calc( var(--caption-height ) + 16px );
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: var(--spacing-1);
}

#community .layout.shop .cell {
  --caption-height: var(--shop-caption-height);
  position: relative;
  display: flex;
}

#community .layout.shop .cell:nth-child(1) {
  width: 50%;
  margin-right: auto;
  margin-bottom: calc(var(--caption-height) * -1);
}

#community .layout.shop .cell:nth-child(2) {
  width: 25%;
  margin-left: auto;
  margin-bottom: calc(var(--caption-height) * -1);
}

#community .layout.shop .cell:nth-child(2) .caption {
  padding-left: 0px;
}

#community .layout.shop .cell:nth-child(3) {
  width: 100%;
  justify-content: flex-end;
}

#community .layout.shop .cell:nth-child(3) .item {
  width: 37.5%;
  margin: auto 0 auto auto;
}

#community .layout.shop .cell:nth-child(3) .caption {
  padding-left: 0px;
}

#community .layout.shop.variation.reverse.right-align .cell:nth-child(1) {
  margin-right: 0;
  margin-left: auto;
}

#community .layout.shop.variation.reverse.right-align .cell:nth-child(2) {
  margin-left: 0;
}

#community .layout.shop.variation.reverse.left-align .cell:nth-child(1) {
  margin-right: auto;
  margin-left: 0;
}

#community .layout.shop.variation.reverse.left-align .cell:nth-child(2) {
  margin-left: auto;
  margin-right: 0;
}

#community .layout.shop.variation.left-align .cell:nth-child(1) {
  margin-right: 0;
}

#community .layout.shop.variation.left-align .cell:nth-child(2) {
  margin-left: 0;
  margin-right: auto;
  padding-left: var(--spacing-1);
}

#community .layout.shop.reverse .cell:nth-child(1) {
  width: 25%;
  margin-bottom: calc(var(--caption-height) * -1);
}

#community .layout.shop.reverse .cell:nth-child(2) {
  width: 50%;
  margin-bottom: calc(var(--caption-height) * -1);
}

#community .layout.shop.reverse .cell:nth-child(2) .caption {
  padding-left: var(--spacing-1);
}

#community .layout.shop.reverse .cell:nth-child(3) {
  width: 100%;
  justify-content: flex-start;
}

#community .layout.shop.reverse .cell:nth-child(3) .item {
  margin: auto auto auto 0;
}

#community .layout.shop.reverse .cell:nth-child(3) .item .caption {
  padding-left: var(--spacing-1);
}

#community .layout.spreadfit {
  min-height: auto;
  height: auto;
  flex-direction: row;
}

#community .layout.spreadfit.reverse {
  justify-content: flex-start;
}

#community .layout.spreadfit.reverse .cell, #community .layout.spreadfit.reverse .item, #community .layout.spreadfit.reverse a.image-link {
  margin: 0 auto 0 0;
}

#community .layout.spreadfit.normal {
  justify-content: flex-end;
}

#community .layout.spreadfit.normal .cell, #community .layout.spreadfit.normal .item, #community .layout.spreadfit.normal a.image-link {
  margin: 0 0 0 auto;
}

#community .layout.spreadfit.bigover .cell {
  width: 75%;
}

#community .layout.spreadfit.bigover.normal .cell .caption {
  margin-left: 33.33%;
  width: 50%;
}

#community .layout.spreadfit.over .cell {
  width: 66.66%;
}

#community .layout.spreadfit.over.normal .cell .caption {
  margin-left: 25%;
  width: 75%;
}

#community .layout.spreadfit.fitcolumn .cell, #community .layout.spreadfit.fitcolumn .item {
  width: 100%;
  display: flex;
  flex-direction: row;
}

#community .layout.spreadfit.fitcolumn .item .caption,
#community .layout.spreadfit.fitcolumn .item a.image-link {
  align-self: flex-end;
  width: 50%;
}

#community .layout.spreadfit.fitcolumn.reverse .item a.image-link {
  order: 1;
}

#community .layout.spreadfit.fitcolumn.reverse .item .caption {
  order: 2;
}

#community .layout.spreadfit.fitcolumn.normal .item a.image-link {
  order: 2;
}

#community .layout.spreadfit.fitcolumn.normal .item .caption {
  order: 1;
}

#community .cell:nth-child(1) .caption-transform {
  opacity: var(--caption-opacity-1);
  transform: translate3d(0px, calc(var(--caption-transform-1) * -1px), 0px);
}

#community .cell:nth-child(2) .caption-transform {
  opacity: var(--caption-opacity-2);
  transform: translate3d(0px, calc(var(--caption-transform-2) * -1px), 0px);
}

#community .cell:nth-child(3) .caption-transform {
  opacity: var(--caption-opacity-3);
  transform: translate3d(0px, calc(var(--caption-transform-3) * -1px), 0px);
}

#community .cell:nth-child(4) .caption-transform {
  opacity: var(--caption-opacity-4);
  transform: translate3d(0px, calc(var(--caption-transform-4) * -1px), 0px);
}

#community .item {
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

#community .item a.image-link {
  display: block;
  width: 100%;
}

#community .item a.image-link img {
  display: block;
  width: 100%;
}

#community .item .caption-transform {
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
}

#community .item .caption {
  padding: var(--spacing-half) var(--spacing-1) var(--spacing-1) var(--spacing-1);
  line-height: 15px;
  mix-blend-mode: exclusion;
  position: sticky;
  bottom: 0;
  margin: 0;
  height: var(--caption-height);
  z-index: 108;
  font-size: 12.5px;
}

#community .item .caption.single-line {
  bottom: -1lh;
}

#community .item .caption.animating {
  will-change: transform, opacity;
}

#community .item .caption a {
  color: rgba(var(--baseColor-default-rgb), 0.4);
  fill: rgba(var(--baseColor-default-rgb), 0.4);
}

#community .item .caption a.site-link {
  color: rgba(var(--baseColor-default-rgb), 0.85);
  fill: rgba(var(--baseColor-default-rgb), 0.85);
}

#community .item .caption a:active {
  opacity: var(--opacity-downstate-default);
}

#community .crease {
  position: absolute;
  inset: 0;
  pointer-events: none;
  margin: 0 auto 0 auto;
  background-repeat: repeat-y;
  background-position: center;
  z-index: 105;
  background-color: white;
  width: 75px;
  background-size: 75px 1000px;
  z-index: 105;
  mix-blend-mode: multiply;
  opacity: 0.85;
}

#community .crease.layer-two {
  opacity: 0.185;
  background-color: white;
  mix-blend-mode: color-burn;
  z-index: 100;
}

#community .crease.layer-three {
  opacity: 0.25;
  mix-blend-mode: plus-lighter;
  background-color: black;
  z-index: 103;
}

#community .crease.layer-four {
  opacity: 0.2;
  z-index: 102;
  mix-blend-mode: color-dodge;
  background-color: black;
}

#community .crease-preload {
  position: absolute;
  visibility: hidden !important;
  z-index: -99;
}

#community.inuse .layout.inuse .caption {
  transform: translateY(0.25em);
}

#community.all .layout.inuse a.image-link, #community.inuse .layout.inuse a.image-link {
  transform: translateY(5px);
  opacity: 0;
}

#community.all .layout:not(.inuse), #community.inuse .layout:not(.inuse) {
  opacity: 0;
  transform: translateY(1.05em);
}

#community.all .layout .caption, #community.inuse .layout .caption {
  opacity: 0;
  transform: translateY(1.05em);
}

#community.animate-in.all .layout.inuse a.image-link, #community.animate-in.inuse .layout.inuse a.image-link {
  opacity: 0;
  transform: translateY(5px);
  animation: communityInuseSlideUp 0.35s ease-out forwards;
}

#community.animate-in.all .layout:not(.inuse), #community.animate-in.inuse .layout:not(.inuse) {
  opacity: 0;
  transform: translateY(1.05em);
  animation: communityNonInuseSlideUp 0.4s ease-out forwards;
}

#community.animate-in.all .layout .caption, #community.animate-in.inuse .layout .caption {
  opacity: 0;
  transform: translateY(1.05em);
  animation: communityCaptionSlideFadeIn 0.35s ease-out forwards;
}

#community.animate-in.inuse .layout.inuse .caption {
  transform: translateY(0.25em);
  animation: communityCaptionSmallSlideFadeIn 0.35s ease-out forwards;
}

#community.animation-ended.all .layout.inuse a.image-link, #community.animation-ended.inuse .layout.inuse a.image-link {
  opacity: 1;
  transform: translateY(0);
}

#community.animation-ended.all .layout:not(.inuse), #community.animation-ended.inuse .layout:not(.inuse) {
  opacity: 1;
  transform: translateY(0);
}

#community.animation-ended.all .layout .caption, #community.animation-ended.inuse .layout .caption {
  opacity: 1;
  transform: translateY(0);
}

#community.animation-ended.inuse .layout.inuse .caption {
  opacity: 1;
  transform: translateY(0);
}

#community.all .second-visible-in-page a.image-link {
  margin-bottom: calc(var(--spacing-half) * -1);
}

#community.all .second-visible-in-page .caption {
  margin-top: var(--spacing-half);
}

body.mobile[data-route=home] {
  background: var(--background-force-dark);
}

body.mobile[data-route=home] #community {
  position: absolute;
  top: calc(var(--min-viewport-height));
  margin: 0;
  left: 0;
}

body.mobile[data-route=home] #community .layout.first-visible-in-page .caption {
  position: relative;
}

body.mobile[data-route=community][data-subroute=all] #community .first-visible-in-page.inuse.layout {
  height: calc(var(--min-viewport-height) - var(--spacing-1));
}

body.mobile #community {
  --tall-height: calc( var(--min-viewport-height, 720px) * 1.05 );
}

body.mobile #community .layout:not(.category-view-only).spread, body.mobile #community .layout:not(.category-view-only).spreadfit {
  min-height: var(--tall-height);
}

body.mobile #community .layout:not(.category-view-only).inuse {
  --layout-height: var(--tall-height);
}

body.mobile #community .layout:not(.category-view-only).inuse:not(.first-visible-in-page) {
  min-height: var(--tall-height);
}

body.mobile #community .layout:not(.category-view-only).inuse a.image-link {
  padding-top: 25px;
  width: min((var(--layout-height) + -1 * var(--spacing-3) + -1 * var(--caption-height) - 35px) * var(--aspect-ratio), 100% - var(--spacing-1) * 2);
}

body.mobile #community .layout:not(.category-view-only).inuse a.image-link .browser-bar:after {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

body.mobile #community .layout:not(.category-view-only).inuse a.image-link:after {
  inset: 25px 0px 0px 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

body.mobile #community .layout:not(.category-view-only).shop-mobile {
  padding: var(--spacing-1) 0 0 0;
  min-height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

body.mobile #community .layout:not(.category-view-only).shop-mobile .caption {
  margin-bottom: var(--spacing-4);
}

body.mobile #community .layout:not(.category-view-only).shop-mobile .cell {
  margin: 0;
}

body.mobile #community .layout:not(.category-view-only).shop-mobile .cell:nth-child(1) {
  width: 75%;
}

body.mobile #community .layout:not(.category-view-only).shop-mobile .cell:nth-child(1) .item {
  display: flex;
}

body.mobile #community .layout:not(.category-view-only).shop-mobile .cell:nth-child(1) .item .caption {
  width: calc(50vw - var(--spacing-1) * 2);
}

body.mobile #community .layout:not(.category-view-only).shop-mobile .cell:nth-child(2) {
  width: 50%;
}

body.mobile #community .layout:not(.category-view-only).shop-mobile .cell:nth-child(3) {
  width: 50%;
}

body.mobile #community .layout:not(.category-view-only).shop-mobile .cell .image-link {
  mix-blend-mode: darken;
}

body.mobile #community .layout:not(.category-view-only).shop-mobile .cell .item {
  padding: 0 var(--spacing-1);
}

body.mobile #community .layout:not(.category-view-only).shop-mobile .cell .item .caption {
  padding-left: 0;
}

body.mobile #community .layout:not(.category-view-only).shop-mobile.normal {
  justify-content: flex-end;
}

body.mobile #community .layout:not(.category-view-only).shop-mobile.normal .cell:nth-child(1) .item {
  justify-content: flex-end;
}

body.mobile #community .layout:not(.category-view-only).shop-mobile.normal .cell:nth-child(1) .item .caption {
  margin-left: auto;
}

body.mobile #community .layout:not(.category-view-only).shop-mobile.reverse {
  justify-content: flex-start;
}

body.mobile #community .layout:not(.category-view-only).shop-mobile.reverse .cell:nth-child(1) .item {
  justify-content: flex-start;
}

body.mobile #community .layout:not(.category-view-only).shop-mobile.reverse .cell:nth-child(1) .item .caption {
  margin-right: auto;
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap {
  --layout-height: calc( var(--tall-height) + var(--caption-height) * -2 + var(--spacing-4) * -2 ) ;
  height: auto;
  min-height: var(--tall-height);
  display: flex;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap.first-visible-in-page {
  padding-top: 0;
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap.normal .cell:nth-child(1) .item {
  display: flex;
  justify-content: flex-start;
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap.normal .cell:nth-child(1) .item .caption {
  width: 50vw;
  margin-right: auto;
  padding-left: var(--spacing-1);
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap.normal .cell:nth-child(1) .item a.image-link {
  margin-right: auto;
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap.normal .cell:nth-child(2) .item {
  display: flex;
  justify-content: flex-end;
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap.normal .cell:nth-child(2) .item .caption {
  width: 50vw;
  margin-left: auto;
  padding-left: var(--spacing-1);
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap.normal .cell:nth-child(2) .item a.image-link {
  margin-left: auto;
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap.reverse .cell:nth-child(1) .item {
  display: flex;
  justify-content: flex-end;
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap.reverse .cell:nth-child(1) .item .caption {
  width: 50vw;
  margin-left: auto;
  padding-left: var(--spacing-1);
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap.reverse .cell:nth-child(1) .item a.image-link {
  margin-left: auto;
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap.reverse .cell:nth-child(2) .item {
  display: flex;
  justify-content: flex-start;
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap.reverse .cell:nth-child(2) .item .caption {
  width: 50vw;
  margin-right: auto;
  padding-left: var(--spacing-1);
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap.reverse .cell:nth-child(2) .item a.image-link {
  margin-right: auto;
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap.disregard-caption .cell:nth-child(1) {
  flex-basis: calc(var(--height-proportion, 0.5) * var(--layout-height));
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap.disregard-caption .cell:nth-child(1) .item {
  margin-bottom: calc(var(--caption-height) * -1);
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap.disregard-caption .cell:nth-child(1) .item .caption {
  margin-bottom: 0;
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap .cell {
  flex-basis: calc(var(--height-proportion, 0.5) * var(--layout-height) + var(--caption-height));
  flex-grow: 0;
  flex-shrink: 1;
  width: 100%;
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap .cell .item {
  width: 100%;
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap .cell .item .caption {
  margin-bottom: var(--spacing-4);
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap .cell .item a.image-link {
  height: auto;
  width: calc(var(--overlap-width) * 100%);
  aspect-ratio: var(--aspect-ratio);
}

body.mobile #community .layout:not(.category-view-only).mixed-overlap .cell .item a.image-link img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  aspect-ratio: var(--aspect-ratio);
}

body.mobile #community .layout:not(.category-view-only).graphicdesign {
  --layout-height: calc( var(--min-viewport-height) * .7875 );
}

body.mobile #community .layout:not(.category-view-only).graphicdesign.default .item {
  width: 100%;
}

body.mobile #community .layout:not(.category-view-only).graphicdesign.default .item .caption {
  margin-left: 0;
}

body.mobile #community .layout:not(.category-view-only).graphicdesign.quadruple {
  padding: 0;
  --layout-height: calc( var(--min-viewport-height) * 1.30 );
  flex-wrap: wrap;
}

body.mobile #community .layout:not(.category-view-only).graphicdesign.quadruple:after {
  content: "";
  display: block;
  position: absolute;
  top: calc(var(--layout-height) / 2);
  width: 100%;
  border: 1px solid #313131;
  pointer-events: none;
  z-index: 99;
}

body.mobile #community .layout:not(.category-view-only).graphicdesign.quadruple .cell {
  margin-top: var(--spacing-1);
  margin-bottom: var(--spacing-1);
  flex-grow: 0;
  width: calc(50% - var(--spacing-1) * 1.5);
  height: calc(var(--layout-height) / 2 - var(--spacing-1) * 2);
}

body.mobile #community .layout:not(.category-view-only).graphicdesign.quadruple .cell:nth-child(1), body.mobile #community .layout:not(.category-view-only).graphicdesign.quadruple .cell:nth-child(3) {
  margin-right: auto;
}

body.mobile #community .layout:not(.category-view-only).graphicdesign.quadruple .cell:nth-child(2), body.mobile #community .layout:not(.category-view-only).graphicdesign.quadruple .cell:nth-child(4) {
  margin-left: auto;
}

body.mobile #community .layout:not(.category-view-only).graphicdesign.wide .cell:nth-child(1) {
  margin-right: 0;
}

body.mobile #community .layout:not(.category-view-only).graphicdesign.wide .cell:nth-child(2) {
  margin-left: 0;
}

body.mobile #community .layout:not(.category-view-only).graphicdesign.wide .cell:nth-child(2) .caption {
  margin-left: var(--spacing-1);
}

body.mobile #community .layout:not(.category-view-only).graphicdesign.uneven.normal .cell:nth-child(1) .item {
  width: 100%;
}

body.mobile #community .layout:not(.category-view-only).graphicdesign.uneven.normal .cell:nth-child(1) .item .caption {
  margin-left: 0;
}

body.mobile #community .layout:not(.category-view-only).graphicdesign.uneven.reverse .cell:nth-child(2) .item {
  width: 100%;
}

body.mobile #community .layout:not(.category-view-only).graphicdesign.uneven.reverse .cell:nth-child(2) .item .caption {
  margin-left: 0;
}

body.mobile #community .crease {
  width: 45px;
  background-size: 45px 1000px;
}

@keyframes communityInuseSlideUp {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes communityNonInuseSlideUp {
  from {
    opacity: 0;
    transform: translateY(1.05em);
  }
  to {
    opacity: 1;
    transform: translateY(0em);
  }
}
@keyframes communityCaptionSmallSlideFadeIn {
  from {
    opacity: 0;
    transform: translateY(0.25em);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes communityCaptionSlideFadeIn {
  from {
    opacity: 0;
    transform: translateY(1.05em);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Base Color and Background Mixins */
body.authenticated #templates .row-page .section-header:after {
  display: none;
}

body.unauthenticated.mobile #templates .row-page .section-header:after {
  display: none;
}

#templates .row-page {
  padding-top: 0;
  min-width: 100%;
}

#templates .row-page .section-header {
  padding-top: var(--sections-topAlignment);
  margin-bottom: calc(var(--spacing-1) * -1);
  display: grid;
  grid-column: 1/-1;
  position: relative;
  line-height: 1;
}

#templates .row-page .section-header:after {
  top: var(--sections-topAlignment);
  right: calc(var(--spacing-1) - var(--sites-padding));
  left: calc(var(--spacing-1) - var(--sites-padding));
}

#templates .row-page .section-header .title-nav {
  display: inline-flex;
  justify-content: space-between;
  margin: calc(-1 * var(--row-rule-offset)) calc(var(--spacing-1) - var(--sites-padding));
  margin-bottom: 0;
}

#templates .row-page .section-header .title-nav .header-row {
  padding: 0 !important;
}

#templates .row-page .section-header .title-nav .back {
  display: inline-block;
  cursor: pointer;
}

#templates .row-page .section-header .title-nav .back:active {
  opacity: var(--opacity-downstate-default);
}

#templates .row-page .section-header .title-nav .right-arrow {
  display: none;
}

#templates .row-page .section-header .title-nav .see-all {
  display: none;
}

#templates .row-page > *:last-child {
  margin-bottom: var(--height-row);
}

body[data-route=templates] {
  --lineHeight-templates: 16px;
  --siteDetails-height: var(--lineHeight-templates);
  background: var(--background-3);
}

body[data-route=templates].authenticated {
  height: auto;
  overflow: hidden;
}

body[data-route=templates].authenticated #manager {
  padding-bottom: 0;
}

body[data-route=templates].authenticated #templates {
  --scrollSnap-alignment: var(--sites-padding);
  --sections-topAlignment: 0;
  margin-top: 0;
  overflow: auto;
  grid-column: 2/-1;
  grid-row: 1/-1;
  margin-left: calc((var(--leftMenuBar-width) + var(--spacing-1)) * 0.333 * -1 - 1px);
}

body[data-route=templates].authenticated #templates .see-all {
  display: inline-flex;
}

body[data-route=templates].authenticated #templates .sections {
  height: calc(var(--viewport-height) - var(--height-row));
}

body[data-route=templates].authenticated #templates .sections .section:first-of-type .section-header:after {
  display: none;
}

body[data-route=templates].authenticated #templates .sections .section .row .site-scroller {
  padding-right: calc(var(--viewport-width) - var(--site-width) - var(--siteSpacer-width) - var(--siteSpacer-width) + 1px + 270px); /* temporary fix after changing padding-left */
  padding-left: calc(var(--leftMenuBar-width) * 0.333 + var(--spacing-1) + 5px); /* 105px indent*/
}

body[data-route=templates].authenticated #templates .sections .section .section-header .title-nav .name {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
}

body[data-route=templates].authenticated.mobile #templates {
  margin-left: calc(-1 * var(--spacing-1));
}

body[data-route=templates].authenticated.mobile #templates .section-header::after {
  content: "";
  left: 0px;
}

body[data-route=templates].unauthenticated {
  --siteDetails-height: 42px;
  height: auto;
}

body[data-route=templates].unauthenticated #templates {
  --siteScroller-padding: 12px;
  --sections-topAlignment: var(--height-row);
  --scrollSnap-alignment: var(--spacing-1);
  grid-column: 1/-1 !important;
  margin-left: calc(-1 * var(--spacing-1));
  margin-top: calc(-1 * var(--spacing-1));
  min-height: var(--viewport-height);
  padding-bottom: 10em;
}

body[data-route=templates].unauthenticated #templates .see-all {
  display: none;
}

body[data-route=templates].unauthenticated #templates .sections .section .row .site-scroller {
  padding-right: calc(var(--viewport-width) - var(--site-width) - var(--siteSpacer-width));
  padding-left: var(--siteSpacer-width); /* override authenticated ruling */
}

body[data-route=templates].unauthenticated #templates #siteslist.row-page {
  height: auto;
}

body[data-route=templates].unauthenticated #templates .templates-message {
  line-height: var(--lineHeight-templates);
  top: var(--height-row);
}

body[data-route=templates].unauthenticated #templates .start .section-header::after {
  content: "";
  left: calc(var(--spacing-1));
}

body[data-route=templates].unauthenticated.mobile {
  --grid-leftPanel-width: var(--spacing-1) !important;
  margin: 0;
  padding: 0;
}

body[data-route=templates].unauthenticated.mobile #templates {
  --siteScroller-padding: var(--spacing-1);
}

#templates {
  --description-color: #666;
  --dl-hint-bg: #E8F5F9;
  --site-height: calc(var(--site-width) * .625);
  --siteSpacer-width: calc(var(--leftMenuBar-width) + var(--spacing-1));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: calc(-1 * var(--spacing-1));
}

#templates .sections {
  --sectionHeader-height: 65px;
  width: 100%;
  position: relative;
  padding-top: var(--sections-topAlignment);
  overscroll-behavior-y: contain;
  scroll-snap-type: y mandatory;
  scroll-padding-inline-start: 0;
}

#templates .sections .section {
  height: calc(var(--site-height) + 12rem);
  position: relative;
  scroll-snap-align: start;
}

#templates .sections .section .positioner {
  height: inherit;
}

#templates .sections .section .section-header {
  position: relative;
  line-height: 1;
  margin: 0;
  z-index: 2;
  height: var(--sectionHeader-height);
  padding-top: calc(-1 * var(--row-rule-offset));
  pointer-events: none;
}

#templates .sections .section .section-header a {
  pointer-events: auto;
}

#templates .sections .section .section-header .header-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 0 var(--spacing-1);
}

#templates .sections .section .section-header .title-nav {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 0 0;
}

#templates .sections .section .section-header .title-nav .row {
  display: inline-flex;
  align-items: flex-start;
  justify-content: space-between;
}

#templates .sections .section .section-header .title-nav .name {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.75);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.75);
}

#templates .sections .section .section-header .title-nav .name:active {
  opacity: var(--opacity-downstate-default);
}

#templates .sections .section .section-header .title-nav .name .right-arrow {
  display: none;
  float: right;
  transform: translate(0, 1px);
}

#templates .sections .section .section-header .title-nav .back {
  display: none;
}

#templates .sections .section .section-header .title-nav .right-arrow {
  display: none;
}

#templates .sections .section .row {
  padding-top: var(--sectionHeader-height);
  margin-top: calc(var(--sectionHeader-height) * -1);
}

#templates .sections .section .row .site-scroller {
  margin-top: calc(var(--sectionHeader-height) * -1);
  padding-top: var(--sectionHeader-height);
  padding-bottom: 0;
}

#templates .sections .section .row .site-scroller.will-snap {
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scroll-padding-inline-start: calc(var(--scrollSnap-alignment) - 1px);
}

#templates .sections .section .row .site-scroller.will-snap.transition {
  scroll-snap-type: none;
}

#templates .sections .section .row .site-scroller.will-snap .site {
  scroll-snap-align: start;
}

#templates .sections .section .row .site-scroller.will-snap .site a {
  transition: transform 0.2s ease-out;
}

#templates .section-header::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: var(--element-gap);
  height: 1px;
  background: rgba(var(--baseColor-default-reverse-rgb), 0.15);
  z-index: 0;
}

#templates .templates-message {
  --message-color: var(--color-default);
  --message-background: #ff0;
  position: relative;
  width: fit-content;
  padding-left: var(--spacing-1);
  padding-top: calc(-1 * var(--row-rule-offset));
  margin-bottom: var(--spacing-4);
}

#templates .templates-message span {
  color: var(--message-color);
  background: var(--message-background);
}

#templates.animated .section {
  --site-initial: 3px;
  --text-initial: 0.5px;
  --animation-duration: 0.35s;
  --site-animation-delay-multiplier: 0.022s;
  --row-delay-multiplier: 0.044s;
  --template-move-in-easing: ease-out;
}

@keyframes fadeInSite {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes moveLeftSite {
  from {
    transform: translateX(var(--site-initial));
  }
  to {
    transform: translateX(0px);
  }
}
@keyframes moveLeftText {
  from {
    transform: translateX(var(--text-initial));
  }
  to {
    transform: translateX(0px);
  }
}
#templates.animated .section:nth-of-type(1) .title-nav {
  animation: moveLeftText var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(0 * var(--row-delay-multiplier));
  opacity: 0;
  transform: translateX(var(--text-initial));
}

#templates.animated .section:nth-of-type(1) .site:nth-of-type(-n+6) {
  opacity: 0;
  transform: translateX(var(--site-initial));
}

#templates.animated .section:nth-of-type(1) .site:nth-of-type(1) {
  animation: moveLeftSite var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(0 * var(--row-delay-multiplier) + 0 * var(--site-animation-delay-multiplier));
}

#templates.animated .section:nth-of-type(1) .site:nth-of-type(2) {
  animation: moveLeftSite var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(0 * var(--row-delay-multiplier) + 1 * var(--site-animation-delay-multiplier));
}

#templates.animated .section:nth-of-type(1) .site:nth-of-type(3) {
  animation: moveLeftSite var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(0 * var(--row-delay-multiplier) + 2 * var(--site-animation-delay-multiplier));
}

#templates.animated .section:nth-of-type(1) .site:nth-of-type(4) {
  animation: moveLeftSite var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(0 * var(--row-delay-multiplier) + 3 * var(--site-animation-delay-multiplier));
}

#templates.animated .section:nth-of-type(1) .site:nth-of-type(5) {
  animation: moveLeftSite var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(0 * var(--row-delay-multiplier) + 4 * var(--site-animation-delay-multiplier));
}

#templates.animated .section:nth-of-type(1) .site:nth-of-type(6) {
  animation: moveLeftSite var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(0 * var(--row-delay-multiplier) + 5 * var(--site-animation-delay-multiplier));
}

#templates.animated .section:nth-of-type(2) .title-nav {
  animation: moveLeftText var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(1 * var(--row-delay-multiplier));
  opacity: 0;
  transform: translateX(var(--text-initial));
}

#templates.animated .section:nth-of-type(2) .site:nth-of-type(-n+6) {
  opacity: 0;
  transform: translateX(var(--site-initial));
}

#templates.animated .section:nth-of-type(2) .site:nth-of-type(1) {
  animation: moveLeftSite var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(1 * var(--row-delay-multiplier) + 0 * var(--site-animation-delay-multiplier));
}

#templates.animated .section:nth-of-type(2) .site:nth-of-type(2) {
  animation: moveLeftSite var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(1 * var(--row-delay-multiplier) + 1 * var(--site-animation-delay-multiplier));
}

#templates.animated .section:nth-of-type(2) .site:nth-of-type(3) {
  animation: moveLeftSite var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(1 * var(--row-delay-multiplier) + 2 * var(--site-animation-delay-multiplier));
}

#templates.animated .section:nth-of-type(2) .site:nth-of-type(4) {
  animation: moveLeftSite var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(1 * var(--row-delay-multiplier) + 3 * var(--site-animation-delay-multiplier));
}

#templates.animated .section:nth-of-type(2) .site:nth-of-type(5) {
  animation: moveLeftSite var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(1 * var(--row-delay-multiplier) + 4 * var(--site-animation-delay-multiplier));
}

#templates.animated .section:nth-of-type(2) .site:nth-of-type(6) {
  animation: moveLeftSite var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(1 * var(--row-delay-multiplier) + 5 * var(--site-animation-delay-multiplier));
}

#templates.animated .section:nth-of-type(3) .title-nav {
  animation: moveLeftText var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(2 * var(--row-delay-multiplier));
  opacity: 0;
  transform: translateX(var(--text-initial));
}

#templates.animated .section:nth-of-type(3) .site:nth-of-type(-n+6) {
  opacity: 0;
  transform: translateX(var(--site-initial));
}

#templates.animated .section:nth-of-type(3) .site:nth-of-type(1) {
  animation: moveLeftSite var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(2 * var(--row-delay-multiplier) + 0 * var(--site-animation-delay-multiplier));
}

#templates.animated .section:nth-of-type(3) .site:nth-of-type(2) {
  animation: moveLeftSite var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(2 * var(--row-delay-multiplier) + 1 * var(--site-animation-delay-multiplier));
}

#templates.animated .section:nth-of-type(3) .site:nth-of-type(3) {
  animation: moveLeftSite var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(2 * var(--row-delay-multiplier) + 2 * var(--site-animation-delay-multiplier));
}

#templates.animated .section:nth-of-type(3) .site:nth-of-type(4) {
  animation: moveLeftSite var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(2 * var(--row-delay-multiplier) + 3 * var(--site-animation-delay-multiplier));
}

#templates.animated .section:nth-of-type(3) .site:nth-of-type(5) {
  animation: moveLeftSite var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(2 * var(--row-delay-multiplier) + 4 * var(--site-animation-delay-multiplier));
}

#templates.animated .section:nth-of-type(3) .site:nth-of-type(6) {
  animation: moveLeftSite var(--animation-duration) forwards var(--template-move-in-easing), fadeInSite var(--animation-duration) forwards var(--template-move-in-easing) calc(2 * var(--row-delay-multiplier) + 5 * var(--site-animation-delay-multiplier));
}

body:not(.mobile).unauthenticated #templates .templates-message::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: calc(var(--spacing-1) * -1);
  height: var(--element-gap);
  background: rgba(var(--baseColor-default-reverse-rgb), 0.15);
  z-index: var(--layer-2);
  width: var(--viewport-width);
}

body.mobile #templates {
  --siteSpacer-width: var(--spacing-1);
}

body.mobile #templates .templates-message {
  --message-color: var(--color-default);
  --message-background: transparent;
}

body.mobile #templates .sections .section {
  height: calc(var(--site-height) + 65px + 105px); /* more spacious rows*/
}

body.mobile #templates .sections .section .row .site-scroller {
  padding-left: var(--spacing-1);
  padding-right: calc(var(--viewport-width) - var(--site-width) - var(--siteSpacer-width) - var(--spacing-1)) !important; /* preserve peaking on final sites */
}

body.mobile.dark #templates .templates-message {
  --message-color: var(--color-default);
  --message-background: transparent;
}

body.mobile.authenticated #templates .sections .section:first-of-type .section-header:after {
  display: block;
}

body.mobile.authenticated #templates .sections .section .row .site-scroller {
  padding-left: var(--spacing-1);
}

body.dark #templates {
  /* give sites heavier drop shadow in dark mode */
}

body.dark #templates .templates-message {
  --message-color: var(--baseColor-accent);
  --message-background: transparent;
}

body.dark #templates .site a {
  transition: filter 0.2s ease-out;
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0));
}

body.dark #templates .site:hover a {
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.8));
}

/* Base Color and Background Mixins */
/* Base Color and Background Mixins */
div[static-page=information] .inactive {
  display: none;
}

div[static-page=information] .inactive svg path {
  color: rgba(var(--baseColor-default-reverse-rgb), 1);
  fill: rgba(var(--baseColor-default-reverse-rgb), 1);
}

div[static-page=information] .interface {
  margin-top: -4rem;
}

div[static-page=information] .explanation {
  grid-column: 2/2 span;
}

div[static-page=information] .prices {
  --left-subcell-span: 3;
  --right-subcell: 7;
  max-width: 25ch;
  margin-bottom: calc(var(--height-row) + var(--spacing-1));
}

div[static-page=information] .price {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

div[static-page=information] .price-per-month {
  text-wrap: nowrap;
}

div[static-page=information] .tally {
  display: flex;
  position: relative;
  justify-content: space-between;
}

div[static-page=information] .tally::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  height: 1px;
  background: rgba(var(--baseColor-default-reverse-rgb), 1);
}

div[static-page=information] label {
  display: block;
  width: fit-content;
  cursor: pointer;
}

div[static-page=information] label:active {
  opacity: var(--opacity-downstate-default);
}

div[static-page=information] label:active.active {
  opacity: 1;
}

div[static-page=information] label input[type=radio] {
  width: unset;
  height: unset;
  font-size: 12px;
  margin: 0 10px -1.5px 0;
  border-radius: 0;
  appearance: none; /* Hide the default radio button */
  -moz-appearance: none;
  -webkit-appearance: none;
  padding: 0.5em;
  background-color: transparent;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='9' height='9' stroke='black' stroke-opacity='0.85'/%3E%3C/svg%3E");
}

div[static-page=information] label input[type=radio]:checked {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='9' height='9' stroke='black' stroke-opacity='0.85'/%3E%3Crect x='2' y='2' width='6' height='6' fill='black' fill-opacity='0.85'/%3E%3Crect x='2.5' y='2.5' width='5' height='5' stroke='white' stroke-opacity='0.85'/%3E%3C/svg%3E");
}

body.dark div[static-page=information] label input[type=radio] {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='9' height='9' stroke='white' stroke-opacity='0.85'/%3E%3C/svg%3E");
}

body.dark div[static-page=information] label input[type=radio]:checked {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='9' height='9' stroke='white' stroke-opacity='0.85'/%3E%3Crect x='2' y='2' width='6' height='6' fill='white' fill-opacity='0.85'/%3E%3Crect x='2.5' y='2.5' width='5' height='5' stroke='black' stroke-opacity='0.85'/%3E%3C/svg%3E");
}

body.mobile div[static-page=information] .prices {
  max-width: 100%;
}

body.authenticated[cargo-information] #manager {
  --grid-template-columns: repeat(4, 1fr) repeat(4, 1fr) ;
}

body.authenticated [static-page] {
  --grid-height-sans-pad: calc(var(--viewport-height) - var(--height-row));
  left: calc(var(--spacing-1) * -1);
}

body.authenticated [static-page] #intercom-launcher {
  pointer-events: all;
}

[static-page] {
  --lineHeight: 1.15;
  --info-color-main: rgba(var(--baseColor-default-reverse-rgb), .85);
  --info-color-secondary: rgba(var(--baseColor-default-reverse-rgb), .4);
  --rule-color: rgba(var(--baseColor-default-reverse-rgb), .15);
  font-family: var(--fontFamily-CargoDiatype);
  font-weight: var(--fontWeight-CargoDiatype);
  letter-spacing: 0;
  font-style: normal;
  display: grid;
  grid-template-columns: var(--grid-template-columns);
  grid-column-gap: var(--grid-column-gap);
  font-size: var(--fontSize-default);
  grid-column: 1/-1;
  min-height: var(--grid-height-sans-pad);
  color: var(--info-color-main);
  font-style: normal;
  line-height: var(--lineHeight-CargoDiatype);
  width: var(--viewport-width);
  position: absolute;
  overflow-x: hidden;
  z-index: var(--layer-3);
  pointer-events: all;
}

[static-page] .page-scroll {
  --column-span: 4;
  --left-subcell-span: 3;
  --right-subcell: 2;
  --fullRow: var(--grid-subcell-height);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: var(--grid-column-gap);
  grid-template-columns: repeat(4, 1fr);
  grid-column: var(--grid-line-golden)/-1;
  color: var(--info-color-main);
  line-height: var(--lineHeight);
  align-self: start;
  padding-right: var(--spacing-1);
  overflow-x: hidden;
  z-index: 3;
  position: relative;
}

[static-page] .page-scroll::before {
  content: "";
  height: 1px;
  background: var(--background-3);
  position: fixed;
  top: 0;
  left: calc((var(--viewport-width) + var(--grid-column-gap)) * 0.5);
  right: var(--spacing-1);
  height: 40px;
  box-shadow: 0 1px 0 var(--rule-color);
  z-index: var(--layer-1);
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}

[static-page] .page-scroll .page {
  grid-column: 1/-1;
  width: 100%;
  padding-right: var(--spacing-1);
  padding-bottom: var(--grid-cell-height);
  padding-left: calc(var(--grid-subcell-width) * 1 + var(--grid-subcolumn-gap) * 1);
}

[static-page] .page-scroll .page.short {
  padding-bottom: var(--spacing-2);
}

[static-page] .page-scroll .static-page-navigation {
  grid-column: 1/span 1;
  position: fixed;
  top: var(--height-row);
  bottom: 0;
  padding-bottom: var(--spacing-1);
  color: var(--info-color-secondary);
  margin-top: calc(-1 * var(--row-rule-offset));
  row-gap: calc(var(--fontSize-default) * var(--lineHeight));
  flex-direction: column;
  display: flex;
  min-width: var(--grid-cell-width);
  z-index: var(--layer-2);
}

[static-page] .page-scroll .static-page-navigation > span {
  display: block;
}

[static-page] .page-scroll .static-page-navigation a {
  text-decoration: none;
}

[static-page] .page-scroll .static-page-navigation a[target=_blank]:hover::after {
  content: "↗";
  display: inline;
  padding-left: 0.2em;
}

[static-page] .page-scroll .static-page-navigation .button-link,
[static-page] .page-scroll .static-page-navigation button {
  padding: 0;
  color: var(--info-color-secondary);
  font-family: var(--fontFamily-CargoDiatype);
  font-weight: var(--fontWeight-CargoDiatype);
  letter-spacing: 0;
  font-style: normal;
  display: inline-block;
  line-height: inherit;
  width: fit-content;
}

[static-page] .page-scroll .static-page-navigation .button-link:active,
[static-page] .page-scroll .static-page-navigation button:active {
  opacity: var(--opacity-downstate-default);
}

[static-page] .page-scroll .static-page-navigation .button-link.active,
[static-page] .page-scroll .static-page-navigation button.active {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
}

[static-page] .page-scroll .static-page-navigation .contact:active,
[static-page] .page-scroll .static-page-navigation .button-link.external:active {
  opacity: var(--opacity-downstate-default);
}

[static-page] .page-scroll .static-page-navigation .contact:active button,
[static-page] .page-scroll .static-page-navigation .button-link.external:active button {
  opacity: 1;
}

[static-page] .page-scroll .static-page-navigation .first {
  grid-column: 4/span 3;
}

[static-page] .page-scroll .static-page-navigation .second {
  grid-column: span 3;
}

[static-page] .page-scroll .static-page-navigation .dropdown button.open {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.85);
}

[static-page] .page-scroll .static-page-navigation .dropdown .dropdown-items {
  display: flex;
  flex-direction: column;
  margin-left: var(--spacing-1);
}

[static-page] .page-scroll .page-window {
  grid-column: 2/span 3;
  margin-top: var(--height-row);
  margin-right: calc(-1 * var(--spacing-1));
}

[static-page] .page-scroll .page-window .page .bodycopy {
  grid-column: 1/-1;
}

[static-page] .page-scroll .page-window .page .button-link {
  display: inline;
  white-space: pre-wrap;
}

[static-page] .page-scroll .page-window .page .row {
  margin-bottom: calc(var(--fontSize-default) * var(--lineHeight) * 6 + var(--row-rule-offset));
}

[static-page] .page-scroll .page-window .page .row:first-child {
  margin-top: calc(-1 * var(--row-rule-offset));
}

[static-page] .page-scroll .page-window .page .row:first-child:before {
  display: none;
}

[static-page] .page-scroll .page-window .page .row:last-child {
  margin-bottom: 0;
}

[static-page] .page-scroll .page-window .page .row.hide-margin {
  margin-bottom: calc(var(--lineHeight) * var(--fontSize-default));
}

[static-page] .page-scroll .page-window .page .row.hide-margin .right::before {
  display: none;
}

[static-page] .page-scroll .page-window .page .row.hide-hr:before {
  display: none;
}

[static-page] .page-scroll .row {
  position: relative;
}

[static-page] .page-scroll .row::before {
  content: "";
  position: absolute;
  top: var(--row-rule-offset);
  left: 0;
  right: 0;
  height: 1px;
  background: var(--rule-color);
}

[static-page] .page-scroll .row.fill {
  grid-row: span 3;
}

[static-page] .page-scroll .row.fill:before {
  display: none;
}

[static-page] .page-scroll .row .left {
  padding-right: var(--spacing-1);
  grid-column: 1/span var(--left-subcell-span);
  position: relative;
}

[static-page] .page-scroll .row .right {
  grid-column: var(--right-subcell)/-1;
  position: relative;
}

[static-page] .page-scroll .index-item {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--grid-subcolumn-gap);
  align-items: start;
}

[static-page] .page-scroll .index-number {
  width: var(--grid-subcell-width);
}

[static-page] .page-scroll .section-header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
  margin-bottom: calc(var(--lineHeight) * var(--fontSize-default));
}

[static-page] .page-scroll .fixed-header {
  color: var(--info-color-main);
  position: fixed;
  top: 0;
  line-height: var(--height-row);
  z-index: var(--layer-2);
}

[static-page] .page-scroll .button-close {
  position: fixed;
  top: var(--spacing-1);
  right: var(--spacing-1);
  z-index: var(--layer-2);
}

[static-page] .page-scroll .button-close button {
  --close-button-size: 12px;
  display: flex;
  pointer-events: all;
  height: var(--close-button-size);
  width: var(--close-button-size);
}

[static-page] .page-scroll .button-close button svg {
  height: var(--close-button-size);
  width: var(--close-button-size);
}

[static-page] .page-scroll .button-close button:active {
  opacity: var(--opacity-downstate-default);
}

[static-page] .title {
  display: grid;
  grid-area: 2/1/-1/var(--grid-line-golden);
  z-index: 2;
}

[static-page] a {
  color: inherit;
  text-decoration: underline;
}

[static-page] ul, [static-page] ol {
  padding: 0 0 0 1em;
}

[static-page] ul ul, [static-page] ul ol, [static-page] ol ul, [static-page] ol ol {
  padding: 0 0 0 var(--grid-subcolumn-gap);
}

[static-page] ul li, [static-page] ol li {
  white-space: break-spaces;
}

[static-page] ul li {
  list-style-type: disc;
  margin-left: 0.15em;
}

[static-page] ul ul li {
  list-style-type: circle;
}

[static-page] ul[type=s] li {
  list-style-type: square;
}

[static-page] ol li {
  margin-left: var(--grid-subcolumn-gap);
  list-style-type: decimal;
}

[static-page] ol[type=a] li {
  list-style-type: lower-alph a;
}

[static-page] ol[type=i] li {
  list-style-type: lower-roman;
}

[static-page] h1 {
  font-style: normal;
  padding: 0;
  margin: 0;
  color: var(--info-color-main);
}

[static-page] h1 a {
  color: var(--info-color-main);
}

[static-page] h2 {
  font-style: normal;
  padding: 0;
  margin: 0;
  color: var(--info-color-main);
}

[static-page] h2 a {
  color: var(--info-color-main);
}

[static-page] .background {
  position: fixed;
  inset: 0;
  margin: 0;
  z-index: 1;
  background: var(--background-3);
  grid-area: var(--grid-area-golden);
  grid-column: 4/-1;
  margin: calc(-1 * var(--spacing-1));
  pointer-events: none;
}

[static-page] .super {
  grid-column: 2/span 1;
  grid-row: 2/span 1;
}

[static-page] .super button,
[static-page] .super a {
  pointer: cursor;
  height: var(--grid-subcell-height);
  width: auto;
  cursor: pointer;
  pointer-events: auto;
  grid-row: 1/span 1;
  grid-row: 1/span 1;
}

[static-page] .super button.show-checkerboard,
[static-page] .super a.show-checkerboard {
  pointer-events: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

[static-page] .super button .🌐,
[static-page] .super a .🌐 {
  pointer-events: none;
  height: 100%;
  width: auto;
}

body.mobile [static-page] {
  grid-template-columns: auto;
  grid-column-gap: 0;
  padding: 0;
}

body.mobile [static-page] .page-scroll {
  min-width: 100vw;
  padding-right: 0;
}

body.mobile [static-page] .page-scroll::before {
  display: none;
}

body.mobile [static-page] .page-scroll .static-page-navigation {
  position: relative;
  display: grid;
  min-width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: var(--grid-column-gap);
  grid-column: 1/-1;
  padding: var(--spacing-1);
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: calc(var(--fontSize-default) * var(--lineHeight) * 6 + var(--row-rule-offset) * 2);
}

body.mobile [static-page] .page-scroll .static-page-navigation .first {
  grid-column: 1/span 2;
}

body.mobile [static-page] .page-scroll .static-page-navigation .second {
  grid-column: 3/span 2;
}

body.mobile [static-page] .page-scroll .page-window {
  position: relative;
  grid-column: 1/-1;
  margin-right: 0;
}

body.mobile [static-page] .page-scroll .page-window .page {
  display: block;
  padding-left: var(--spacing-1);
  padding-right: var(--spacing-1);
}

body.mobile [static-page] .page-scroll .page-window::before {
  content: "";
  height: 1px;
  background: var(--rule-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

body.mobile.authenticated [static-page] {
  width: var(--viewport-width);
}

body.mobile.authenticated [static-page]::before {
  display: none;
}

body.mobile.authenticated [static-page] .page-scroll {
  padding-right: var(--spacing-1);
  width: auto;
}

body.mobile.authenticated [static-page] .page-scroll::after {
  display: none;
}

body.mobile.authenticated [static-page] .page-scroll .static-page-navigation {
  padding-right: 0;
  height: fit-content;
  margin-bottom: calc(var(--fontSize-default) * var(--lineHeight) * 6 + var(--row-rule-offset) * 2 + var(--spacing-1));
}

body.mobile.authenticated [static-page] .page-scroll .static-page-navigation .fixed-header {
  display: none;
}

body.mobile.authenticated [static-page] .page-scroll .page-window {
  margin-right: calc(var(--spacing-1) * -1);
}

body.mobile.authenticated [static-page] .page-scroll .page-window::before {
  top: var(--row-rule-offset);
}

body.mobile.authenticated [static-page] .page-scroll .page-window .page {
  margin-top: calc(var(--spacing-1) * -1);
}

@media (min-width: 1250px) {
  [static-page] .page-scroll .page-window .page .row.wide-cols {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: var(--grid-column-gap);
    grid-column: 1/span 1;
    grid-gap: var(--grid-column-gap);
  }
  [static-page] .page-scroll .page-window .page .row.wide-cols .row:first-child {
    margin-top: 0;
  }
  [static-page] .page-scroll .page-window .page .row.wide-cols .row:before {
    display: none;
  }
}
.uiWindow[window-name=subscriptionInfo][type=popover] {
  width: 355px;
}

.uiWindow[window-name=subscriptionInfo][type=popover] .uiWindow-content .uiWindow-inner {
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  color: var(--color-default);
  background: #fff;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.uiWindow[window-name=subscriptionInfo][type=popover] .uiWindow-content .uiWindow-inner .title {
  display: flex;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.1;
  font-style: italic;
}

.uiWindow[window-name=subscriptionInfo][type=popover] .uiWindow-content .uiWindow-inner .title.large.blue {
  font-weight: 700;
  color: var(--baseColor-accent);
}

.uiWindow[window-name=subscriptionInfo][type=popover] .uiWindow-content .uiWindow-inner .titles {
  font-weight: bold;
  color: var(--baseColor-accent);
}

.uiWindow[window-name=subscriptionInfo][type=popover] .uiWindow-content .uiWindow-inner .legend {
  display: block;
  line-height: 1.3;
}

.uiWindow[window-name=subscriptionInfo][type=popover] .uiWindow-content .uiWindow-inner .legend .legend-item {
  display: flex;
  align-items: center;
  margin-top: 0.1em;
}

.uiWindow[window-name=subscriptionInfo][type=popover] .uiWindow-content .uiWindow-inner .legend .legend-item .icon {
  position: relative;
  display: inline-flex;
  margin-right: 0.1em;
}

#intercom-launcher {
  --main-fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  position: fixed;
  right: 3px;
  bottom: 7px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  z-index: 201;
}

#intercom-launcher svg {
  width: 100%;
  height: auto;
}

#intercom-launcher svg path {
  fill: var(--main-fill);
}

#intercom-launcher svg path.background {
  fill: rgba(var(--baseColor-default-rgb), 1);
}

#intercom-launcher svg rect {
  fill: none;
}

body.dark #intercom-launcher {
  --main-fill: rgba(var(--baseColor-default-reverse-rgb), 0.5);
}

/* Base Color and Background Mixins */
[window-name=inuse-details-editor].uiWindow[type=popover] {
  --preview-image-height: 250px;
  --preview-image-width: 365px;
  --parent-window-width: 462px;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.15);
  width: var(--parent-window-width);
  border-radius: var(--windowRadiusLarge);
  font-family: var(--fontFamily-default);
  font-size: var(--fontSize-default);
}

[window-name=inuse-details-editor].uiWindow[type=popover] .uiWindow-content {
  border-radius: var(--windowRadiusLarge);
}

[window-name=inuse-details-editor].uiWindow[type=popover] .uiWindow-content .uiWindow-inner {
  border-radius: var(--windowRadiusLarge-inner);
}

[window-name=inuse-details-editor].uiWindow[type=popover] .close.close-uiWindow {
  position: absolute;
  z-index: 2;
  top: 12px;
  right: 12px;
}

[window-name=inuse-details-editor].uiWindow[type=popover] window-header {
  display: flex;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

[window-name=inuse-details-editor].uiWindow[type=popover] window-header window-label[label-size=large] {
  display: inline-block;
  max-width: 406px;
  margin-bottom: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 20px;
}

[window-name=inuse-details-editor].uiWindow[type=popover] .uiWindow-content .uiWindow-inner {
  position: relative;
}

[window-name=inuse-details-editor].uiWindow[type=popover] .uiWindow-content .uiWindow-inner .ui-group .label-input-group {
  grid-template-columns: 114px auto;
  grid-gap: 0px;
}

[window-name=inuse-details-editor].uiWindow[type=popover] .uiWindow-content .uiWindow-inner .ui-group .label-input-group .input-label {
  background: var(--baseColor-default);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  white-space: nowrap;
  overflow: hidden;
}

[window-name=inuse-details-editor].uiWindow[type=popover] .uiWindow-content .uiWindow-inner .ui-group .label-input-group input {
  font-size: var(--fontSize-default);
}

[window-name=inuse-details-editor].uiWindow[type=popover] .uiWindow-content .uiWindow-inner .ui-group .label-input-group.error input {
  color: var(--subscription-error-color);
}

[window-name=inuse-details-editor].uiWindow[type=popover] .uiWindow-content .uiWindow-inner .preview-image-area.droppable::after {
  content: " ";
  position: absolute;
  height: 100%;
  width: 100%;
  outline: 2px solid red;
  outline-offset: -2px;
  z-index: 10;
}

[window-name=inuse-details-editor].uiWindow[type=popover] .uiWindow-content .uiWindow-inner .preview-image-area {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

[window-name=inuse-details-editor].uiWindow[type=popover] .uiWindow-content .uiWindow-inner .preview-image-area .delete-image {
  height: 20px;
  width: 20px;
  z-index: 999;
  position: absolute;
  top: 5px;
  right: 5px;
  margin: 0;
  padding: 0;
}

[window-name=inuse-details-editor].uiWindow[type=popover] .uiWindow-content .uiWindow-inner .preview-image-area .delete-image button {
  display: inline-flex;
  background: none;
  width: auto;
  height: auto;
  padding: 0;
}

[window-name=inuse-details-editor].uiWindow[type=popover] .uiWindow-content .uiWindow-inner .preview-image-area .delete-image button svg {
  position: relative;
  height: 20px;
  width: 20px;
}

[window-name=inuse-details-editor].uiWindow[type=popover] .uiWindow-content .uiWindow-inner .preview-image-area .delete-image button svg circle {
  opacity: 0.85;
  fill: rgb(0, 0, 1);
}

[window-name=inuse-details-editor].uiWindow[type=popover] .uiWindow-content .uiWindow-inner .preview-image-area .delete-image button svg path {
  fill: rgb(255, 255, 255);
}

[window-name=inuse-details-editor].uiWindow[type=popover] .uiWindow-content .uiWindow-inner .preview-image-area .preview-image {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

[window-name=inuse-details-editor].uiWindow[type=popover] .uiWindow-content .uiWindow-inner .preview-image-area .preview-image.upload {
  cursor: pointer;
}

[window-name=inuse-details-editor].uiWindow[type=popover] .uiWindow-content .uiWindow-inner .preview-image-area .preview-image .preview-image-frame {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}

[window-name=inuse-details-editor].uiWindow[type=popover] .uiWindow-content .uiWindow-inner .preview-image-area .preview-image .preview-image-frame img {
  aspect-ratio: 16/10 auto;
  width: 430px;
  pointer-events: all;
  text-align: center;
}

/* Base Color and Background Mixins */
body[data-route=public-folder] {
  background: var(--background-3);
  overflow-y: hidden;
}

body[data-route=public-folder] #intercom-launcher {
  display: none;
}

body[data-route=public-folder] #manager {
  padding-top: var(--spacing-1);
}

body[data-route=public-folder] #manager #siteslist {
  margin: calc(var(--spacing-1) * -1);
  margin-bottom: 0;
  padding-top: var(--spacing-4);
  min-height: var(--viewport-height);
}

body[data-route=public-folder] .public-folder.notfound {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  height: 100vh;
  width: 100vw;
  height: 100dvh;
  width: 100dvw;
  background: #fff;
}

body[data-route=public-folder] .public-folder.notfound .notfound-message {
  letter-spacing: 0;
  padding: 30px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  line-height: 1;
}

/* Base Color and Background Mixins */
.uiWindow[window-name=share-folder] .uiWindow-inner {
  height: 340px;
}

.uiWindow[window-name=share-folder] window-header {
  display: flex;
  margin-top: 0;
}

.uiWindow[window-name=share-folder] window-content {
  display: flex;
}

.uiWindow[window-name=share-folder] span.label-header-grid {
  display: inline-grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  grid-gap: 0;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  line-height: 1.3;
}

.uiWindow[window-name=share-folder] span.label-header-grid .ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
}

.uiWindow[window-name=share-folder] .label-desc {
  display: inline-block;
  font-size: var(--fontSize-default);
  margin-top: 10px;
  line-height: 1.3;
  margin-bottom: 0;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
}

.uiWindow[window-name=share-folder] button.copy-link {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  grid-template-columns: auto var(--height-row);
  position: relative;
}

.uiWindow[window-name=share-folder] button.copy-link span {
  height: var(--height-row);
  width: var(--height-row);
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.uiWindow[window-name=share-folder] button.copy-link span img {
  height: auto;
  width: 21px;
  aspect-ratio: 1/1;
}

.uiWindow[window-name=share-folder] .ui-window-text {
  max-width: 100%;
  overflow: hidden;
}

.uiWindow[window-name=share-folder] .ui-window-text a.text-link {
  display: inline-grid;
  font-size: var(--fontSize-secondary);
  line-height: 1.3em;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.5);
  -webkit-user-drag: none;
  text-decoration: none;
  grid-template-columns: auto auto;
  grid-gap: 0;
}

.uiWindow[window-name=share-folder] .ui-window-text a.text-link:hover:after {
  content: "↗";
  display: inline-block;
}

.uiWindow[window-name=share-folder] .ui-window-text a.text-link .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.uiWindow[window-name=share-folder] .ui-window-text a.text-link .text .ellipsis-friendly-arrow-spacer {
  display: inline-block;
  width: 0.25em;
}

/* Base Color and Background Mixins */
body .fixed-clock {
  position: fixed;
  right: var(--spacing-1);
  top: calc(var(--spacing-1) + var(--grid-cell-height) * 2);
  transform: rotate(90deg);
  width: var(--fontSize-default);
  line-height: 1;
  color: var(--color-default-secondary);
}

body .background-flipper {
  --aspect-ratio: 3456 / 2154;
  --maxWidth: calc(var(--viewport-height) * .9);
  --browserBar-height: 0px;
  --border-radius: 0;
  pointer-events: none;
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  width: 100%;
  height: 100%;
  margin: auto;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: var(--layer-2);
}

body .background-flipper .item {
  margin: auto;
  display: block;
  position: fixed;
  width: min((var(--maxWidth) - var(--browserBar-height)) * var(--aspect-ratio), 92.5%);
  aspect-ratio: var(--aspect-ratio);
  box-sizing: content-box;
  padding-top: var(--browserBar-height);
  inset: 0;
  margin: auto;
  border-radius: var(--border-radius);
}

body .background-flipper .item:after {
  position: absolute;
  content: "";
  inset: 0px;
  mix-blend-mode: plus-lighter;
  border-radius: var(--border-radius);
}

body .background-flipper .item .site-img {
  background-size: cover;
  background-position: 50%;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  border-radius: var(--border-radius);
  aspect-ratio: var(--aspect-ratio);
}

body .plus-animation {
  position: absolute;
  inset: 0;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

body .flipper-cursor-ghost {
  position: fixed;
  z-index: 9999;
  pointer-events: none;
}

body .flipper-cursor-ghost svg {
  width: 36px;
  height: 36px;
}

body .flipper-cursor-ghost svg path {
  fill: rgb(var(--baseColor-default-reverse-rgb));
}

body .flipper-button {
  --flipperButton-size: 40px;
  position: fixed;
  left: calc((var(--viewport-width) - var(--flipperButton-size)) / 2);
  top: calc((var(--viewport-height) - var(--flipperButton-size)) / 2);
  height: var(--flipperButton-size);
  width: auto;
  background: transparent;
  z-index: var(--layer-3);
  opacity: inherit;
  border-radius: 50%;
}

body .flipper-button .button-link {
  height: 100%;
  width: auto;
  pointer: cursor;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

body .flipper-button .button-link.home-start-link {
  border: none;
  outline: solid 1px rgba(0, 0, 0, 0);
  width: var(--flipperButton-size);
  height: var(--flipperButton-size);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

body .flipper-button .button-link.home-start-link svg {
  width: calc(var(--flipperButton-size) * 0.9);
  height: calc(var(--flipperButton-size) * 0.9);
}

body .flipper-button .button-link.home-start-link svg path {
  fill: rgb(var(--baseColor-default-reverse-rgb), 0.6);
  fill-opacity: 1;
}

body .flipper-button .button-link.home-start-link.dark {
  background: #000;
}

body .flipper-button .button-link.home-start-link.dark svg path {
  fill: #fff;
}

body.mobile .flipper-button {
  position: absolute;
  pointer-events: none;
}

body.mobile .flipper-button .button-link.home-start-link {
  background: transparent;
  outline-color: transparent;
}

#cargositelogo {
  margin: 0 auto;
  display: inline-block;
  width: 90px;
  height: auto;
  transform: translate(0, -5px);
  display: none;
}

#cargositelogo path {
  fill: rgba(0, 0, 0, 0.4);
}

body.unauthenticated {
  --selection-backgroundColor: rgba(var(--baseColor-default-reverse-rgb), .85);
  --selection-invert-backgroundColor: rgba(255, 255, 255, .85);
  --selection-color: rgba(0, 0, 0, 1);
}

body.unauthenticated .top-menu-bar ::selection,
body.unauthenticated #manager ::selection {
  background: var(--selection-backgroundColor);
  color: var(--ui-color-flat);
}

body.unauthenticated[data-route=home].mobile .top-menu-bar ::selection, body.unauthenticated[data-route=community] .top-menu-bar ::selection {
  background: var(--selection-invert-backgroundColor);
  color: var(--selection-color);
}

body.unauthenticated[data-route=home].mobile #manager ::selection, body.unauthenticated[data-route=community] #manager ::selection {
  background: var(--selection-invert-backgroundColor);
  color: rgb(0, 0, 0);
}

body.unauthenticated[data-route=home].mobile.dark, body.unauthenticated[data-route=community].dark {
  --selection-color: rgba(0, 0, 0, 0.85);
}

body.unauthenticated #manager .fixed-information ::selection {
  background: var(--selection-invert-backgroundColor);
  color: rgb(0, 0, 0);
}

/* Base Color and Background Mixins */
@font-face {
  font-display: block;
  font-family: "Marist Variable";
  src: url("https://type.cargo.site/files/CargoMaristVariable.woff2") format("woff2-variations");
  font-style: normal;
  font-weight: 350 900;
}
@font-face {
  font-display: block;
  font-family: "Marist Variable";
  src: url("https://type.cargo.site/files/CargoMaristVariable.woff2") format("woff2-variations");
  font-style: normal;
  font-weight: 350 900;
}
@font-face {
  font-display: block;
  font-family: "Marist Variable";
  src: url("https://type.cargo.site/files/CargoMaristVariable.woff2") format("woff2-variations");
  font-style: normal;
  font-weight: 350 900;
}
@font-face {
  font-display: block;
  font-family: "Marist Variable";
  src: url("https://type.cargo.site/files/CargoMaristVariable.woff2") format("woff2-variations");
  font-style: normal;
  font-weight: 350 900;
}
@font-face {
  font-display: block;
  font-family: "Marist Variable";
  src: url("https://type.cargo.site/files/CargoMaristVariable.woff2") format("woff2-variations");
  font-style: normal;
  font-weight: 350 900;
}
@font-face {
  font-display: block;
  font-family: "Marist Variable";
  src: url("https://type.cargo.site/files/CargoMaristItalicVariable.woff2") format("woff2-variations");
  font-style: italic;
  font-weight: 350 900;
}
@font-face {
  font-display: block;
  font-family: "Marist Variable";
  src: url("https://type.cargo.site/files/CargoMaristVariable.woff2") format("woff2-variations");
  font-style: normal;
  font-weight: 350 900;
}
body.authenticated #app [writing-page] {
  grid-template-columns: repeat(var(--grid-column-count), 1fr);
  grid-gap: var(--grid-column-gap);
}

body.authenticated #app [writing-page] .page-scroll .page-window {
  margin-top: 0;
}

body.authenticated[cargo-writing] [static-page] .background {
  left: 0;
}

body[cargo-writing] {
  background-color: var(--background-3);
}

#app [writing-page] {
  --lineHeight: 1.15;
  --mobile-padding-offset: 1;
  --text-decoration-color: rgba(var(--baseColor-default-reverse-rgb), 0.2);
  --rule-color: rgba(var(--baseColor-default-reverse-rgb), 0.15);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  font-family: var(--fontFamily-CargoDiatype);
  font-weight: var(--fontWeight-CargoDiatype);
  letter-spacing: 0;
  display: grid;
  grid-template-columns: var(--grid-template-columns);
  grid-column-gap: var(--grid-column-gap);
  font-size: var(--fontSize-default);
  grid-column: 1/-1;
  min-height: var(--grid-height-sans-pad);
  font-style: normal;
  line-height: var(--lineHeight-CargoDiatype);
  z-index: var(--layer-3);
  /* Method to apply font-feature-settings to list item counter (replace ::marker with ::before) */
}

#app [writing-page] ul {
  padding: 0 0 0 2em;
}

#app [writing-page] ul li {
  list-style-type: disc;
}

#app [writing-page] ol {
  padding: 0 0 0 2.75em;
  list-style: none;
  counter-reset: custom-counter;
}

#app [writing-page] ol li {
  counter-increment: custom-counter;
}

#app [writing-page] ol li::before {
  content: counter(custom-counter) ".";
  font-feature-settings: "onum" on;
  position: absolute;
  width: 2.75em;
  margin-left: -2.75em;
  padding-right: 0.875em;
  text-align: right;
}

#app [writing-page] hr {
  --hr-height: 1.5px;
  height: var(--hr-height);
  max-width: 4ch;
  background: rgb(var(--baseColor-default-reverse-rgb), 0.5);
  margin: calc((var(--lineHeight) - var(--hr-height)) * 0.5) auto;
}

#app [writing-page] .page-scroll {
  display: grid;
  grid-column-gap: var(--grid-column-gap);
  grid-template-columns: repeat(4, 1fr);
  grid-column: var(--grid-line-golden)/-1;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  line-height: var(--lineHeight);
  align-self: start;
  padding-right: var(--spacing-1);
  z-index: 3;
  position: relative;
}

#app [writing-page] .page-scroll .article-navigation {
  grid-column: 1/span 1;
  position: fixed;
  overflow-y: scroll;
  top: var(--height-row);
  bottom: 0;
  padding-bottom: var(--spacing-1);
  padding-top: calc(-1 * var(--row-rule-offset) - 1px);
  margin-top: 1px;
  row-gap: calc(var(--fontSize-default) * var(--lineHeight));
  min-width: var(--grid-cell-width);
  padding-left: var(--spacing-1);
  margin-left: calc(var(--spacing-1) * -1 - 1px);
  border-left: 1px solid rgba(var(--baseColor-default-reverse-rgb), 0.15);
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#app [writing-page] .page-scroll .article-navigation::-webkit-scrollbar {
  display: none;
}

#app [writing-page] .page-scroll .article-navigation .button-link {
  font-family: var(--fontFamily-CargoDiatype);
  display: inline-block;
  font-variation-settings: "slnt" 0, "MONO" 1;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.25);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.25);
}

#app [writing-page] .page-scroll .article-navigation .button-link.active {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.85);
}

#app [writing-page] .page-scroll .article-navigation .button-link.see-all {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  font-variation-settings: "MONO" 0;
}

#app [writing-page] .page-scroll .article-navigation .button-link.see-all:hover {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.45);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.45);
}

#app [writing-page] .page-scroll .page-window {
  grid-column: 2/span 3;
  margin-top: calc(var(--height-row) - var(--spacing-1));
  margin-right: calc(-1 * var(--spacing-1));
}

#app [writing-page] .page-scroll .page-window .oracle-images {
  display: flex;
  padding-top: var(--spacing-1);
  border-bottom: 1px solid var(--rule-color);
}

#app [writing-page] .page-scroll .page-window .oracle-images .oracle-image {
  border-right: 1px solid var(--rule-color);
  padding-bottom: var(--spacing-1);
  background: var(--background-3);
  display: flex;
  flex-direction: column;
  width: 50%;
}

#app [writing-page] .page-scroll .page-window .oracle-images .oracle-image img {
  aspect-ratio: 1/1;
  object-fit: contain;
  padding: 5%;
}

#app [writing-page] .page-scroll .page-window .oracle-images .oracle-image.iching {
  border-right: none;
}

#app [writing-page] .page-scroll .page-window .oracle-images .oracle-image.iching .oracle-image-caption {
  padding-left: var(--spacing-1);
}

#app [writing-page] .page-scroll .page-window .oracle-images .oracle-image img {
  width: 100%;
  filter: grayscale(100%) contrast(150%) brightness(99%);
}

#app [writing-page] .page-scroll .page-window .oracle-images .oracle-image-caption {
  margin-top: auto;
  margin-bottom: -2px;
  padding-top: 19px;
  padding-right: calc(12.5px * var(--mobile-padding-offset));
}

#app [writing-page] .page-scroll .page-window .oracle-images .oracle-image-caption,
#app [writing-page] .page-scroll .page-window .oracle-images .oracle-image-caption * {
  font-family: var(--fontFamily-CargoDiatype);
  font-size: 12px;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  display: block;
  font-weight: var(--fontWeight-CargoDiatype) !important;
  line-height: var(--lineHeight);
}

#app [writing-page] .page-scroll .page-window .article-block:first-of-type .article-title:before {
  display: none;
}

#app [writing-page] .page-scroll .page-window .article-block:last-of-type {
  /* make the last article tall enough to it can be scrolled all the way to the top */
  min-height: calc(var(--viewport-height) - var(--height-row) - var(--spacing-1));
}

#app [writing-page] .page-scroll .page-window .article-block.direct-link:last-of-type {
  min-height: 0;
}

#app [writing-page] .page-scroll .page-window .article-block[data-article-section=site_reviews] .article-content .article-title a[target=_blank] {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  font-variation-settings: "MONO" 0;
}

#app [writing-page] .page-scroll .page-window .article-block[data-article-section=goings_online] .article-title {
  padding-bottom: 3px;
}

#app [writing-page] .page-scroll .page-window .article-block[data-article-section=oracle] .article-title {
  padding-bottom: var(--spacing-1);
}

#app [writing-page] .page-scroll .page-window .article-block[data-article-section=oracle] .article-title:before {
  background: transparent;
}

#app [writing-page] .page-scroll .page-window .article-block[data-article-section=oracle] .article-title:after {
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  background: var(--rule-color);
  position: absolute;
  left: 0;
  right: 0;
}

#app [writing-page] .page-scroll .page-window .article-content {
  --lineHeight: 16.5px;
  width: 100%;
  padding-bottom: var(--grid-cell-height-rounded);
  padding-left: calc(var(--grid-subcell-width) * 1 + var(--grid-subcolumn-gap) * 1 + 4px);
  font-family: "Marist Variable";
  font-feature-settings: "onum" on;
  font-size: 18px;
  line-height: 20px;
  font-weight: 350;
  margin-top: 0;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.85);
}

#app [writing-page] .page-scroll .page-window .article-content .article-title {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.25);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.25);
  position: relative;
  font-family: var(--fontFamily-CargoDiatype);
  font-size: var(--fontSize-default);
  font-variation-settings: "slnt" 0, "MONO" 1;
  display: block;
  font-weight: var(--fontWeight-CargoDiatype);
  padding-top: var(--spacing-1);
  line-height: var(--lineHeight);
}

#app [writing-page] .page-scroll .page-window .article-content .article-title:before {
  content: "";
  display: block;
  height: 1px;
  background: var(--rule-color);
  position: absolute;
  left: 0;
  right: 0;
  top: -1px;
  z-index: 4;
}

#app [writing-page] .page-scroll .page-window .article-content .article-title a {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.25);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.25);
  position: relative;
}

#app [writing-page] .page-scroll .page-window .article-content .article-title a[target=_blank]:hover::after {
  content: "↗";
  display: inline;
  padding-left: 0.2em;
}

#app [writing-page] .page-scroll .page-window .article-content .article-title.description {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  font-variation-settings: "MONO" 0;
}

#app [writing-page] .page-scroll .page-window .article-content .article-title.description a,
#app [writing-page] .page-scroll .page-window .article-content .article-title.description .button-link {
  display: inline;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  text-decoration: underline;
  text-decoration-color: var(--text-decoration-color);
}

#app [writing-page] .page-scroll .page-window .article-content .article-title.description a:hover,
#app [writing-page] .page-scroll .page-window .article-content .article-title.description .button-link:hover {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.45);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.45);
}

#app [writing-page] .page-scroll .page-window .article-content .article-title.description a:hover::after,
#app [writing-page] .page-scroll .page-window .article-content .article-title.description .button-link:hover::after {
  display: none;
}

#app [writing-page] .page-scroll .page-window .article-content .article-body {
  word-break: break-word;
}

#app [writing-page] .page-scroll .page-window .article-content .article-body a {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  text-decoration: underline;
  text-decoration-color: var(--text-decoration-color);
}

#app [writing-page] .page-scroll .page-window .article-content a:active {
  opacity: var(--opacity-downstate-default);
}

#app [writing-page] .section-title {
  grid-column: 1/span 4;
  margin-right: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.75);
  font-size: calc(var(--grid-subcell-height) + var(--grid-subcell-height) / 8);
  font-family: var(--fontFamily-CargoDiatype);
  font-style: normal;
  font-weight: 500;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50vw;
  pointer-events: none;
  letter-spacing: -0.025em;
}

#app [writing-page] .section-title span {
  pointer-events: auto;
}

#app [writing-page][writing-page=useful-music] .article-navigation {
  pointer-events: none;
}

#app [writing-page][writing-page=useful-music] .article-navigation > * {
  display: none;
}

#app [writing-page][writing-page=useful-music] .page-scroll .page-window .article-content .article-title {
  font-variation-settings: "MONO" 0;
}

#app [writing-page][writing-page=useful-music] .page-scroll .page-window .article-content .article-title .credit {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.25);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.25);
}

#app [writing-page][writing-page=useful-music] .page-scroll .page-window .article-content .article-title span.play {
  margin-left: 0.58em;
}

#app [writing-page][writing-page=useful-music] .page-scroll .page-window .article-content .article-title span.play svg {
  height: auto;
  width: 12px;
  padding: 0 0 0 1px;
}

#app [writing-page][writing-page=useful-music] .page-scroll .page-window .article-content .article-title a {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.85);
}

#app [writing-page][writing-page=useful-music] .page-scroll .page-window .article-content .article-title a:hover::after {
  display: none;
}

#app [writing-page][writing-page=useful-music] .page-scroll .page-window .article-content .article-title a:hover span.play {
  text-decoration-color: transparent;
}

body.mobile[cargo-writing].authenticated {
  --grid-column-count: 4;
}

body.mobile[cargo-writing].authenticated #app [writing-page] {
  grid-template-columns: repeat(4, 1fr);
}

body.mobile[cargo-writing].authenticated #app [writing-page] #community-menu {
  top: var(--spacing-1);
}

body.mobile[cargo-writing].authenticated #app [writing-page] .page-scroll .page-window {
  margin-top: var(--spacing-1);
}

body.mobile[cargo-writing] #app [writing-page] {
  --mobile-padding-offset: 0.5;
}

body.mobile[cargo-writing] #app [writing-page] #community-menu {
  position: relative;
  background: var(--background-3);
  top: var(--height-row);
  left: 0;
  z-index: 4;
  width: calc(var(--viewport-width) - var(--spacing-2));
  padding-bottom: var(--grid-cell-height);
}

body.mobile[cargo-writing] #app [writing-page] .page-scroll {
  display: block;
  grid-column: 1/span 4;
  padding-right: 0;
}

body.mobile[cargo-writing] #app [writing-page] .page-scroll .page-window {
  margin-top: var(--height-row);
  grid-column: 1/span 4;
}

body.mobile[cargo-writing] #app [writing-page] .page-scroll .page-window .article-block:first-of-type.direct-link .article-title:before {
  display: block;
  left: calc(var(--spacing-1) * -1);
  right: calc(var(--spacing-1) * -1);
}

body.mobile[cargo-writing] #app [writing-page] .page-scroll .page-window .article-content {
  --lineHeight: 18.5px;
  padding-left: 0;
  padding-right: var(--spacing-1);
}

body.mobile[cargo-writing] #app [writing-page] .page-scroll .page-window .article-content .article-title.description:before {
  display: block;
  left: calc(var(--spacing-1) * -1);
  right: calc(var(--spacing-1) * -1);
}

body.mobile[cargo-writing] #app [writing-page] .section-title {
  display: none;
}

body.dark[cargo-writing] #app [writing-page] {
  --text-decoration-color: rgba(var(--baseColor-default-reverse-rgb), 0.25);
}

body.dark[cargo-writing] #app [writing-page] .page-scroll .page-window .oracle-images .oracle-image img {
  mix-blend-mode: screen;
}

body.dark[cargo-writing] #app [writing-page] .page-scroll .page-window .oracle-images .oracle-image.tarot img {
  filter: contrast(150%) grayscale(100%) brightness(85%);
}

body.dark[cargo-writing] #app [writing-page] .page-scroll .page-window .oracle-images .oracle-image.iching img {
  filter: saturate(220%) grayscale(100%) invert(100%) brightness(99%);
}